// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyAbdRc9PjbzmTQ-Dp8m05UNfPwiMoKVMZ8",
  authDomain: "himalayan-haat.firebaseapp.com",
  databaseURL: "https://himalayan-haat-default-rtdb.firebaseio.com",
  projectId: "himalayan-haat",
  storageBucket: "himalayan-haat.appspot.com",
  messagingSenderId: "400768696994",
  appId: "1:400768696994:web:73b9c3705fd09341524dfb"
});

export const auth = getAuth();
export default app