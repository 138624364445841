import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL } from "firebase/storage";
import storage from "../context/Firebase";

const noMenuRoutes = ["/login"];

function GetImage({ imageRef, alt }) {


  const { pathname } = useLocation();
  const [attachmentMedia, setAttachmentMedia] = useState("");

  console.log(imageRef)

  useEffect(() => {
    if (imageRef && imageRef !== null && imageRef !== undefined) {
      if (imageRef.includes('https://') || (imageRef.includes('http://'))) {
        setAttachmentMedia(imageRef);
      } else {
        getImg(imageRef);
      }
    }
  }, [imageRef]);


  // get the attachment either image or video
  const getImg = (coverRef) => {
    getDownloadURL(ref(storage, coverRef))
      .then((url) => {
        setAttachmentMedia(url);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {attachmentMedia && <img src={attachmentMedia} alt={alt} className='devotional-img bg-image' />}
    </>
  );
}
export default GetImage;