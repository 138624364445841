import React, { useState, useEffect, useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import editButton from '../../../assets/img/blog/edit.svg';
import deleteButton from '../../../assets/img/blog/delete.svg';
import placeholder from '../../../assets/img/placeholder.png';

import DrawerContext from '../../../context/DrawerContext.js';

import AuthContext from '../../../context/AuthContext';
import { Card } from '@mui/material';
import ModalContext from '../../../context/ModalContext';


function RetailersTable(props) {

  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loadedUsers, setUsersData] = useState([]);
  const [retailersAddressesData, setRetailersAddressesData] = useState([]);
  const [state, setState] = React.useState(false);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  useEffect(() => {
    getRetailers()
  }, []);


  const getRetailers = () => {

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/retailers', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);

        setRetailersAddressesData(data.details);

      });
  }

  const retailersStatusClass = (status) => {

    if (status == 'published') {
      return 'post-status published';
    } else {
      return 'post-status draft';
    }
  }
  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const openDeleteRetailertionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-retailer', { id });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { origin: 'retailers', id: id });
    modalCtx.openModal();
  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }
  console.log(loadedUsers);

  return (

    <>
      <div className="retailers">

        {
          retailersAddressesData?.map((data, index) => (
            <Card className='retailer-card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <div className={retailersStatusClass(data.status)}></div>

              {
                data.logo ?
                  <div className='retailers-img'  >
                    <img src={data.logo} alt="test" onClick={() => toggleDrawerHandler('retailer-details', data)} />

                  </div>
                  :
                  <div className='placeholder-img'
                  // onClick={() => openUploadImageModal(data.id)}
                  >
                    <img src={placeholder} alt="placeholder" />
                    <span className='placeholder-text' >No image. Click to Add a image</span>
                  </div>
              }


              <div className='retailer-details'>
                <div className='retailers-row'><p className='retailer-title-text' >{data.retailer}</p>
                </div>
                <Button variant='outlined' style={{ borderColor: data.status == "draft" ? '#636951' : '#CD4A4A', color: data.status == "draft" ? '#636951' : '#CD4A4A' }}>{data.pending_order_count} Pending Order</Button>
                <div className='editable-row'>
                  <div className='status-row'>
                    <div className="author-tags">
                      <span className="author-tag" style={{ backgroundColor: data.status == "draft" ? '#D0D2CB' : '#636951', color: 'white' }}>{data.status.toLocaleUpperCase()}</span>
                    </div>
                  </div>
                  <div align="right" className="action-buttons-t-cell">
                    <img className='back-button action-icon'
                      onClick={() => toggleDrawerHandler('edit-retailers', data)}
                      src={editButton} alt='' />
                    <img className='back-button action-icon'
                      onClick={() => openDeleteRetailertionalModal(data.id)}
                      src={deleteButton} alt='' />
                  </div>
                </div>
              </div>
            </Card>
          ))
        }
      </div>
    </>

  );
}

export default RetailersTable;
