import React, { useContext, useState, useEffect } from "react";
import ModalContext from "../../../context/ModalContext.js";
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";

const CreatePromotions = ({ modalDetails }) => {
  //context
  const modalCtx = useContext(ModalContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");

  return (
    <>
      <div className="create-promotion-modal-container">
        <h3>Create Promotion</h3>
        <div className="input-box">
          <h4 className="label">Title</h4>
          <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="input-box">
          <h4 className="label">Description</h4>
          <textarea name="description" id="description" cols="30" rows="5" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
        <div className="input-box">
          <h4 className="label">Type</h4>
          <Select value={type} onChange={(e) => setType(e.target.value)} displayEmpty inputProps={{ "aria-label": "Without label" }}>
            <MenuItem value="">Type</MenuItem>
            <MenuItem value='Email'>Email</MenuItem>
            <MenuItem value='Text Message'>Text Message</MenuItem>
            <MenuItem value='Whatsapp'>Whatsapp</MenuItem>
          </Select>
        </div>
        <div className="buttons-group">
          <button className="btn-primary-border" onClick={() => modalCtx.closeModal()}>Cancel</button>
          <button className="btn-primary" onClick={() => modalCtx.closeModal()}>Create</button>
        </div>
      </div>
    </>
  );
};

export default CreatePromotions;
