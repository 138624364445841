import React, { useState, useEffect } from 'react'
import { useContext } from 'react';

import '../../Topics/Topics.scss'

//mui
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CircularProgress } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { BiEdit } from 'react-icons/bi';

//context
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';
import AuthContext from '../../../context/AuthContext';

const TopicsTable = () => {
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext)
  const modalCtx = useContext(ModalContext)

  const [allTopics, setAllTopics] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    getData();
  }, [drawerCtx, modalCtx])



  const getData = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/topics ', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllTopics(data.data)
      })
  }

  console.log(allTopics)

  const handleModal = (modal, data) => {
    // console.log(modal, data)
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();
  }

  return (
    <>
      {
        allTopics ? <>
          <table className="topic-table">
            <thead>
              <tr>
                <th>Topic Name</th>
              </tr>
            </thead>

            <tbody>
              {allTopics?.map((el, index) => (
                <>
                  <tr className="ingredient-row">
                    <td className="topics-name" key={index}>
                      <h5>{el?.title}</h5>
                    </td>
                    <div className="edit-delete-icons">
                      <td className=''>
                        <button
                          onClick={() => {
                            handleModal("delete-topic", el)
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                      <td>
                        <button>
                          <BiEdit
                            onClick={() => {
                              handleModal("edit-topic", el);
                            }}
                          />
                        </button>
                      </td>
                    </div>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </>
          :
          <>
            <CircularProgress size={30} />
          </>
      }
    </>
  )
}

export default TopicsTable