import React, { createContext, useEffect, useState } from 'react';

const PrintOrdersContext = createContext({
  details: {},
  setDetails: (setDetails) => { },
});

export function PrintOrdersContextProvider(props) {
  const [details, setDetails] = useState({});


  function setDetailsHandler(data) {
    setDetails(data);
  }


  const context = {
    details: details,
    setDetails: setDetailsHandler,
  };

  return <PrintOrdersContext.Provider value={context}>{props.children}</PrintOrdersContext.Provider>;
}

export default PrintOrdersContext;