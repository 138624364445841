import { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';
import { ClosedCaptionRounded } from '@mui/icons-material';
import { GridCloseIcon } from '@mui/x-data-grid';
import '../../../Style/Modal.scss'

const DeleteRecipeModal = () => {
  const [isDeleted , setIsDeleted] = useState(false)
  const [allRecipes, setAllRecipes] = useState()


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  useEffect(() => {
    console.log("recipes all", allRecipes)
  }, [allRecipes])

  const deleteRecipe = (id) => {
    const apiUrl = process.env.REACT_APP_API_URI + "/v3/admin/recipe/" + id;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authCtx.token,
    };

    fetch(apiUrl, {
      method: "DELETE",
      mode: "cors",
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `Network response was not ok (Status: ${res.status})`
          );
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setAllRecipes(data)
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
      modalCtx.closeModal()
  };
  

  return (
    <div className="modal">
      <div className="close-modal">
        <p>Are you sure you want to delete this recipe?</p>
        <GridCloseIcon style={{ cursor: "pointer" }} onClick={() => modalCtx.closeModal()} />
      </div>
      <div className="footer">

        <Button className="cancel-btn" onClick={() => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => {
                              deleteRecipe(allRecipes?.id);
                              isDeleted ? setIsDeleted(false) : setIsDeleted(true);
                            }}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteRecipeModal;
