import React, { useContext, useState, useEffect } from 'react';
import { Alert, Checkbox, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import DrawerContext from '../../../context/DrawerContext';
import editButton from '../../../assets/img/blog/edit.svg';
import deleteButton from '../../../assets/img/blog/delete.svg';

const OrderConfirmModal = ({ modalDetails }) => {

  const orderDetails = modalDetails.order;
  const zohoSetup = modalDetails.zohoSetup;

  console.log("modalDetails", orderDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [isConfirming, setIsConfirming] = useState(false);
  const [createInvoice, setCreateInvoice] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState(orderDetails.status);
  const [sendNotifications, setSendNotifications] = useState(true);
  const [sendCustomerUpdate, setSendCustomerUpdate] = useState(false);
  const [sendCustomerNotification, setSendCustomerNotification] = useState(false);


  //effect
  useEffect(() => {

    // getAllAddress()

  }, []);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const confirmOrder = () => {

    setIsConfirming(true);

    let promises = [];

    if(statusUpdate != orderDetails.status) {
      let options = {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ status: statusUpdate })
      }
      promises.push(fetch(process.env.REACT_APP_API_URI + '/v3/admin/order/' + orderDetails.id + '/updateStatus', options));
    }

    if(createInvoice) {
      let options = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ orderId: orderDetails.id, lineItems: orderDetails.products })
      }
      promises.push(fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderDetails.id + '/createZohoInvoice', options));
    }

    if(sendNotifications && statusUpdate == "packing") {
      let options = {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ notificationType: "packing" })
      }
      promises.push(fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderDetails.id + '/sendNotification', options));
    }

    if(promises.length > 0) {
      Promise.all(promises).then((data) => {
        modalCtx.setPageUpdate({page: "ordersDrawer", updateType: "status", status: statusUpdate});
        modalCtx.closeModal();
        setTimeout(() => {modalCtx.setPageUpdate({page: "orders"});}, 500);
      });
    }
    

    // 

    // if(createInvoice) {

      // fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderDetails.id + '/createZohoInvoice', {
      //   method: 'POST',
      //   mode: 'cors',
      //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      //   body: JSON.stringify({ orderId: orderDetails.id, lineItems: orderDetails.products })
      // }).then(response => {
      //   return response.json();
      // }).then(data => {
      //   console.log(data);
      //   if (data.status === "success") {

      //     setIsConfirming(false);
      //     modalCtx.setPageUpdate({page: "orders"});
      //     modalCtx.closeModal();
      //     // setProducts(data.products);        
      //     // updateTotals(data.products);
      //     // setIsLoading(false);
      //   } else {
      //     setIsConfirming(false);
      //     console.error("products request error");
      //   }
      // }).catch(err => console.error("products request error", err));

    // }

    

    

  }

  const showOrderStatus = () => {
    switch (orderDetails.status) {
      case "packing": return "packing";
      case "dispatched": return "dispatched";
      case "delivered": return "delivered";
      default: return "draft";
    }
  }

  const showAdditionalOptions = () => {
    switch (statusUpdate) {

      case "packing": return <div className='aditional-options'>
        <div><Checkbox checked={sendNotifications} onChange={(e) => setSendNotifications(e.target.checked)} /> Send notification to operations team</div>
      </div>;

      case "dispatched": return <div className='aditional-options'>
        <div><Checkbox checked={sendCustomerUpdate} onChange={(e) => setSendNotifications(e.target.checked)} /> Send update to customer</div>
      </div>;
      
      case "delivered": return <div className='aditional-options'>
        <div><Checkbox checked={sendCustomerNotification} onChange={(e) => setSendNotifications(e.target.checked)} /> Send notification to customer</div>
      </div>;

      default: return null;
    }
  }

  

  return (
    <>
      <div className="confirm-order-status-container">
        <header>
          <h2 data-aos="fade-right" data-aos-delay="500">Confirm Order</h2>
          <CloseRounded onClick={() => handleCloseModal()} style={{cursor: "pointer"}} />
        </header>

        <section>
          
          <p>Your order is currently in {showOrderStatus()} mode.<br/>Confirm the order and move it to the next stage of completed it and mark as delivered.</p>
          <div style={{height: "10px"}}></div>

          <div><Checkbox checked={createInvoice} onChange={(e) => setCreateInvoice(e.target.checked)} /> Create invoice on confirmation</div>
          <hr></hr>

          <div className='confirmation-options'>
            <h3>Update status</h3>
            <RadioGroup defaultValue={statusUpdate} onChange={(e) => setStatusUpdate(e.target.value)} name="radio-buttons-group" >
              <FormControlLabel value="pending" control={<Radio />} label="Set status as DRAFT" />
              <FormControlLabel value="packing" control={<Radio />} label="Set status as PACKING" />
              <FormControlLabel value="dispatched" control={<Radio />} label="Set status as DISPATCHED" />
              <FormControlLabel value="delivered" control={<Radio />} label="Set status as DELIVERED" />
            </RadioGroup>
          </div>
          <hr></hr>

          {showAdditionalOptions()}

          <div className="save-changes-btn">
            <Button variant="outlined" component="label" disabled={false} onClick={() => handleCloseModal()} fullWidth style={{ border: '1px Solid #AA7B59', color: '#AA7B59' }}>Cancel</Button>
            {
              isConfirming ? <Button variant="contained" component="label" disabled={true} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}><CircularProgress size={10} style={{color: "#fff"}} /></Button> :
              <Button variant="contained" component="label" disabled={false} onClick={() => confirmOrder()} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}>Save</Button>
            }
          </div>
        </section>
      </div>
    </>
  );


}

export default OrderConfirmModal;
