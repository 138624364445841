import React, { useContext, useEffect, useState } from "react";
import RecipeTable from "./components/RecipeTable";
import DrawerContext from "../../context/DrawerContext";
import AuthContext from "../../context/AuthContext";
import { AiOutlinePlus } from 'react-icons/ai'

export default function Recipes() {


  const drawerCtx = useContext(DrawerContext)
  const authCtx = useContext(AuthContext);

  function toggleDrawerHandler(drawer) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer);
  };

  return (
    <>
      <div className="recipes-page">
        <header className="template-header">
          <h1 data-aos="fade-right" data-aos-delay="500">Recipes</h1>
          <div>
            <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-recipe', null) }}>+ Create New</button>
          </div>
        </header>

        <section className='template-section' >
          <RecipeTable />
        </section>
      </div>
    </>
  );
}
