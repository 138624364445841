import { useContext, useState } from 'react';
//react
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//Layout
import Main from './Layout/Main/Main';
import Sidebar from './Layout/Main/Sidebar';

//pages
import Overview from './pages/overview/Overview.jsx'
import Blogs from './pages/blogs/Blogs.jsx'

//context
import AuthContext from './context/AuthContext';

//drawer
import DetailsDrawer from "./common/DetailsDrawer";
//drawer
import DetailsModal from "./common/DetailsModal";

//styles 
import './Style/Styles.scss'

import Login from "./pages/login/Login";
import Orders from "./pages/orders/Orders";
import Products from "./pages/products/Products";
import Featured from './pages/featured/Featured.jsx';
import Recipes from "./pages/recipes/Recipes";
import Retailers from "./pages/retailers/Retailers";
import Customers from "./pages/customers/Customers";
import { DrawerContextProvider } from "./context/DrawerContext";
import { ModalContextProvider } from "./context/ModalContext";
import { PrintOrdersContextProvider } from "./context/PrintOrdersContext.js";
import RecipesIngredients from "./pages/recipeIngredients/RecipesIngredients";
import RecipeMethod from "./pages/recipeMethod/RecipeMethod";
import PrintOrders from "./pages/printOrders/PrintOrders.jsx";
import PrintInvoices from './pages/printInvoice/PrintInvoices.jsx';
import Promotion from './pages/promotion/Promotion.jsx';
import Automation from './pages/automation/Automation.jsx';
import Topics from './pages/Topics/Topics.jsx';
import Tags from './pages/tags/Tags.jsx';
import Authors from './pages/authors/authors.jsx';
import Coupons from './pages/coupons/Coupons.jsx';


function App() {

  const authCtx = useContext(AuthContext)

  const [sideBarOpen, setSideBarOpen] = useState(true)

  return (
    <main className="app-container">
      <DrawerContextProvider>
        <ModalContextProvider>
          <PrintOrdersContextProvider>
            <BrowserRouter>
              {/* <Navbar /> */}
              <div className="main-container">
                <Sidebar setSideBarOpen={setSideBarOpen} />
                <Main sideBarOpen={sideBarOpen} >
                  <Routes>
                    <Route path="/" exact index element={!authCtx.isLoggedIn ? <Login /> : <Navigate to='/overview' />} />
                    <Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login /> : <Navigate to='/overview' />} />
                    <Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview /> : <Navigate to='/login' />} />
                    <Route path="/promotion" exact element={authCtx.isLoggedIn ? <Promotion /> : <Navigate to='/login' />} />
                    <Route path="/automation" exact element={authCtx.isLoggedIn ? <Automation /> : <Navigate to='/login' />} />
                    <Route path="/blogs" exact element={authCtx.isLoggedIn ? <Blogs /> : <Navigate to='/login' />} />
                    <Route path="/topics" exact element={authCtx.isLoggedIn ? <Topics /> : <Navigate to='/login' />} />
                    <Route path="/orders" exact element={authCtx.isLoggedIn ? <Orders /> : <Navigate to='/login' />} />
                    <Route path="/products" exact element={authCtx.isLoggedIn ? <Products /> : <Navigate to='/login' />} />
                    <Route path="/authors" exact element={authCtx.isLoggedIn ? <Authors /> : <Navigate to='/login' />} />
                    <Route path="/featured" exact element={authCtx.isLoggedIn ? <Featured /> : <Navigate to='/login' />} />
                    <Route path="/tags" exact element={authCtx.isLoggedIn ? <Tags /> : <Navigate to='/login' />} />
                    <Route path="/retailers" exact element={authCtx.isLoggedIn ? <Retailers /> : <Navigate to='/login' />} />
                    <Route path="/recipes" exact element={authCtx.isLoggedIn ? <Recipes /> : <Navigate to='/login' />} />
                    <Route path="/coupons" exact element={authCtx.isLoggedIn ? <Coupons /> : <Navigate to='/login' />} />

                    <Route path="/recipes/recipesIngredients/:recipeId" exact element={authCtx.isLoggedIn ? <RecipesIngredients /> : <Navigate to='/login' />} />
                    <Route path="/recipes/recipesMethod/:recipeId" exact element={authCtx.isLoggedIn ? <RecipeMethod /> : <Navigate to='/login' />} />
                    <Route path="/customers" exact element={authCtx.isLoggedIn ? <Customers /> : <Navigate to='/login' />} />
                    <Route path="/print-orders" exact element={authCtx.isLoggedIn ? <PrintOrders /> : <Navigate to='/login' />} />
                    <Route path="/print-invoices" exact element={authCtx.isLoggedIn ? <PrintInvoices /> : <Navigate to='/login' />} />
                  </Routes>
                </Main>
              </div>
              {/* <Footer /> */}
              <DetailsDrawer />
              <DetailsModal />
            </BrowserRouter>
          </PrintOrdersContextProvider>
        </ModalContextProvider>
      </DrawerContextProvider>
    </main>
  );
}

export default App;
