import { useContext } from 'react';
import { Button } from '@mui/material';

import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';

const DeleteAuthorModal = ({modalDetails}) => {
	const authCtx = useContext(AuthContext);
	const modalCtx = useContext(ModalContext);

	const handleCloseModal = () => {
		modalCtx.closeModal();
	}

	const deleteAuthor = (id) => {
		if (authCtx.isLoggedIn) {
			fetch(process.env.REACT_APP_API_URI + `/v3/admin/author/${id}`, {
				method: 'DELETE',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
			}).then((res) => res.json())
				.then(data => {
					console.log(data);
					if (data.status === "success") {
						modalDetails.getAllAuthors();
						modalCtx.closeModal();
					} else {
						console.error("Error while deleting author...");
					}
				}).catch((err) => {
					console.error("Error while deleting author...", err);
				});
		}
	}


	return (
    <div className="modal">
      <p>Are you sure you want to delete this Author?</p>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteAuthor(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
		
}
export default DeleteAuthorModal;