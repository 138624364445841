import { useContext, useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import AuthContext from '../../context/AuthContext.js';
import DrawerContext from '../../context/DrawerContext.js';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import ModalContext from '../../context/ModalContext.js';
import editButton from '../../assets/img/blog/edit.svg';
import deleteButton from '../../assets/img/blog/delete.svg';

import GetTimeFormatted from '../../helper/getTimeFormatted.js'
import PrintOrdersContext from '../../context/PrintOrdersContext.js';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../common/TextInput.jsx';


const Orders = () => {
 
  const navigate = useNavigate()
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const printCtx = useContext(PrintOrdersContext);

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [searchText, setSearchText] = useState('');

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const handleModal = (name, data) => {
    console.log("clicking happening");
    modalCtx.setDetails(name, { data });
    modalCtx.openModal();

  }

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);


  const getStatusClass = (status) => {
    switch (status) {
      case "confirmed": return "status-chip confirmed";
      case "paid": return "status-chip confirmed";
      case "packing": return "status-chip packing";
      case "dispatched": return "status-chip dispatched";
      case "delivered": return "status-chip delivered";
      default: return "status-chip draft";
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case "confirmed": return "Confirmed";
      case "paid": return "Confirmed";
      case "packing": return "Packing";
      case "dispatched": return "Dispatched";
      case "delivered": return "Delivered";
      default: return "Draft";
    }
  }

  const getSource = (status) => {
    switch (status) {
      case "website": return "WEBSITE";
      case "whatsapp": return "WHATSAPP";
      case "amazon": return "AMAZON";
      default: return "unknown";
    }
  }
  
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (modalCtx.pageUpdate?.page === "orders") {
      console.log("\n\nPage update requested. Refetching all orders...");
      modalCtx.setPageUpdate({ page: "" });
      fetchData();
    } else {
      console.log("No page update requests detected...");
    }
  }, [modalCtx.pageUpdate]);

  useEffect(() => {
    // const selectedOrders = orders?.filter((order, i) => order.id)
    let selectedOrders = []
    for (let i = 0; i < selectedRows?.length; i++) {
      for (let j = 0; j < orders?.length; j++) {
        if (orders[j].id == selectedRows[i]) {
          selectedOrders.push({ id: orders[j].id, type: 'customer' })
        }
      }
    }
    setSelectedOrders(selectedOrders)

  }, [selectedRows])

  const fetchData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/admin/orderSummaries', {
        method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      });
      const json = await response.json();
      console.log("jsondata", json.data);
      setOrders(json.data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  const postDataForPrint = async (type) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/admin/order/forPrint', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ orderIds: selectedOrders })
      });
      const data = await response.json();

      if (data.status == 'success') {
        printCtx.setDetails(data.data)
        if (type == 'orders') {
          navigate(`/print-orders?ids=${selectedOrders.map(i => i.id).join(',')}`)
        } else {
          navigate('/print-invoices')
        }
      }

    } catch (e) {
      console.error(e);
    }
  }
  console.log(selectedOrders)

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const showCustomerOrRetailer = (data) => {
    if(data.orderType == "retailer") {
      return data.retailer;
    } else {
      return data.customer;
    }
  }


  const columns = [
    {
      field: 'id', headerName: 'Id', width: 70, headerAlign: "center", align: "center", renderCell: (params) => (<>
        <Link className="order-details-link" style={{ cursor: "pointer" }} onClick={() => {
          // console.log("order params.row\n", params.row);
          // toggleDrawerHandler('order-details', { orderDetails: params?.row, orderId: params?.row.id });
          toggleDrawerHandler('order-details', { orderId: params?.row.id, customerId: params?.row.order.customer.id });
        }}>{params.value}</Link></>)
    },
    // { renderCell: (params) => <Link to ></Link>, headerName: 'ID', width: 70 },
    {
      field: 'date', headerName: 'Date', flex: 1, renderCell: (params) => (<>
        {GetTimeFormatted(params.value, "MMM D 'YY")}
      </>)
    },
    {
      field: 'customer', headerName: 'Customer', flex: 1, renderCell: (params) => (<Link className="customer-details-link" onClick={() => {
        toggleDrawerHandler('customer-details', { customerDetails: params?.row });
      }}>{showCustomerOrRetailer(params?.row)}</Link>)
    },
    {
      field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => <>
        <div className={getStatusClass(params?.row.status)}
          style={{ cursor: "pointer" }}
          onClick={() => handleModal("change-order-status", { orderId: params?.row.id, status: params?.row.status })}
        >{getStatus(params?.row.status)}</div>
      </>
    },
    {
      field: 'type', headerName: 'Type', flex: 1, renderCell: (params) => <>
        {params?.row.orderType == "direct" ? "Direct" : "Retailer"}
      </>
    },
    {
      field: 'source', headerName: 'Source', flex: 1, renderCell: (params) => <>
        <span>{getSource(params?.row.source)}</span>
      </>
    },
    {
      field: 'total', headerName: 'Total', flex: 1, renderCell: (params) => <>
        <span>Rs.{params.value}/-</span>
      </>
    },
    {
      field: 'buttons', headerName: '', flex: 1, width: 80, renderCell: (params) => <>
        <img className='back-button action-icon'
          onClick={() => toggleDrawerHandler('order-details', { orderId: params?.row.id, orderType: params?.row.orderType, customerId: params?.row.order?.customer?.id || 0, retailerId: params?.row.order?.retailer?.id || 0 })}
          src={editButton} alt='' />
        <img className='delete-btn' onClick={() => handleModal("delete-orders", {id: params?.row.id})} src={deleteButton} alt='' />
      </>
    },
  ];

  const rowsData = orders && orders.length > 0 && orders
  .filter((order) => {
    if (!searchText) {
      return true;
    }

    const lowerCaseSearchText = searchText.toLowerCase();
    const customerName = (order.address?.contact_name || order.customer?.full_name || order.customer?.first_name || '').toLowerCase();
    const orderId = order.id.toString();

    return (
      orderId.includes(lowerCaseSearchText) || customerName.includes(lowerCaseSearchText)
    );
  })
  .map((order) => ({
    id: order.id,
    orderType: order.orderType,
    date: order.date || "",
    customer: order.address?.contact_name || order.customer?.full_name || order.customer?.first_name,
    retailer: order?.retailer?.retailer || "",
    total: order.total,
    source: order.source,
    status: order.status,
    order: order
  })) || [];

  if (isLoading) {
    return <div className="container loading"><CircularProgress style={{ 'color': '#F7D54D' }} size={60} /></div>
  }


  return (
    <div className="orders-page">
      <div className='template-header'>
        <div>
          <h1>Orders</h1>
        </div>
        <div className="header-btns">
          {
            selectedOrders?.length > 0 && <a style={{ textDecoration: 'none' }} rel="noreferrer" href={`/print-orders?ids=${selectedOrders.map(i => i.id).join(',')}`} target="_blank">
              <button className='btn-primary'>Print Orders</button>
            </a>
          }
          {
            selectedOrders?.length > 0 && <a style={{ textDecoration: 'none' }} rel="noreferrer" href={`/print-invoices?ids=${selectedOrders.map(i => i.id).join(',')}`} target="_blank">
              <button className='btn-primary'>Print Invoice</button>
            </a>
          }
          <button className='btn-primary' onClick={() => handleModal('add-orders', orders)}>+ Add Order</button>
          <TextInput value={searchText} placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>

      <div className='template-section'>
        <DataGrid
          sx={{
            // boxShadow: 2, border: 1, borderColor: '#77777777',
            '& .MuiDataGrid-cell:hover': { color: 'primary.main', },
          }}
          rows={rowsData}
          columns={columns}
          checkboxSelection
          selectionModel={selectedRows}
          disableSelectionOnClick={true}
          onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        />
      </div>
    </div>
  );

}
export default Orders;
