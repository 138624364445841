import React from 'react'

const Main = ({ children, sideBarOpen }) => {
  return (
    <div className='main' style={!sideBarOpen ? { width: '100%' } : null} >
      {children}
    </div>
  )
}

export default Main