import React, { useContext, useState, useEffect } from 'react'

import deleteButton from '../../assets/img/blog/delete.svg';
import editButton from '../../assets/img/blog/edit.svg';

import TextInput from '../../common/TextInput'
import { DataGrid } from '@mui/x-data-grid';
import ModalContext from '../../context/ModalContext';

function Coupons() {

  const modalCtx = useContext(ModalContext)

  const [searchText, setSearchText] = useState('');
  const [couponsData, setCouponsData] = useState('');
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + "/v3/coupons", {
      method:'GET',
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => res.json())
    .then(data => {
      setCouponsData(data)
      console.log(data);
    })
  }, [refresh])

  const columns = [
    { field: 'coupon', headerName: 'Coupon Code', flex: 1 },
    { field: 'couponType', headerName: 'Coupon Type', flex: 1, },
    { field: 'customerType', headerName: 'Customer Type', flex: 1 },
    { field: 'customer', headerName: 'Customer', flex: 1 },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='coupon-action-btn-container'>
        <img onClick={() => handleModal('edit-coupon', {'couponDetails': params.row, 'allCoupons' : couponsData?.data, 'refresh': setRefresh, 'value': refresh})} className='edit-btn' src={editButton} alt='' />
        <img onClick={() => handleModal('delete-coupon', {'couponDetails': params.row, 'refresh': setRefresh, 'value': refresh})} className='delete-btn' src={deleteButton} alt='' />
      </div>
    },
  ];

  const data = [
    {
      id: 101,
      couponCode: 'HH200',
      couponType: "general",
      customer: "stephen",
      customerType: 'single'
    },
    {
      id: 102,
      couponCode: 'HH200',
      couponType: "general",
      customer: "rushi",
      customerType: 'general'
    },
    {
      id: 103,
      couponCode: 'HH200',
      couponType: "general",
      customer: "-",
      customerType: 'single'
    },
    {
      id: 104,
      couponCode: 'HH200',
      couponType: "custom",
      customer: "stephen",
      customerType: 'single'
    },
    {
      id: 105,
      couponCode: 'HH200',
      couponType: "general",
      customer: "rushi",
      customerType: 'general'
    },
    {
      id: 106,
      couponCode: 'HH200',
      couponType: "general",
      customer: "stephen",
      customerType: 'single'
    },
    {
      id: 107,
      couponCode: 'HH200',
      couponType: "custom",
      customer: "rushi",
      customerType: 'single'
    },
    {
      id: 108,
      couponCode: 'HH200',
      couponType: "custom",
      customer: "-",
      customerType: 'general'
    },
    {
      id: 109,
      couponCode: 'HH200',
      couponType: "general",
      customer: "stephen",
      customerType: 'single'
    },
    {
      id: 110,
      couponCode: 'HH200',
      couponType: "custom",
      customer: "rushi",
      customerType: 'single'
    },
  ]

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  return (
    <div className="coupon-page">

      <header className="template-header" >
        <h1 data-aos="fade-right" data-aos-delay="500">Coupons</h1>
        <div>
          {/* <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-overview', null) }}>Create New</button> */}
        </div>
        <div className="header-actions">
          <button onClick={() => handleModal('create-coupon', { data : couponsData?.data, 'refresh': setRefresh, 'value': refresh})} className='btn-primary' >Create Coupon</button>
          <TextInput value={searchText} placeholder='Seacrh' onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </header>
      <div className='template-section'>
        <DataGrid sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }} rows={couponsData === "" ? [] : couponsData?.data} columns={columns} />
      </div>
    </div>
  )
}

export default Coupons