import React, { useRef, useContext, useState, useEffect } from 'react';
import { Snackbar, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, SnackbarContent } from '@mui/material';

//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//assets
import { AddRounded, ArrowBackRounded, ArrowForwardRounded, CloseRounded, HighlightOffRounded, KeyboardArrowDownRounded, PublishRounded, ReplayRounded } from '@mui/icons-material';
import Edit from '../../../assets/img/blog/edit.svg'
import placeholder from '../../../assets/img/placeholder.png';
import backbutton from '../../../assets/img/drawer/back.svg';
import Flower1 from '../../../assets/img/blog/edit/blog-edit-flower-1.png'
import Flower2 from '../../../assets/img/blog/edit/blog-edit-flower-2.png'
import Logo from '../../../assets/img/hh-logo.png';

//components
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";


const EditBlogDrawer = ({ drawerDetails }) => {

  //clg
  console.log(drawerDetails)

  //refs
  const titleRef = useRef(null);
  const quillRef = useRef();

  //context
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  //states
  const [open, setOpen] = useState(false);
  const [metaTagsOpen, setMetaTagsOpen] = useState(false);
  const [editorToolOpen, setEditorToolOpen] = useState(true);

  // updating status states 
  const [savingChanges, setSavingChanges] = useState(false);

  const [blog, setBlog] = useState(drawerDetails);
  const [createBlogObj, setCreateBlogObj] = useState({
    snippet: blog.snippet || "",
    title: blog.title || "",
    post: blog.post || "",
    slug: blog.slug || blog.id,
    pageTitle: blog.pageTitle || "",
    metaDescription: blog.metaDescription || "",
    metaKeywords: blog.metaKeywords || "",
    isVideo: blog.isVideo || "no",
    videoUrl: blog.videoUrl || "",
    customUrl: blog.customUrl || blog.id,
  });
  const [statusClass, setStatusClass] = useState(() => {
    if (blog.status === 'published') {
      return 'status-button published';
    } else {
      return 'status-button';
    }
  });
  const [editor, setEditor] = useState('myeditor disable');


  //effect
  useEffect(() => {
    submitEditBlog()
  }, [modalCtx])

  useEffect(() => {
    if (titleRef && titleRef.current) {
      titleRef.current.innerText = blog.title;
    }
  }, [blog]);


  //functions
  const handleTitleInput = (e) => {
    setCreateBlogObj((prev) => ({ ...prev, title: e.target.innerText }))
  }

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const submitEditBlog = () => {

    setSavingChanges(true);

    if (authCtx.isLoggedIn) {
      const { title, snippet, post, slug, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog/' + blog.id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          title,
          snippet,
          post,
          slug,
          pageTitle,
          metaDescription,
          metaKeywords,
          isVideo,
          videoUrl
        })
      }).then(response => {
        console.log("getting response");
        return response.json();
      }).then(data => {
        setSavingChanges(false);
        if (data.status === "success") {
          updateURL(blog.id, slug);
        } else {
          console.error("Error while editing blog...");
        }
      }).catch((err) => console.error("Error while editing blog...", err));
    }
  }

  const updateURL = async (blogId, slug) => {

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/blogs/' + blogId + '/updateSlug', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ slug })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            getBlog();
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const closeDrawer = () => {
    console.log(blog.image);
    drawerCtx.closeDrawer();
  }

  const getBlog = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/blogs/' + blog.id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === 'success') {
          console.log("blogs ", data.data)
          setBlog(data.data[0]);
          // setOpen(true);

          updateStatusClass();

          //here we will set the details
        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)

      });
  }
  const updateStatusClass = () => {
    if (blog.status === 'published') {
      setStatusClass('status-button published');
    } else {
      setStatusClass('status-button');
    }
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { origin: 'blog', id: id });
    modalCtx.openModal();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const changeEditorStatus = (e) => {
    if (e) {
      const quill = quillRef.current.getEditor();
      console.log(quill.getSelection());
      setEditor("myeditor active")
    } else {
      const quill = quillRef.current.getEditor();

      console.log(quill.getSelection());
      // quill.setSelection('');

      setEditor(" myeditor disable")
    }
  }

  const DeleteTopic = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${devoId}/topic/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }


  const DeleteTag = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${devoId}/tag/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
    getBlog();
  }

  function showRegDate(unFormattedDate) {

    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  const handleMetaTagsBoolean = (e) => {
    if (e) {
      setMetaTagsOpen(true)
    } else {
      setMetaTagsOpen(false)
    }
  }

  const handleEditorToolBoolean = () => {
    if (editorToolOpen) {
      setEditorToolOpen(false)
    } else {
      setEditorToolOpen(true)
    }
  }

  function showRegDate(unFormattedDate) {
    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }


  return (
    <div className="edit-blog-container">
      <div className="drawer blog-drawer-container">
        <div className="blog-drawer" style={editorToolOpen ? { gridTemplateRows: '160px 1fr 100px' } : { gridTemplateRows: '115px 1fr 100px' }}>

          <div className="blog-drawer-header">
            <header>
              <h1 data-aos="fade-right" data-aos-delay="500">
                < img className='back-button tool-button' onClick={(e) => {
                  closeDrawer();
                }} src={backbutton} alt='' />
                Edit Blog - {createBlogObj.title}</h1>
            </header>

            <div className="blog-webpage-clone">
              <div className="webpage-clone">
                <div className="webpage-link">
                  <ArrowBackRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ArrowForwardRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ReplayRounded style={{ color: '#aaa' }} className='link-icon' />
                  <div className="link-container">
                    <span>kingdomempowered.com/blogs/</span>
                    <input type="text" name="slug" placeholder={createBlogObj.slug} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} />
                  </div>
                </div>
                <div className="title-tab">
                  <img src={Logo} alt="logo" />
                  <span>{createBlogObj.title}</span>
                  <CloseRounded style={{ color: '#aaa' }} />
                </div>

                <div className='action-buttons-div'>
                  <div className='status-container'>
                    Status -
                    <div className="status-span" onClick={() => openPublishModal(blog)}>
                      <span className={statusClass}>{blog.status.toUpperCase()}</span>
                    </div>
                  </div>
                  <FormGroup>
                    <FormControlLabel label="Show Meta Tags" control={<Switch />} onChange={e => handleMetaTagsBoolean(e.target.checked)} />
                  </FormGroup>
                  <button className='btn-primary' variant="contained" component="label" disabled={false}
                    onClick={(e) => submitEditBlog()}> {savingChanges ? <CircularProgress style={{ color: "white" }} size={20} /> : "Save Changes"}
                  </button>
                  <button className='btn-primary-border' variant="outlined" component="label" disabled={false}
                    onClick={(e) => closeDrawer()}>Cancel
                  </button>
                </div>

                <div className="meta-tags-container" style={metaTagsOpen ? null : { display: 'none' }}>
                  <TextField style={{ margin: "10px auto 15px" }} name="pageTitle" label="Page Title" value={createBlogObj.pageTitle} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaDescription" label="Meta Description" value={createBlogObj.metaDescription} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaKeywords" label="Meta Keywords" value={createBlogObj.metaKeywords} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                </div>

              </div>
            </div>
            <div className="editor-toolbar-container">
              <div className="editor-toolbar-control">
                <KeyboardArrowDownRounded style={editorToolOpen ? { transform: 'rotateX(180deg)' } : null} onClick={() => handleEditorToolBoolean()} />
              </div>
              <EditorToolbar editorToolOpen={editorToolOpen} />
            </div>
          </div>

          <div className='blog-edit-section' >

            <img className='blog-flower-1' src={Flower1} alt="flowers" />
            <img className='blog-flower-2' src={Flower2} alt="flowers" />

            <div className='editor-div'>

              <div className="image-and-title-container">
                <div className='image-container'>

                  <div className="img">
                    {
                      blog.image !== null
                        ? <img className='post-image' src={blog.image} alt={blog.title} />
                        : <img className='post-image placeholder' src={placeholder} alt='test' />
                    }
                    <div className='upload-image-buttons' >
                      <button className='btn-primary' disabled={false} onClick={(e) => openUploadImageModal(blog.id)}>
                        <PublishRounded className='buttons' />
                        {
                          blog.image
                            ? "Update Image"
                            : "Add Image"
                        }
                      </button>

                      {
                        blog.image !== null
                          ? <button className='btn-primary-border' onClick={null}><HighlightOffRounded className='buttons' /> Remove Image </button>
                          : null
                      }
                    </div>
                  </div>
                </div>

                <div className='title-and-author-container'>
                  <div className='title-and-author'>

                    <div className="blog-category">
                      <hr /><span>Farm Life</span>
                    </div>

                    <div className="title-container">
                      <div ref={titleRef} contentEditable={true} className='title-editor' onInput={handleTitleInput}></div>
                    </div>

                    <div className="blog-author-and-date">
                      <span>{blog.author?.length > 0 ? blog.author[0]?.first_name : 'Author'}</span>
                      <span><img src={Edit} alt="edit" onClick={() => handleModal('edit-blog-author', blog)} /></span>
                      <span>{showRegDate(blog.createdAt)}</span>
                    </div>

                  </div>
                </div>
              </div>

              <div className='content-container'>
                <div className='text-editor-container'>

                  <ReactQuill className={editor} ref={quillRef}
                    theme="snow"
                    value={createBlogObj.post}
                    onChangeSelection={(e) => {
                      changeEditorStatus(e)
                    }}
                    onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                  />
                  {/* <ReactQuill className='myeditor' modules={{
                      toolbar: false  // hide the default toolbar
                    }} defaultValue='Blog Post' theme="snow" name="post" value={createBlogObj.post} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))} /> */}

                </div>

              </div>
            </div>

          </div>

          <div className='tags-container'>
            <div className='topic-row'>
              {
                blog.topics?.map((topic, i) => {
                  if (topic == null) return null
                  return <div key={topic.id} className='single-tag' onClick={null}> {topic.title} <CloseRounded className='remove-author' onClick={() => DeleteTopic(blog.id, topic.id)} /></div>
                })
              }
              <div className='single-tag' onClick={() => handleModal('edit-blog-topics', blog)}><AddRounded className='add-author' /> Topics</div>
            </div>

            <div className='tags-row'>
              {
                blog.tags?.map((tag, i) => {
                  if (tag == null) return null
                  return <div key={tag.id} className='single-tag' onClick={null}> {tag.title}<CloseRounded className='remove-author' onClick={() => DeleteTag(blog.id, tag.id)} /> </div>
                })
              }
              {/* <div className='single-tag' onClick={null}><CloseRounded className='add-author' onClick={() => null} /> Prayer</div> */}
              <div className='single-tag' onClick={() => handleModal('edit-blog-tags', blog)}><AddRounded className='add-author' /> Tag</div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} >
        <SnackbarContent style={{ backgroundColor: 'teal', borderRadius: "10px", color: "white", }} message={<span id="client-snackbar">Post Saved</span>} />
      </Snackbar>
    </div>
  );
}

export default EditBlogDrawer;

