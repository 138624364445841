import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, MenuItem, FormControl, Button, Card, InputAdornment, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CloseRounded, Search } from '@mui/icons-material';
import editButton from '../../../assets/img/blog/edit.svg';
import deleteButton from '../../../assets/img/blog/delete.svg';
import emailButton from '../../../assets/img/email.svg';
import callButton from '../../../assets/img/call.svg';
import profileButton from '../../../assets/img/profile.svg';
import DrawerContext from '../../../context/DrawerContext';
import GetImage from '../../../common/GetImage';
import { InsertEmoticonSharp } from '@mui/icons-material';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';


const RetailersDetailsDrawer = (props) => {


  const drawerCtx = useContext(DrawerContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTag, setSearchTag] = useState('');
  const [activeButton, setActiveButton] = useState(1);

  console.log("customerDetailsDrawer", props?.retailerDetails);
  const retailerDetails = props?.retailerDetails;
  console.log(retailerDetails);


  const data = [
    { argument: 'Jan', value: 30 },
    { argument: 'Feb', value: 20 },
    { argument: 'Mar', value: 10 },
    { argument: 'Apr', value: 50 },
    { argument: 'May', value: 60 },
    { argument: 'June', value: 30 },
    { argument: 'July', value: 20 },
    { argument: 'Aug', value: 10 },
    { argument: 'Sep', value: 50 },
    { argument: 'Oct', value: 60 },
    { argument: 'Nov', value: 30 },
    { argument: 'Dec', value: 30 }
  ];
  const chartHeight = 363;
  const color = '#AA7B59';


  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSearchTag('');
  }

  const handleFilterArray = (value) => {
    console.log(value);
    setSearchTag(value);
  }


  const handleSelectAll = (event, selecting) => {
    if (selecting = "order") {
      if (event.target.checked) {
        setSelectedRows(dummyOrderData.map((item) => item.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (event.target.checked) {
        setSelectedRows(marginItems.map((item) => item.id));
      } else {
        setSelectedRows([]);
      }
    }

  };

  const handleSelectRow = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    }
  };




  return (

    <div className="retail-drawer">
      <div className='head'>
        <CloseRounded onClick={() => { drawerCtx.closeDrawer(); }}></CloseRounded>
        {retailerDetails.retailer}
      </div>



      <div className='details-section'>
        <div className='buttons-div'>
          <button style={{ backgroundColor: activeButton === 1 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(1)}>Statistics</button>
          <button style={{ backgroundColor: activeButton === 2 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(2)}>Margin</button>
          <button style={{ backgroundColor: activeButton === 3 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(3)}>Location</button>
          <button style={{ backgroundColor: activeButton === 4 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(4)}>Orders</button>
          <button style={{ backgroundColor: activeButton === 5 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(5)}>Details</button>
          <button style={{ backgroundColor: activeButton === 6 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(6)}>Images</button>

        </div>

        {activeButton === 1 &&
          <div className='statistics'>
            <Paper >
              <Chart height={chartHeight}
                data={data}
              >
                <ArgumentAxis />
                {/* <ValueAxis /> */}

                <BarSeries valueField="value" argumentField="argument" color={color} />
              </Chart>
            </Paper>

            <div className='graph-details'>
              <div className='card'><p className='title'>5600</p> <p>Sales</p></div>
              <div className='card'><p className='title'>May</p> <p>Top Month</p></div>
              <div className='card'><p className='title'>5600</p> <p>Prediction</p></div></div>
          </div>}
        {activeButton === 2 && <div className='margin'>
          <div className='header-location'>
            <Button style={{ backgroundColor: '#AA7B59' }}>Delete</Button>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTag}
              onChange={(e) => handleFilterArray(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className='data-table'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.length === marginItems.length}
                        indeterminate={selectedRows.length > 0 && selectedRows.length < marginItems.length}
                        onChange={(e) => handleSelectAll(e, "margin")}
                      />
                    </TableCell>
                    <TableCell style={{ width: '100%' }} >Product</TableCell>
                    <TableCell style={{ minWidth: '300px' }}>Margin Percentage</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='table-body'>
                  {marginItems.filter(marginItem => marginItem.product.toLocaleLowerCase().includes(searchTag.toLocaleLowerCase())).map((item, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="table-row">
                      <TableCell padding="checkbox" className='TableCell'>
                        <Checkbox
                          checked={selectedRows.includes(item.id)}
                          onChange={(event) => handleSelectRow(event, item.id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" className='TableCell '>
                        <div className='margin-div'>
                          <img src='https://1.bp.blogspot.com/-kK7Fxm7U9o0/YN0bSIwSLvI/AAAAAAAACFk/aF4EI7XU_ashruTzTIpifBfNzb4thUivACLcBGAsYHQ/s1280/222.jpg' className="image"></img>
                          {item.product}
                        </div>

                      </TableCell>
                      <TableCell component="th" scope="row" className='TableCell'>
                        <TextField value={item.marginPercentage} placeholder='0%' style={{ width: '80px' }} variant='outlined'></TextField>
                        <Button variant='contained' style={{ backgroundColor: '#AA7B59', color: 'white', fontWeight: 'normal' }}>Apply</Button>
                      </TableCell>
                      <TableCell component="th" scope="row" className='TableCell'>
                        <img className='back-button action-icon'
                          // onClick={() => toggleDrawerHandler('edit-retailers', data)}
                          src={deleteButton} alt='' /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>}


        {activeButton === 3 &&
          <div className='location'>

            <div className='header-location'>
              <Button style={{ backgroundColor: '#AA7B59' }}>Delete</Button>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTag}
                onChange={(e) => handleFilterArray(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <div className='card-div'>
              {dummyData
                .filter(data => data.address.toLocaleLowerCase().includes(searchTag.toLocaleLowerCase()))
                .map((data, index) => (
                  <Card className='card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {data.address}
                    <div align="right" className="action-buttons-t-cell">

                      <img className='back-button action-icon'
                        // onClick={() => toggleDrawerHandler('edit-retailers', data)}
                        src={editButton} alt='' />
                      <img className='back-button action-icon'
                        //   onClick={() => openDeleteDevotionalModal(blog.id)}
                        src={deleteButton} alt='' />
                    </div>
                  </Card>
                ))}

              <Card className='card-add' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                + Add Address
              </Card>
            </div>

          </div>}


        {activeButton === 4 &&

          <div className='orders'>
            <div className='header-location'>
              <Button style={{ backgroundColor: '#AA7B59' }}>Delete</Button>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTag}
                onChange={(e) => handleFilterArray(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className='data-table'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.length === dummyOrderData.length}
                          indeterminate={selectedRows.length > 0 && selectedRows.length < dummyOrderData.length}
                          onChange={(e) => handleSelectAll(e, "order")}
                        />
                      </TableCell>
                      <TableCell >ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='table-body'>
                    {dummyOrderData.filter(dummyOrderData => dummyOrderData.customer.toLocaleLowerCase().includes(searchTag.toLocaleLowerCase())).map((item, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="table-row">
                        <TableCell padding="checkbox" className='TableCell'>
                          <Checkbox
                            checked={selectedRows.includes(item.id)}
                            onChange={(event) => handleSelectRow(event, item.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.id}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.date}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.customer}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.status}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.type}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>{item.total}</TableCell>
                        <TableCell component="th" scope="row" className='TableCell'>
                          <img className='back-button action-icon'
                            // onClick={() => toggleDrawerHandler('edit-retailers', data)}
                            src={editButton} alt='' /></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

          </div>}
        {activeButton === 5 && <div className='details' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Card style={{ width: '502px', height: 'auto', fontWeight: 'normal', marginTop: '2rem', borderRadius: '10px' }}>

            <div className='card'>
              <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
              <div className='row'>
                <p>{retailerDetails.retailer}</p>
                <div>
                  <img src={profileButton} />
                  <p>Anna Parker</p>
                </div>
              </div>
              <div className='row'>
                <div>
                  <img src={callButton} />
                  <p>9643238162</p>
                </div>

                <div>
                  <img src={emailButton} />
                  <p>naturessoul@gmail.com</p>
                </div>
              </div>
              <div className='row-end'>
                <img src={editButton} />
                <img src={deleteButton} />
              </div>
            </div>

          </Card>
        </div>}
        {activeButton === 6 && <div className='images'>

          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>

          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>
          <img src='https://img.freepik.com/free-photo/space-background-realistic-starry-night-cosmos-shining-stars-milky-way-stardust-color-galaxy_1258-154643.jpg' style={{ height: '170px', width: '280px' }}></img>


        </div>}
      </div>
    </div>
  );



}

export default RetailersDetailsDrawer;


const dummyData = [
  { id: 1, address: "123 Main St." },
  { id: 2, address: "456 Oak Ave." },
  { id: 3, address: "789 Pine Blvd." },
  { id: 4, address: "321 Elm St." },
  { id: 5, address: "654 Maple Ave." }
];

const dummyOrderData = [
  {
    id: 1,
    date: '2022-05-03',
    customer: 'John Doe',
    status: 'Pending',
    type: 'Online',
    total: 100.0,
  },
  {
    id: 2,
    date: '2022-05-02',
    customer: 'Jane Smith',
    status: 'Completed',
    type: 'In-Store',
    total: 50.0,
  },
  {
    id: 3,
    date: '2022-05-01',
    customer: 'Bob Johnson',
    status: 'Cancelled',
    type: 'Online',
    total: 75.0,
  },
  {
    id: 4,
    date: '2022-04-30',
    customer: 'Sarah Lee',
    status: 'Completed',
    type: 'In-Store',
    total: 25.0,
  },
];


const marginItems = [
  { id: 1, product: 'Product A', marginPercentage: 10 },
  { id: 2, product: 'Product B', marginPercentage: 12 },
  { id: 3, product: 'Product C', marginPercentage: 8 },
  { id: 4, product: 'Product D', marginPercentage: 15 },
  { id: 5, product: 'Product E', marginPercentage: 20 },
  { id: 6, product: 'Product F', marginPercentage: 18 },
  { id: 7, product: 'Product G', marginPercentage: 7 },
  { id: 8, product: 'Product H', marginPercentage: 14 },
  { id: 9, product: 'Product I', marginPercentage: 9 },
  { id: 10, product: 'Product J', marginPercentage: 11 },
];