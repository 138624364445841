import React, { useContext, useState, useEffect } from "react";
import { GrClose } from 'react-icons/gr';
import { BiCircle } from 'react-icons/bi';
import { AiFillCheckCircle } from 'react-icons/ai';
import Select from 'react-select';
import { useParams } from "react-router-dom";
import ModalContext from "../../../context/ModalContext.js";
import AuthContext from "../../../context/AuthContext.js";
import DrawerContext from "../../../context/DrawerContext.js";
import { CircularProgress } from "@mui/material";

const AddIngredient = () => {


  const { recipeId } = useParams();

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  const [newIngredientText, setNewIngredientText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getIngredients()
  }, [])


  const handleSaveChanges = () => {
    setLoading(true);
    handleAddIngredient();
  };

  const handleClose = () => {
    modalCtx.closeModal();
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleIpChange = (e) => {
    setNewIngredientText(e.target.value);
  }

  const handleAddIngredient = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/recipe/ingredient", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        recipeId: +drawerCtx.recipeId,
        productId: selectedOption?.value,
        ingredient: newIngredientText,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setNewIngredientText("");
        modalCtx.details.refreshIngredientsData();
        modalCtx.closeModal();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding new ingredient:", error);
      });
  };

  const getIngredients = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/products`, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
      })
      const data = await res.json()

      if (data.status == 'success') {
        setOptions(data.data)
      }

    } catch (error) {
      console.log('error getting ingredients : ', error)
    }
  }

  console.log(selectedOption)

  return (
    <div className="enter-ingredient-modal">
      <div className="enter-ingredient-heading">
        <h3>Add New Ingredient</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-ingredient-input-container">
        <h5>Ingredient</h5>
        <input type="text" placeholder="Ingredient" onChange={handleIpChange} />
      </div>
      <div className="link-hh-product">
        <h5>Link Himalayan Haat Product</h5>
        <Select
          className="select-tag"
          options={options.map((option, index) => ({
            value: option.id,
            label: (
              <div className="option-container" key={option.id}>
                {option.title}
              </div>
            ),
          }))}
          value={selectedOption?.id}
          onChange={handleOptionChange}
        />
      </div>
      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-primary" onClick={() => !loading && handleSaveChanges()}>
          {
            loading ?
              <CircularProgress style={{ 'color': '#ffffff' }} size={22} /> :
              'Add'
          }
        </button>
      </div>
    </div>
  );
};

export default AddIngredient;
