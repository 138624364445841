import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { InputLabel, Paper, IconButton, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TextField, MenuItem, FormControl, Button } from '@mui/material';
import { Delete, Done, Edit, NotInterestedOutlined } from '@mui/icons-material';
import RemoveIcon from "../../assets/img/drawer/remove.svg";
import EditIcon from "../../assets/img/blog/edit.svg"


import AuthContext from '../../context/AuthContext';
import DrawerContext from '../../context/DrawerContext';

import ModalContext from '../../context/ModalContext';


const OrderDetailsDrawer = props => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  console.log(props);
  // const orderDetails = props?.orderDetails?.orderDetails;
  // const order = props?.orderDetails?.orderDetails?.order;
  // const address = props?.orderDetails?.orderDetails?.order?.address;
  // const customer = props?.orderDetails?.orderDetails?.order?.customer;
  // const productsArr = props?.orderDetails?.orderDetails?.order?.products;
  // console.log(productsArr);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingDate, setIsSavingDate] = useState(false);
  const [zohoSetup, setZohoSetup] = useState(true);
  const [products, setProducts] = useState([]);
  const [customer, setCustomer] = useState({});
  const [retailer, setRetailer] = useState({});
  const [address, setAddress] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [orderId, setOrderId] = useState(drawerCtx?.details.orderId);
  const [customerId, setCustomerId] = useState(drawerCtx?.details.customerId);
  const [retailerId, setRetailerId] = useState(drawerCtx?.details.retailerId);
  const [orderType, setOrderType] = useState(drawerCtx?.details.orderType);
  const [order, setOrder] = useState({});
  const [newProduct, setNewProduct] = useState("");
  const [subTotal, setSubTotal] = useState(0);



  // const [shippingTotal, setShippingTotal] = useState(order.shippingTotal);
  // const [discountTotal, setDiscountTotal] = useState(order.discountTotal);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const [gstTotal, setGstTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [editTime, setEditTime] = useState(false);
  const [date, setDate] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [time, setTime] = useState("18:02:16");

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = () => {

    console.log("Running!");

    if(orderType == "retailer") {

      let options = {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      }
      Promise.all([
        fetch(process.env.REACT_APP_API_URI + '/v3/admin/retailers/orders/' + orderId, options).then(response => response.json()),
        fetch(process.env.REACT_APP_API_URI + '/v2/products', options).then(response => response.json()),
        fetch(process.env.REACT_APP_API_URI + '/v3/admin/retailers/' + retailerId + '/addresses', options).then(response => response.json())
      ]).then((data) => {
        console.log(data);
        if (data[0].status == "success" && data[1].status == "success") {
          console.log("We are running");
          setProducts(data[1]?.products.sort((a, b) => a.title.localeCompare(b.title)));
          setLineItems(data[0]?.data.products);
          setShippingTotal(data[0]?.data.shippingTotal);
          setRetailer(data[0]?.data.retailer);
          setDateAndTime(data[0]?.data.orderDate)
          // setAddress(data[0]?.data.address);
          setOrder(data[0].data);
          // setDiscountTotal(data[0]?.data.discountTotal);
          setIsLoading(false);
          console.log("ADDRESS", address, data[0]);

          let add = data[2]?.addresses.find(obj => obj.id == data[0]?.data.addressId);
          console.log(add);
          if(add) {
            setAddress(add);
          }
        }
    
      })
      .catch((err) => {
        console.log("Failed to fetch data...", err);
        setIsLoading(false);
      });
      
    } else {

      let options = {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      }
      Promise.all([
        fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderId, options).then(response => response.json()),
        fetch(process.env.REACT_APP_API_URI + '/v2/products', options).then(response => response.json()),
        fetch(process.env.REACT_APP_API_URI + '/v2/customer/' + customerId + '/addresses', options).then(response => response.json())
      ]).then((data) => {
        console.log(data);
        if (data[0].status == "success" && data[1].status == "success") {
          console.log("We are running");
          setProducts(data[1]?.products.sort((a, b) => a.title.localeCompare(b.title)));
          setLineItems(data[0]?.data.products);
          setShippingTotal(data[0]?.data.shippingTotal);
          setCustomer(data[0]?.data.customer);
          setDateAndTime(data[0]?.data.orderDate)
          // setAddress(data[0]?.data.address);
          setOrder(data[0].data);
          // setDiscountTotal(data[0]?.data.discountTotal);
          setIsLoading(false);
          console.log("ADDRESS", address, data[0]);

          let add = data[2]?.addresses.find(obj => obj.id == data[0]?.data.addressId);
          console.log(add);
          if(add) {
            setAddress(add);
          }
        }
    
      })
      .catch((err) => {
        console.log("Failed to fetch data...", err);
        setIsLoading(false);
      });

    }

  }

  // useEffect(() => {
  //   async function getAddress() {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/customer/${customer.id}/addresses`,
  //         {
  //           method: 'GET',
  //           headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
  //         })

  //       const res = await response.json();
  //       if (res.status == 'success') {
  //         console.log("this is response", res.addresses[0])
  //         setAddress(res?.addresses[0]);
  //       }

  //     } catch (err) {
  //       console.log("TROUBLED", err);
  //     }
  //   }
  //   // getAddress();
  // }, [authCtx.token, customer.id])

  useEffect(() => {
    console.log("Running");
    updateTotals(products);
  }, [lineItems, products, shippingTotal, discountTotal]);

  useEffect(() => {
		if(modalCtx.pageUpdate?.page === "ordersDrawer") {
      console.log("\n\nPage update requested. Refetching all orders...");
      if(modalCtx.pageUpdate?.updateType == "status") {
        order.status = modalCtx.pageUpdate?.status;
      } else if(modalCtx.pageUpdate?.updateType == "updateDetails") {
        fetchOrder();
      } 
      setOrder(order);
      modalCtx.setPageUpdate({ page: "" });
    } else {
      console.log("No page update requests detected...");
    }
	}, [modalCtx.pageUpdate]);

  const updateTotals = (currentProducts) => {

    let subTotalAmount = 0;
    let gstTotalAmount = 0;
    console.log(lineItems);
    console.log(products);

    lineItems.forEach(item => {

      let product = products.find(obj => obj.id == item.productId);
      console.log(product);

      if (!product.zoho_product_id) { setZohoSetup(false); }
      subTotalAmount = subTotalAmount + item.total;
      let multiplier = (product.gst / 100) + 1;
      item.gstPrice = parseFloat((item.total / multiplier).toFixed(2));
      item.gstItemPrice = parseFloat((item.price / multiplier).toFixed(2));
      item.gst = parseFloat((item.total - item.gstPrice).toFixed(2));
      item.zohoProductId = product.zoho_product_id;
      gstTotalAmount = gstTotalAmount + item.gst;

    });
    console.log(subTotalAmount);
    console.log(shippingTotal);
    console.log(discountTotal);
    setSubTotal(subTotalAmount);
    let newTotal = subTotalAmount + shippingTotal - discountTotal;

    setTotal(newTotal);
    setGstTotal(gstTotalAmount);

  }

  const newProductLine = (e) => {

    let payload = {
      orderId: orderId,
      orderType: orderType,
      productId: e.target.value
    }
    console.log(payload);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/order/' + orderId + '/newLine', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setLineItems([...lineItems, data.newLine]);
        // updateTotals(products);
      } else {
        console.log("products request error");
      }
    }).catch(err => console.error("products request error", err));

  }

  const updateProductLine = (id, e) => {
    console.log(id);
    let newLines = [];
    lineItems.forEach((line, i) => {
      console.log(line);
      if (line.id == id) {
        let product = products.find(function (obj) { return obj.id == e.target.value; });
        console.log("We get here");
        console.log(product);
        line.productId = e.target.value;
        line.title = product.title;
        line.variant = product.variant;
      }
      newLines.push(line);
    });
    setLineItems(newLines);
    // updateTotals(products);
  }

  const updateQuantity = (id, e) => {
    let newLines = [];
    lineItems.forEach((line, i) => {
      if (line.id == id) {
        line.quantity = e.target.value;
        line.total = line.quantity * line.price;
      }
      newLines.push(line);
    });
    console.log(newLines);
    setLineItems(newLines);
    updateTotals(products);
  }

  const updatePrice = (id, e) => {
    let newLines = [];
    lineItems.forEach((line, i) => {
      if (line.id == id) {
        line.price = e.target.value;
        line.total = line.quantity * line.price;
      }
      newLines.push(line);
    });
    console.log(newLines);
    setLineItems(newLines);
    updateTotals(products);
  }

  const deleteLine = (id) => {
    console.log(id);

    setLineItems(lineItems.filter((lineItem) => {
      return lineItem.id !== id
    }));

    fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderId + '/items/' + id, {
      method: 'DELETE',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        // setLineItems([...lineItems, data.newLine]);
      } else {
        console.log("products request error");
      }
    }).catch(err => console.error("products request error", err));
  }

  // const createInvoice = () => {

  //   fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderId + '/createZohoInvoice', {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
  //     body: JSON.stringify({ orderId: orderId, lineItems: lineItems })
  //   }).then(response => {
  //     return response.json();
  //   }).then(data => {
  //     console.log(data);
  //     if (data.status === "success") {
  //       // setProducts(data.products);        
  //       // updateTotals(data.products);
  //       // setIsLoading(false);
  //     } else {
  //       // setOpenSnackbar(true);
  //       console.error("products request error");
  //     }
  //   }).catch(err => console.error("products request error", err));

  // }

  const saveUpdate = () => {

    setIsSaving(true);

    console.log(lineItems);
    // console.log(order);
    // console.log(orderDetails);

    let payload = {
      orderType: orderType,
      lineItems: lineItems,
      cartTotal: subTotal,
      shippingTotal: shippingTotal,
      discountTotal: discountTotal,
      total: total
    }

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/order/' + orderId + '/updateLines', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setIsSaving(false);
        setIsEditing(false);
        order.products = lineItems;
        setOrder(order);
        modalCtx.setPageUpdate({page: "orders"});
        // setProducts(data.products);        
        // updateTotals(data.products);
        // setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
        console.error("products request error");
      }
    }).catch(err => console.error("products request error", err));

  }

  const handleModal = (data) => {

    let orderDetails = {
      customer: data,
      order: order
    }
    modalCtx.setDetails('change-order-address', orderDetails);
    modalCtx.openModal();

  }

  const handleAccountDetailsModal = (data) => {
    let orderDetails = {
      customer: data,
      order: order
    }
    modalCtx.setDetails('change-account-details', orderDetails);
    modalCtx.openModal();
  }

  const openConfirmationModal = (zohoSetup) => {
    let orderDetails = {
      zohoSetup: zohoSetup,
      order: order
    }
    modalCtx.setDetails('confirm-order', orderDetails);
    modalCtx.openModal();
  }

  const saveDateHandler = () => {
    setIsSavingDate(true);

    let payload = {
      date: date
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + orderId + '/updateDate', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setIsSavingDate(false);
        setDateAndTime(date);
        setEditTime(false);
        // setProducts(data.products);        
        // updateTotals(data.products);
        // setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
        console.error("products request error");
      }
    }).catch(err => console.error("products request error", err));

  }

  const setDateHandler = (e) => {
    console.log("DATE", e.target.value);
    setDate(e.target.value);
    const date = new Date(e.target.value);


    // const ISTOffset = 5.5 * 60 * 60 * 1000;
    // const ISTDate = new Date(date.getTime() + ISTOffset);

    // const hour = ISTDate.getHours();
    // const minute = ISTDate.getMinutes();
    // const seconds = ISTDate.getSeconds();

    // console.log("TIME NOW IS", hour, minute, seconds);
    // setDateAndTime(e.target.value);
    // setTime(`${hour}:${minute}:${seconds}`);
  }

  const closeModal = () => {
    console.log("CLOSESED");
    drawerCtx.closeDrawer();
  }

  const showStatusChip = () => {
    switch (order.status) {
      case "packing": return <span className="status-chip packing">Packing</span>;
      case "dispatched": return <span className="status-chip dispatched">Dispatched</span>;
      case "delivered": return <span className="status-chip delivered">Delivered</span>;
      default: return <span className="status-chip draft">Draft</span>;
    }
  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  const showMainButton = () => {
    console.log(order);
    if(order.status == 'pending') {
      return <Button className="confirm-btn black-btn" onClick={() => openConfirmationModal(zohoSetup)}>Confirm Order</Button>
      // return <Button className="confirm-btn black-btn" disabled={!zohoSetup} onClick={() => createInvoice()}>Confirm Order</Button>
    } else if(order.status == 'packing') {
      return <Button className="confirm-btn black-btn" onClick={() => openConfirmationModal(zohoSetup)}>Update Order</Button>
      // return <Button className="confirm-btn black-btn" disabled={!zohoSetup} onClick={() => createInvoice()}>Confirm Order</Button>
    } else {
      return null;
    }
    
  }

  return (
    <>
      <div className="container">
        <div className="drawer order-drawer">
          <div style={{height: "20px"}}></div>
          <div className='order-drawer-address'>
            <header className='order-drawer-header'>
              <div className='header-section-1'>
                <Button onClick={closeModal}><img src={RemoveIcon}></img></Button>
                <h1>Order No: {orderId?.toString()?.padStart(4, "0")}</h1> {showStatusChip()}
                {/* <p><strong>Date:</strong> {orderDetails.date.split("T")[0] + " " + orderDetails.date.split("T")[1]}</p> */}
              </div>

              {!editTime ? <div className='header-section-2'>
                  <Button onClick={() => setEditTime(true)}><img src={EditIcon}></img></Button>
                  <time dateTime='2023-05-02 18:02:16'><strong>Date:</strong> {dateAndTime}</time>
                </div> : <div className='header-section-2'>
                  <input className='date-input' onChange={setDateHandler} type='date' name='edit-date'></input>
                  <Button className='save-date-button' onClick={saveDateHandler}>{isSavingDate ? <CircularProgress size={10} style={{color: "#fff"}} /> : "Save"}</Button>
                </div>
              }
            </header>

            <div className="order-details">

              <div className="address-section">

                { 
                  orderType == "retailer" ? <div className="customer-info">
                    <div className="customer-name-section">
                      <Button onClick={(e) => handleAccountDetailsModal(retailer)}><img src={EditIcon}></img></Button>
                      <p className="customer-name">{ retailer?.retailer }&nbsp;</p>
                    </div>
                    {address && <div className='customer-address'>
                      <p>{address.address || ""}</p>
                      <p>{address.state}, {address.pin_code}</p>
                    </div>}
                    <div className='customer-contact-info'>
                      <p>Contact: {address?.phone}</p>
                      <p onClick={(e) => handleModal(customer)} style={{ color: '#AA7B59', fontWeight: 'bold', cursor: 'pointer' }}>Change Address</p>
                      {/* <Button className="change-btn black-btn">Change Adress</Button> */}
                    </div>
                  </div> : <div className="customer-info">
                    <div className="customer-name-section">
                      <Button onClick={(e) => handleAccountDetailsModal(customer)}><img src={EditIcon}></img></Button>
                      {address && <p className="customer-name">
                        {customer?.full_name || address.contact_name || customer?.first_name}&nbsp;
                      </p>}
                    </div>
                    {/* &nbsp;<Button className="change-btn black-btn">Change</Button></p> */}
                    {address && <div className='customer-address'>
                      <p>{address.address_line1 || ""}</p>
                      <p>{address.address_line2 || ""}</p>
                      <p>{address.pin_code}, {address.state}</p>
                    </div>}
                    <div className='customer-contact-info'>
                      <p>Contact: {customer?.phone}</p>
                      <p onClick={(e) => handleModal(customer)} style={{ color: '#AA7B59', fontWeight: 'bold', cursor: 'pointer' }}>Change Address</p>
                      {/* <Button className="change-btn black-btn">Change Adress</Button> */}
                    </div>
                  </div>
                }


                <div className="shop-info">
                  <p className="shop-name">Marrora Farm Pvt Ltd</p>
                  <p>T/A Himalayan Haat</p>
                  <p>Gadoli, Pauri - Srinagar Highway</p>
                  <p>Pauri Garhwal, Uttarakhand</p>
                  <p>GST No.: 05AAOCM5678H1ZY</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <TableContainer component={Paper} className="products-table-container">
              <Table sx={{ minWidth: 350 }} aria-label="products table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems?.map(items => {
                    return (
                      <TableRow key={items.id}>
                        <TableCell style={isEditing ? { padding: "2px 8px" } : null}>
                          {
                            isEditing ? <>
                              <IconButton aria-label="delete" onClick={(e) => { deleteLine(items.id) }}>
                                <Delete />
                              </IconButton>
                              <Select value={items.productId} inputProps={{ 'aria-label': 'Without label' }} onChange={e => updateProductLine(items.id, e)}>
                                {products?.map(product => {
                                  return (<MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>);
                                })}
                              </Select>
                            </> : <>{items.title} - {items.variant}</>
                          }
                        </TableCell>
                        <TableCell align="center" className="order-quantity" style={isEditing ? { padding: "2px 8px" } : null}>
                          {isEditing ? <TextField hiddenLabel type="number" placeholder="Quantity" value={items.quantity} onChange={(e) => updateQuantity(items.id, e)} fullWidth variant="outlined" size="normal" /> : <>{items.quantity}</>}
                        </TableCell>
                        <TableCell align="right" className="order-price" style={isEditing ? { padding: "2px 8px" } : null}>
                          {isEditing ? <TextField hiddenLabel type="number" placeholder="Price" value={items.price} onChange={(e) => updatePrice(items.id, e)} fullWidth variant="outlined" size="normal" /> : <>{items.price}</>}
                        </TableCell>
                        <TableCell align="right" className="order-total">{items.total}</TableCell>
                      </TableRow>
                    );
                  })}
                  {
                    isEditing ? <TableRow>
                      <TableCell style={{ padding: "2px 8px" }}>
                        <Select value={0} inputProps={{ 'aria-label': 'Without label' }} onChange={e => newProductLine(e)}>
                          <MenuItem value={0} key={0}>Add new product</MenuItem>
                          {products?.map(product => {
                            return (<MenuItem value={product.id} key={product.id}>{product.title} - {product.variant}</MenuItem>);
                          })}
                        </Select>
                      </TableCell>
                      <TableCell align="center" className="order-quantity" style={isEditing ? { padding: "2px 8px" } : null}>&nbsp;</TableCell>
                      <TableCell align="right" className="order-price" style={isEditing ? { padding: "2px 8px" } : null}>&nbsp;</TableCell>
                      <TableCell align="right" className="order-total">&nbsp;</TableCell>
                    </TableRow> : null
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div className="totals">
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">Sub Total</div>
                <div className="amount">{subTotal}</div>
              </div>
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">GST</div>
                <div className="amount">{Math.round(gstTotal, 2)}</div>
              </div>
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">Shipping</div>
                <div className="amount">{shippingTotal}</div>
              </div>
              <div className="total-row">
                <div className="spacer"></div>
                <div className="description">Discount</div>
                <div className="amount">{discountTotal}</div>
              </div>
              <div className="total-row total">
                <div className="spacer"></div>
                <div className="description"><strong>TOTAL</strong></div>
                <div className="amount"><strong>{total}</strong></div>
              </div>
            </div>

            {/* {editingOrder && <AddNewProduct />} */}

            {/* <CustomTable orderItems={productsArr} products={products} /> */}

            <div className="order-btns">
              {showMainButton()}
              <div className="spacer"></div>
              {isEditing ? <Button className="edit-products-btn cancel-btn" onClick={() => setIsEditing(!isEditing)}>Cancel</Button> : null}
              {!isEditing ? <Button className="edit-products-btn black-btn" onClick={() => setIsEditing(true)}>Edit Products</Button> : null}
              {isEditing ? <Button className="edit-products-btn black-btn" onClick={() => saveUpdate()}>{isSaving ? <CircularProgress size={10} style={{color: "#fff"}} /> : "Save Changes"}</Button> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );


  // const [products, setProducts] = useState([]);


}

export default OrderDetailsDrawer;
