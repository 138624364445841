import React, { useContext, useState, useEffect } from 'react'
import './Featured.scss'

//assets
import Add from '../../assets/img/blog/add.svg'
import Image from '../../assets/img/nav-logo.png'

//mui
import { useColorScheme } from '@mui/material'
//context 

import AuthContext from '../../context/AuthContext'
import ModalContext from '../../context/ModalContext'
//components
import GetImage from '../../common/GetImage'


const Featured = () => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)

  const [blogs, setBlogs] = useState([])
  const [courses, setCourses] = useState([])
  const [devotionals, setDevotionals] = useState([])
  const [loading, setLoading] = useState(false)
  const [featuredContent, setFeaturedContent] = useState([])

  useEffect(() => {
    // getFeaturedContent()
  }, [])
  useEffect(() => {
    getFeaturedContent()
  }, [modalCtx])

  const validateArray = (arr, index) => {
    // if(arr?.length < 1) return false
    if (arr[index]) {
      return true
    } else {
      return false
    }
  }



  // console.log(validateArray(blogs, 0))

  const handleModal = (name) => {
    modalCtx.setDetails(name);
    modalCtx.openModal();
  }

  const getFeaturedContent = (id) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URI}/v3/featured`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          const blogData = data?.featured?.filter?.(array => array.entity == "blog")
          setFeaturedContent(data.featured)
          setBlogs(blogData)
          setLoading(false)
        }
      }).catch(err => {
        console.error(err)
      });

  }

  const removeFeaturedContent = (id) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/featured/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setLoading(false)
        }
      }).catch(err => {
        console.error(err)
      });

  }


  return (
    <div className='featured-page-container'>
      <header>
        <h1>Featured</h1>
      </header>
      <div className="featured-page">
        <div className="featured-entities-container">
          <div className="featured-entities-header">
            <h3>Blogs</h3>
          </div>
          <div className="featured-entities">
            <div className="featured-entity">
              { 
                validateArray(blogs, 0)
                  ? <> 
                    <div className="image"><GetImage imageRef={blogs[0].image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className='title-text'>{blogs[0].title}</h3>
                        <div className="tag-input">
                          <textarea cols={40}  rows={4} style={{ borderRadius: "4px" }} />
                        </div>
                      </div>
                      <button className='btn-primary-border' onClick={() => removeFeaturedContent(blogs[0].id)} >Remove</button>
                    </div>
                  </> : <div className="add-to-container" onClick={() => handleModal("add-featured-blog")}>
                    <img src={Add} alt="add to" />
                    <span>Add Blog</span>
                  </div> 
              } 
            </div>
            <div className="featured-entity">
              {
                validateArray(blogs, 1)
                  ? <>
                    <div className="image"><GetImage imageRef={blogs[1].image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className='title-text'>{blogs[1].title} </h3>
                        <div className="tag-input">
                        <textarea cols={40}  rows={4} style={{ borderRadius: "4px" }} />
                        </div>
                      </div>
                      <button className='btn-primary-border' onClick={() => removeFeaturedContent(blogs[1].id)} >Remove</button>
                    </div>
                  </> : <div className="add-to-container" onClick={() => handleModal("add-featured-blog")}>
                    <img src={Add} alt="add to" />
                    <span>Add Blog</span>
                  </div>
              }
            </div>
            <div className="featured-entity">
              {
                validateArray(blogs, 2)
                  ? <> 
                    <div className="image"><GetImage imageRef={blogs[2].image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className='title-text'>{blogs[2].title} </h3>
                        <div className="tag-input">
                        <textarea cols={40}  rows={4} style={{ borderRadius: "4px" }} />
                        </div>
                      </div>
                      <button className='btn-primary-border' onClick={() => removeFeaturedContent(blogs[2].id)} >Remove</button>
                    </div>
                  </> : <div className="add-to-container" onClick={() => handleModal("add-featured-blog")}>
                    <img src={Add} alt="add to" />
                    <span>Add Blog</span>
                  </div>
              }
            </div>
            <div className="featured-entity">
              {
                validateArray(blogs, 3)
                  ? <>
                    <div className="image"><GetImage imageRef={blogs[3].image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className='title-text'>{blogs[3].title} </h3>
                        <div className="tag-input">
                          <textarea cols={40}  rows={4} style={{ borderRadius: "4px" }} />
                        </div>
                      </div>
                      <button className='btn-primary-border' onClick={() => removeFeaturedContent(blogs[3].id)} >Remove</button>
                    </div>
                  </> : <div className="add-to-container" onClick={() => handleModal("add-featured-blog")}>
                    <img src={Add} alt="add to" />
                    <span>Add Blog</span>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Featured