import React, { useState, useEffect, useCallback } from 'react';
import { signInWithEmailAndPassword, signOut, onIdTokenChanged } from 'firebase/auth';

import { auth } from "./Firebase"

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  userId: '',
  userName: '',
  email: '',
  scope: '',
  isLoggedIn: false,
  login: (token, userId, userName, email, scope, tokenExpiration) => {},
  logout: () => {}
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime               = new Date().getTime();
  const adjExpirationTime         = new Date(expirationTime).getTime();

  const remainingDuration         = adjExpirationTime - currentTime;

  return remainingDuration;
}

const retrieveStoredToken = () => {
  const storedToken               = localStorage.getItem('token');
  const storedTokenExpiration     = localStorage.getItem('tokenExpiration');

  const remainingTime             = calculateRemainingTime(storedTokenExpiration);

  if(remainingTime <= 1000) {

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('scope');

    return null;
  }
  
  return {
    token: storedToken,
    duration: remainingTime
  };
}

export const AuthContextProvider = (props) => {


  const initialToken              = localStorage.getItem('token');
  const initialUserId             = localStorage.getItem('userId');
  const initialUserName           = localStorage.getItem('userName');
  const initialEmail              = localStorage.getItem('email');
  const initialScope              = localStorage.getItem('scope');

  const [token, setToken]         = useState(initialToken);
  const [userId, setUserId]       = useState(initialUserId);
  const [userName, setUserName]   = useState(initialUserName);
  const [email, setEmail]         = useState(initialEmail);
  const [scope, setScope]         = useState(initialScope);
  const userIsLoggedIn            = !!token;

  const logoutHandler = useCallback(() => {

    signOut(auth)
    .then(() => {
      console.log("Signed out");
      setToken(null);
      setUserId(null);

      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    })
    .catch((error) => {
      console.log(error);
    });
    

    if(logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
  
    onIdTokenChanged(auth, data => {
      if(data) {
        // console.log(data);
        setToken(data.accessToken);
        localStorage.setItem('token', data.accessToken);
      }
    })
  }, [onIdTokenChanged, setToken]);

  const loginHandler = (email, password, setResponse) => {

    signInWithEmailAndPassword(auth, email, password)
    .then((data) => {
      
      setToken(data.user.accessToken);
      setUserId(data.user.uid);

      localStorage.setItem('token', data.user.accessToken);
      localStorage.setItem('userId', data.user.uid);

      console.log('logged in ', data)

    })
    .catch((error) => {
      console.log(error, "this is error");

      
      let errorMessage = "An error occurred during login.";
      if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Email or password incorrect.";
      } else if (error.code === "auth/too-many-requests") {
        errorMessage = "Too many failed logins. Please wait a while and try again.";
      }
      setResponse(errorMessage);

    });
    
  

  }

  const contextValue = {
    token: token,
    userId: userId,
    userName: userName,
    email: email,
    scope: scope,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler
  }
  console.log(contextValue);
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;