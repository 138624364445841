import { useState, useEffect, useContext } from 'react';

import CircularProgress from '@mui/material';

import AuthContext from '../../../context/AuthContext.js';
import DrawerContext from '../../../context/DrawerContext.js';
import ModalContext from '../../../context/ModalContext.js';
//assets
import NoImage from '../../../assets/img/no-image.gif';
import { AddRounded, DeleteOutlineRounded, EditRounded } from '@mui/icons-material';


function UploadProductImageDrawerNew({ details }) {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);


  const [product, setProduct] = useState();
  const [productImages, setProductImages] = useState();

  useEffect(() => {
    setProduct(details)
    getProductImages(details?.id)
  }, [details])


  useEffect(() => {

    getProduct()
    getProductImages(product?.id)

  }, [modalCtx])

  const openUploadImageModal = (origin, id, product) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 0.65, origin: origin, id: id, product: product });
    modalCtx.openModal();
  }
  const openDeleteImageModal = (id) => {
    modalCtx.setDetails('confirm-delete-product-additional-image', { id: id });
    modalCtx.openModal();
  }

  const getProduct = async () => {

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/products/${product?.id}/details`)
      const data = await response.json()

      if (data.status === "success") {
        setProduct(data.data[0])
      }

    } catch (error) {
      console.log(error)
    }

  }

  const getProductImages = async (id) => {

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/products/${id}/images`)
      const data = await response.json()

      if (data.status === "success") {
        setProductImages(data.data)
      }

    } catch (error) {
      console.log(error)
    }

  }

  console.log(product)

  return (
    <div className="drawer-container">
      <div className="drawer product-image-drawer-new">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Product Images</h1>
        </header>

        <section>

          <div className="product-display-img">
            <div className="product-display-header">Display Image</div>
            <div className="product-display-content">
              <div className="display-img">
                {
                  product?.image == null
                    ? <img src={NoImage} alt="no uploaded" />
                    : <img src={product?.image} alt="display" />
                }
              </div>

              {
                product?.image == null
                  ? <button className="btn-primary" onClick={() => openUploadImageModal('product-display-image', product?.id, details)}>Add Display Image</button>
                  : <button className="btn-primary" onClick={() => openUploadImageModal('product-display-image', product?.id, details)}>Replace Display Image</button>
              }
            </div>
          </div>

          <div className="product-additional-img">
            <div className="product-additional-header">Additional Images</div>
            <div className="product-additional-content">
              <div className="additional-images">
                {
                  productImages?.length > 0
                    ? productImages?.map((item, index) => (
                      <div key={index} className="additional-img">
                        <img src={item?.image} alt="display" />
                        <div className="edit-icon">
                          <EditRounded onClick={() => openUploadImageModal('update-product-additional-image', item.id)} />
                        </div>
                        <div className="delete-icon" onClick={() => openDeleteImageModal(item?.id)}>
                          <DeleteOutlineRounded style={{ color: '#F00' }} />
                        </div>
                      </div>
                    ))
                    : null
                }
                <div className="add-additional-img" onClick={() => openUploadImageModal('product-additional-image', product?.id, details)} >
                  <AddRounded className='add-icon' />
                  <div>Add Additional images</div>
                </div>
              </div>
            </div>
          </div>

        </section>

      </div>
    </div>
  );

}
export default UploadProductImageDrawerNew;