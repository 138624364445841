import { GridCloseIcon } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import DrawerContext from '../../../context/DrawerContext';
import AuthContext from '../../../context/AuthContext';

const RecipeDrawer = ({ drawerDetails }) => {
  const drawerCtx = useContext(DrawerContext);

  // Define states for form inputs
  const [recipeName, setRecipeName] = useState();
  const [totalTime, setTotalTime] = useState();
  const [prepTime, setPrepTime] = useState();
  const [serves, setServes] = useState();
  const [postData, setPostData] = useState({
    "title": recipeName,
    "totalTime": totalTime,
    "prepTime": prepTime,
    "serves": serves
  });
  const authCtx = useContext(AuthContext);

  useEffect(()=>{
    setPostData({
      "title": recipeName,
      "totalTime": totalTime,
      "prepTime": prepTime,
      "serves": serves
    })
  },[recipeName,totalTime,prepTime,serves])

  // Event handlers to update the states
  const handleRecipeNameChange = (e) => {
    setRecipeName(e.target.value);
  };

  const handleTotalTimeChange = (e) => {
    setTotalTime(e.target.value);
  };

  const handlePrepTimeChange = (e) => {
    setPrepTime(e.target.value);
  };

  const handleServesChange = (e) => {
    setServes(e.target.value);
  };

  const handleClose = () => {
    // Handle closing the drawer here
    drawerCtx.closeDrawer();
  };

  // Post Request to send recipe into database
  const sendRecipeRequest = () =>{
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/recipe`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + authCtx.token
      },
      body: JSON.stringify(postData),
    })
    .then((res)=>{
      return res.json();
    })  
    .then((data)=>{
      console.log(data)
    })
  }

  const handleSaveChanges = () => {
   //Handelling save changes button here
    sendRecipeRequest();
    drawerCtx.closeDrawer();
  }

  return (
    <div className="edit-recipe-container">
      <div className="drawer recipe-drawer-container">
        <div className="recipe-drawer">
          <div className="recipe-drawer-header">
            <header>
              <GridCloseIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              />
              <h1>Create Recipe</h1>
            </header>
          </div>
          <div className="recipe-input-box-container">
            <div className="ingredient-input-box-container">
              <div className="form-label">Recipe Name</div>
              <input
                type="text"
                placeholder="Recipe Name"
                className="input-box"
                value={recipeName}
                onChange={handleRecipeNameChange}
              />
            </div>
            <div className="ingredient-input-box-container">
              <div className="form-label">Total Time</div>
              <input
                type="number"
                placeholder="eg: 1 h"
                className="input-box"
                value={totalTime}
                onChange={handleTotalTimeChange}
              />
            </div>
            <div className="ingredient-input-box-container">
              <div className="form-label">Prep Time</div>
              <input
                type="number"
                placeholder="eg: 40 min"
                className="input-box"
                value={prepTime}
                onChange={handlePrepTimeChange}
              />
            </div>
            <div className="ingredient-input-box-container">
              <div className="form-label">Serves</div>
              <input
                type="number"
                placeholder="eg: 5"
                className="input-box"
                value={serves}
                onChange={handleServesChange}
              />
            </div>
            <div className="ingredient-save-changes-btn">
              <button
                className="cancel-btn"
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className="save-btn" disabled={
                recipeName==null || serves==null || prepTime==null || totalTime==null ? true : false
              } style={{
                cursor: 'pointer',
              }} onClick={handleSaveChanges}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeDrawer;