import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, MenuItem, FormControl, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';



const CreateOrderDrawer = (props) => {

  console.log("customerDetailsDrawer", props?.customerDetails);
  const customerDetails = props?.customerDetails.customer;
  console.log(customerDetails);

  const [isLoading, setIsLoading] = useState(true);

  return (<>

    <div className="create-order-drawer">
      <div className="drawer">


        <div className="order-details">
          <h1>Customers Id: {customerDetails.id}</h1>
          <div className="customer-info">
            <p>Name: {customerDetails.fullName}</p>
          </div>
        </div>
      </div>
    </div>


  </>);


  // const [products, setProducts] = useState([]);


}

export default CreateOrderDrawer;
