import React, { useContext, useState } from 'react'
import { GridCloseIcon } from '@mui/x-data-grid'
import ModalContext from '../../../context/ModalContext'
import { Button } from '@mui/material'
import AuthContext from '../../../context/AuthContext'

const DeleteTagModal = () => {

  const modalCtx = useContext(ModalContext)
  const authCtx = useContext(AuthContext)

  const [allTags, setAllTags] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  const handleCloseModal = () => {
    modalCtx.closeModal()
  }
  
  const deleteTopic = (id) => {
    const apiUrl = process.env.REACT_APP_API_URI + "/v3/admin/tag/" + modalCtx.details.id;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authCtx.token,
    };

    fetch(apiUrl, {
      method: "DELETE",
      mode: "cors",
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `Network response was not ok (Status: ${res.status})`
          );
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setAllTags(data)
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
      modalCtx.closeModal()
  };

  return (
    <div className="modal">
      <div className="close-modal">
        <p>Are you sure you want to delete this tag?</p>
        <GridCloseIcon style={{ cursor: "pointer" }} onClick={() => modalCtx.closeModal()} />
      </div>
      <div className="footer">

        <Button className="cancel-btn" onClick={() => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => {
          deleteTopic(allTags?.id);
          isDeleted ? setIsDeleted(false) : setIsDeleted(true);
        }}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteTagModal