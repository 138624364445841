import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';


const OrderDeleteModal = ({ modalDetails }) => {

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  console.log(modalDetails);

  const [isLoading, setIsLoading] = useState(false);

  const deleteOrder = () => {

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/order/` + modalDetails?.data.id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {        
        modalCtx.setPageUpdate({page: "orders"});
        modalCtx.closeModal();
        setIsLoading(false);
      }
    }).catch(err => {
      console.error(err)
      setIsLoading(false);
    });

  }

  return(
    <>
      <div className='delete-order-modal-container'>
        <h3>Are you sure that you wish to delete the Order?</h3>
        <div className='btns'>
          <Button className='close-btn' variant='outlined' component='label' disabled={false}
            onClick={(e) => modalCtx.closeModal()}>Close
          </Button>
          <Button className='delete-btn' variant='contained' component='label' disabled={false} onClick={() => deleteOrder()}>
            {isLoading ? <><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></> : <>Delete</>}
          </Button>
       </div>
      </div>
    </>
  )
}

export default OrderDeleteModal;