import React, { useContext } from 'react';

import RetailersTable from "./components/RetailersTable";

import DrawerContext from '../../context/DrawerContext';

const Retailers = () => {

  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  return (
    <>
      <div className="retailers-page">

        <header className="template-header" >
          <h1 data-aos="fade-right" data-aos-delay="500">Retailers</h1>
          <div>
            <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-retailer', null) }}>Create</button>
          </div>
        </header>

        <section className='template-section' >
          <RetailersTable />
        </section>

      </div>
    </>
  );

}
export default Retailers;