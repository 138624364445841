import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';

const  DeleteRetailModel = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteBlog = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v2/admin/retailers-contacts/' + id, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting blog...");
        }
      }).catch((err) => {
        console.error("Error while deleting blog...", err);
      });
    }
  }

  return (
    <div className="modal">
      <p>Are you sure you want to delete this Retailer?</p>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteBlog(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteRetailModel;
