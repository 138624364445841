import React, { useContext, useState } from 'react'
import ModalContext from '../../../context/ModalContext'
import AuthContext from '../../../context/AuthContext'
import { GrClose } from 'react-icons/gr'


const EditTopics = () => {

  const modalCtx = useContext(ModalContext)
  const authCtx = useContext(AuthContext)

  const [topicTitle, setTopicTitle] = useState()

  const handleClose = () => {
    modalCtx.closeModal()
  }

  console.log(modalCtx.details.id)

  const handleSaveChanges = () => {
    const payload = {
      title: topicTitle,
    };

    fetch(
      `https://apiv2.himalayanhaat.org/v3/admin/topic/${modalCtx.details.id}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (response.ok) {
        } else {
          throw new Error("Failed to update topic data");
        }
      })
      .catch((error) => {
        console.error("Error updating topic data:", error);
      });
      handleClose()
  };

  return (
    <div className="enter-time-modal">
      <div className="enter-time-heading">
        <h3>Edit Topic</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-time-input-container">
        {
          <input
            type="text"
            placeholder="edit topic"
            value={topicTitle}
            onChange={(e) => setTopicTitle(e.target.value)}
          />
        }
      </div>
      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-primary" onClick={handleSaveChanges}>
          Save
        </button>
      </div>
    </div>
  )
}

export default EditTopics