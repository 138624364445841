import React, { useState } from 'react'
import { useContext } from 'react';
import DrawerContext from '../../../context/DrawerContext';
import { GridCloseIcon } from '@mui/x-data-grid';
import '../Topics/TopicsDrawer.scss'
import AuthContext from '../../../context/AuthContext';

const TopicsDrawer = () => {

  const authCtx = useContext(AuthContext);


  const drawerCtx = useContext(DrawerContext);
  const [topicName, setTopicName] = useState()
  


  const handleTopicNameChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleSaveChanges = () => {
    //Handelling save changes button here
    sendTopicRequest();
    drawerCtx.closeDrawer();
  }
  //post request to send the topics in database

  const sendTopicRequest = () => {
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/topic`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + authCtx.token
      },
      body: JSON.stringify(
        {
          title: topicName,
        }
      ),
      
    })
    .then((res)=>{
      return res.json();
    })  
    .then((data)=>{
      console.log(data)
    })
  }  



  return (
    <div className="edit-topics-container">
      <div className="drawer topics-drawer-container">
        <div className="topics-drawer">
          <div className="topics-drawer-header">
            <header>
              <h1>Create Topic Drawer</h1>
            </header>
          </div>
          <div className="topics-input-box-container">
            <div className="sub-topics-input-box-container">
              <div className="form-label">Title</div>
              <input
                type="text"
                placeholder="Title"
                className="input-box"
                value={topicName}
                onChange={handleTopicNameChange}
              />
            </div>
            <div className="btn-container">
              <button className="save-btn" onClick={handleSaveChanges} >Create Topic</button>
            </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TopicsDrawer