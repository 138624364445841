import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material'

//context
import DrawerContext from '../context/DrawerContext';

//drawers
import UserDrawer from '../utils/Drawers/UsersDrawer';
import CreateProductDrawer from '../utils/Drawers/CreateProductDrawer';
import EditProductDrawer from '../utils/Drawers/Products/EditProductDrawer.jsx';
import UploadProductImageDrawer from '../utils/Drawers/Products/UploadProductImageDrawer.jsx';
import OrderDetailsDrawer from '../utils/Drawers/OrderDetailsDrawer';
import CustomerDetailsDrawer from '../utils/Drawers/CustomerDetailsDrawer';
import CreateBlogDrawer from '../utils/Drawers/Blogs/CreateBlogDrawer';
import EditBlogDrawer from '../utils/Drawers/Blogs/EditBlogDrawer';
import CreateOrderDrawer from "../utils/Drawers/Orders/CreateOrderDrawer";
import RetailersDetailsDrawer from '../utils/Drawers/Retailers/RetailersDetailsDrawer';
import RecipeDrawer from '../utils/Drawers/Recipe/RecipeDrawer';
import TopicsDrawer from '../utils/Drawers/Topics/TopicsDrawer.jsx';
import CreateNewAuthor from '../utils/Drawers/CreateNewAuthor.jsx';
import EditAuthor from '../utils/Drawers/EditAuthor.jsx';
import TagDrawer from '../utils/Drawers/Tags/TagDrawer.jsx';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

  const drawerCtx = useContext(DrawerContext);

  const { pathname } = useLocation();

  function closeDrawer() {
    drawerCtx.closeDrawer();
  }

  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  const currentDrawer = () => {

    switch (drawerCtx.drawer) {

      // Users
      case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UserDrawer userDetails={drawerCtx.details} /></Drawer>;
      case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>Create New User</h1></Drawer>;
      case "create-product": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateProductDrawer /></Drawer>;
      case "product-image-upload": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UploadProductImageDrawer details={drawerCtx.details} /></Drawer>;
      case "edit-product": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditProductDrawer productDetails={drawerCtx.details} /></Drawer>;

      //Orders
      case "create-order": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateOrderDrawer customerDetails={drawerCtx.details} /></Drawer>;
      case "order-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer} disableEnforceFocus><OrderDetailsDrawer orderDetails={drawerCtx.details} /></Drawer>;
      case "customer-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CustomerDetailsDrawer customerDetails={drawerCtx.details} /></Drawer>;

      //Retailers
      case "retailer-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><RetailersDetailsDrawer retailerDetails={drawerCtx.details} /></Drawer>;
      case "edit-retailers": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><RetailersDetailsDrawer retailerDetails={drawerCtx.details} /></Drawer>;

      //create Blog Drawer

      case "create-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBlogDrawer /></Drawer>;
      case "edit-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
      case "create-recipe": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><RecipeDrawer/></Drawer>;
      
      //Topics
      case "create-topics": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><TopicsDrawer/></Drawer>;
      
      //Tags
      case "create-tags": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><TagDrawer /></Drawer>;
      

      // Author
      case "create-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNewAuthor drawerDetails={drawerCtx.details}/></Drawer>
      case "edit-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditAuthor drawerDetails={drawerCtx.details}/></Drawer>

      default: return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
    }
  }

  return (
    <div>{currentDrawer()}</div>
  );

}
export default DetailsDrawer;
