import React from 'react'

const Overview = () => {
  return (
    <div className="overview-page">

      <header className="template-header" >
        <h1 data-aos="fade-right" data-aos-delay="500">Overview</h1>
        <div>
          {/* <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-overview', null) }}>Create New</button> */}
        </div>
      </header>

      <section className='template-section' ></section>
    </div>
  )
}

export default Overview