import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import DrawerContext from '../../context/DrawerContext';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MdOutlineClose } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { FiPhone, FiMail, FiEdit, FiTrash2   } from "react-icons/fi";
import { FaRegEdit, FaRegTrashAlt  } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { DataGrid } from '@mui/x-data-grid';
import AuthContext from '../../context/AuthContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function UsersDrawer(props) {

  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const [orderData, setOrderData] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);

  console.log(orderData )
  const stats_data = [
    { month: 'Jan', sales: 1400 },
    { month: 'Feb', sales: 900 },
    { month: 'Mar', sales: 1100 },
    { month: 'Apr', sales: 1600 },
    { month: 'May', sales: 700 },
    { month: 'June', sales: 1200 },
    { month: 'July', sales: 1100 },
    { month: 'Aug', sales: 1400 },
    { month: 'Sept', sales: 800 },
    { month: 'Oct', sales: 1500 },
    { month: 'Nov', sales: 650 },
    { month: 'Dec', sales: 1350 },
  ];

  console.log(props);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // const orders = [
  //   {
  //     id: 101,
  //     date: "20-12-2022",
  //     status: "Delivered",
  //     type: "Cooler Lemon",
  //     total: 300
  //   },
  //   {
  //     id: 102,
  //     date: "25-12-2022",
  //     status: "On the Way",
  //     type: "Pasta Sauce",
  //     total: 150
  //   },
  //   {
  //     id: 103,
  //     date: "28-12-2022",
  //     status: "Canceled",
  //     type: "Apple Cider Vinegar",
  //     total: 280
  //   },
  // ]

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 100, headerAlign: "center", align: "center", 
    },
    { field: 'date', headerName: 'Date', flex: 1, },
    {
      // field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => <div className={`status bg-${params.row.status === "On the Way" ? 'on-the-way' : params.row.status.toLowerCase()}`}>{params.row.status}</div>
      field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => <div></div>
    },
    {
      field: 'type', headerName: 'Type', flex: 1,
    },
    {
      field: 'total', headerName: 'Total', flex: 1, renderCell: (params) => <div>Rs. {params.row.total}/-</div>
    },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='btn-container'>
        <FaRegEdit size={20} color='#000' />
      </div>
    },

  ];

  useEffect(() => {

    //to get orderdata
    fetch(process.env.REACT_APP_API_URI + '/v3/customer/' + props?.userDetails?.id + '/orders',  {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', },
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
         setOrderData(data?.orders)
      } else {
        console.error("order request error");
      }
    }).catch(err => console.error("order request error", err));
    
  }, [])
  

  //to get adresss
  const getUserAddress=() => {
    fetch(process.env.REACT_APP_API_URI + '/v3/customer/' + props?.userDetails?.id + '/addresses', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', },
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setCustomerAddresses(data?.addresses)
      } else {
        console.error("address request error");
      }
    }).catch(err => console.error("adress request error", err));

  }
 
  //to get stats
  const getStats = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/stats', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
      
      } else {
        console.error(" request error");
      }
    }).catch(err => console.error(" request error", err));

  }

  useEffect(() => {

    getUserAddress();
    getStats();

  }, [])
  
  // const addresses = [ "No.24, XYZ Street, 4th Cross, sdadadadaaaa, zxyssss, ssssssahgsfs HRBR Layout, Delhi - 110023", "No.24, XYZ Street, 4th Cross, sdadadadaaaa, zxyssss, ssssssahgsfs HRBR Layout, Delhi - 110023", "No.24, XYZ Street, 4th Cross, sdadadadaaaa, zxyssss, ssssssahgsfs HRBR Layout, Delhi - 110023",]
  return (
    <div className="container">
      <div className="drawer">

        <header>
        <MdOutlineClose size={30} color='#000000' onClick={() => drawerCtx.closeDrawer()} style={{cursor: "pointer"}} />
          <h1 data-aos="fade-right">{props.userDetails.fullName}</h1>
        </header>
        <Tabs value={selectedTab} onChange={handleChange} style={{background: "transpaent"}}>

          <Tab label="Statistics" />
          <Tab label="Orders" />
          <Tab label="Details" />
          <Tab label="Addresses" />

        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <div className='tab-panel'>
            <div className="light-bg">
              <div className="chart">
                <Chart
                  data={stats_data}
                >
                  <ArgumentAxis />
                  <ValueAxis showTicks={true} />

                  <BarSeries
                    valueField="sales"
                    argumentField="month"
                    color='#AA7B59'
                  />
                  <Title text="Statistics" />
                  <Animation />
                </Chart>
              </div>
            </div>
            <div className='flex'>
              <div className="card">
                <div className="number">24</div>
                <div className="text">Sales</div>
              </div>
              <div className="card">
                <div className="number">April</div>
                <div className="text">Top Month</div>
              </div>
              <div className="card">
                <div className="number">6500</div>
                <div className="text">Predection</div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <div className='orders-panel'>
            <div className="search-flex">
              <button>Delete</button>
              <input type="text" placeholder='Search'  />
            </div>
            <div className='template-section'>
              <DataGrid
                sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
                rows={orderData}
                columns={columns}
                checkboxSelection
                selectionModel={selectedRows}
                disableSelectionOnClick={true}
                onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <div className="user-details-card">
            <div className='single-detail'>
              <FaRegUser color='#000' size={20} />
              <div>{props.userDetails.fullName}</div>  
            </div>
            <div className='single-detail'>
              <FiPhone color='#000' size={20} />
              <div>{props.userDetails.phone}</div>  
            </div>
            <div className='single-detail-editable'>
              <div className='inner-flex'>
                <FiMail color='#000' size={20} />
                <div>{props.userDetails.email}</div>  
              </div>
              <div className='inner-flex'>
                <FiEdit color='#000' size={20} /> 
                <FiTrash2 color='#000' size={20} /> 
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <div className='address-panel'>
            <div className="search-flex">
              <button>Delete</button>
              <input type="text" placeholder='Search'  />
            </div>
            <div className='address-flex'>
              {customerAddresses.map((item,i) => <div className='address-card' key={i}>
                <div> {item?.address_line1}, {item?.address_line2} , {item?.city}, {item?.state}</div>
                <p>{item?.country} - {item?.pin_code}</p>
                <div className="buttons"><FaRegEdit color='#000' size={20} /> <FaRegTrashAlt color='#000' size={20} /></div>
              </div>)}
              <div className='add-address-card'>
                <FaPlus size={30} color='#9B9B9B' /> Add Address
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );

}
export default UsersDrawer;

