import React, { useContext, useState, useEffect } from "react";
import { GrClose } from 'react-icons/gr';
import { BiCircle } from 'react-icons/bi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useParams } from "react-router-dom";
import ModalContext from "../../../context/ModalContext.js";
import AuthContext from "../../../context/AuthContext.js";
import DrawerContext from "../../../context/DrawerContext.js";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

var initialSuggestions = [];

const CreateCoupon = ({modalDetails}) => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  useEffect(() => {
    modalDetails.data.map((item) => {initialSuggestions.push(item.customer)})
  }, [])

  const [customer, setCustomer] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("once_off");
  const [customerType, setCustomerType] = useState("general");

  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCustomer(value);
    if (value) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCustomer(suggestion);
    setFilteredSuggestions([]);
  };


  const handleSaveChanges = () => {
    setLoading(true);
    handleAddMethod();
  };

  const handleClose = () => {
    modalCtx.closeModal();
  };


  const handleCouponCode = (e) => {
    setCouponCode(e.target.value);
  }

  const handleCustomer = (e) => {
    setCustomer(e.target.value);
  }

  const handleAddMethod = () => {
    const sendData = { 
      "couponCode": couponCode,
      "couponType": couponType,
      "customerType": customerType,
      "customer": customer
    }
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/coupon", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify(sendData),
    })
      .then((res) => res.json())
      .then(() => {
        setCouponCode("");
        // modalCtx.details.refreshMethodsData();
        modalCtx.closeModal();
        setLoading(false);
        modalDetails.refresh(!modalDetails.value);
      })
      .catch((error) => {
        console.error("Error adding new coupon:", error);
      });
  };


  return (
    <div className="enter-ingredient-modal">
      <div className="enter-ingredient-heading">
        <h3>Add New Coupon</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-ingredient-input-container">
        <h5>Coupon Code</h5>
        <input type="text" placeholder="Coupon Code" value={couponCode} onChange={handleCouponCode} />
      </div>
      <div className="enter-ingredient-input-container">
        <h5>Coupon Type</h5>
        <div className="checkbox-container">
          <FormControlLabel value="once_off" control={<Radio checked={couponType == "once_off"} onClick={() => setCouponType("once_off")} />} label="Once-off" />
          <FormControlLabel value="all_time" control={<Radio checked={couponType == "all_time"} onClick={() => setCouponType("all_time")} />} label="All-time" />
        </div>
      </div>
      <div className="enter-ingredient-input-container">
        <h5>Customer Type</h5>
        <div className="checkbox-container">
          <FormControlLabel value="general" control={<Radio checked={customerType == "general"} onClick={() => setCustomerType("general")} />} label="General" />
          <FormControlLabel value="single" control={<Radio checked={customerType == "single"} onClick={() => setCustomerType("single")} />} label="Single" />
        </div>
      </div>
      {
        customerType == "single"
          ? <div className="enter-ingredient-input-container">
            <h5>Customer</h5>
            {/* <input type="text" placeholder="Customer" value={customer} onChange={handleCustomer} /> */}
            <div style={{ position: 'relative'}}>
              <input type="text" placeholder="Customer" value={customer} onChange={handleInputChange} />
              {filteredSuggestions.length > 0
                && ( <ul>
                {filteredSuggestions.map((suggestion) => (
                  <li key={suggestion} onClick={() => handleSuggestionClick(suggestion)} >{suggestion}</li>
                ))}
              </ul> )}
            </div>
          </div>
          : null
      }

      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-primary" onClick={() => !loading && handleSaveChanges()}>
          {
            loading ?
              <CircularProgress style={{ 'color': '#ffffff' }} size={22} /> :
              'Add'
          }
        </button>
      </div>
    </div>
  );
};

export default CreateCoupon;
