import React, { createContext, useEffect, useState } from 'react';

const DrawerContext = createContext({
  open: false,
  drawer: 'none',
  details: {},
  pageUpdate: {},
  recipeId: null, // Add the recipeId to the context
  setDetails: (setDetails) => {},
  setPageUpdate: (setPageUpdate) => {},
  setRecipeId: (setRecipeId) => {}, // Add a setter function for recipeId
  openDrawer: (openDrawer) => {},
  closeDrawer: (closeDrawer) => {},
});

export function DrawerContextProvider(props) {
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState('none');
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});
  const [recipeId, setRecipeId] = useState(); // Initialize recipeId as null

  useEffect(()=>{
    console.log('recipeId',recipeId)
  },[recipeId])

  function openDrawerHandler() {
    setOpen(true);
  }

  function closeDrawerHandler() {
    setOpen(false);
  }

  function setDetailsHandler(drawer, data) {
    setDrawer(drawer);
    setDetails(data);
  }

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  const context = {
    open: open,
    drawer: drawer,
    details: details,
    pageUpdate: pageUpdate,
    recipeId: recipeId, // Include recipeId in the context
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    setRecipeId: setRecipeId, // Add setter function for recipeId
    openDrawer: openDrawerHandler,
    closeDrawer: closeDrawerHandler,
  };

  return <DrawerContext.Provider value={context}>{props.children}</DrawerContext.Provider>;
}

export default DrawerContext;