import moment from "moment/moment"

// Input
// GetTimeFormatted("2018-05-16 12:57:13", "DD/MM/YYYY")
// Output : "16/05/2018"

const GetTimeFormatted = (time, format) => {

  if (!time) return null;

  const convertedTime = moment(time)?.format(format ? format : "DD/MM/YYYY")
  return convertedTime

}

export default GetTimeFormatted