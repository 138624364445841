import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import AuthContext from '../../context/AuthContext.js';
import DrawerContext from '../../context/DrawerContext.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function CreateNetworkDrawer(props) {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const [isZip, setIsZip] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [parentProduct, setParentProduct] = useState(-1);
  const [parentProducts, setParentProducts] = useState([]);
  const [showForm, setShowForm] = useState("");

  const [productTitle, setProductTitle] = useState("");
  const [productVariant, setProductVariant] = useState("");
  const [productPrice, setProductPrice] = useState(0.00);
  const [productDescription, setProductDescription] = useState("");

  const updateBaseProduct = (parent) => {
    setParentProduct(parent);
    if (parent == 0) {
      setShowForm("parent");
    } else if (parent == -1) {
      setShowForm("");
    } else {
      setShowForm("variant");
    }
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/products/byParent', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        setParentProducts(data.products);
        setIsLoading(false);
        // setNetworkPaymentFee(data.network[0].payment_fee);
        // if(data.network[0].payment_recurring != "none") {
        //   setNetworkPaymentRecurring(data.network[0].payment_recurring);
        // } else {
        //   setNetworkPaymentRecurring("once off");
        // }
        // setUserEmail(data.user[0].email);
        // getImg(data.network[0].cover);
        // getIntent();
        // // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });
  }, []);

  // const updateTranslation = (event) => {
  //   translation         = event.target.value;
  //   checkImportBtn()
  // }

  const checkBtn = () => {
    if (productVariant != "" && productPrice != "" && productDescription != "") {
      return false;
    } else {
      return true;
    }
  }

  const createProduct = (event) => {
    event.preventDefault();

    setDisabled(true);
    setIsLoading(true);

    let title;
    if (parentProduct == 0) {
      title = productTitle;
    } else {
      let product = parentProducts.find(function (obj) { return obj.id == parentProduct });
      if (product) {
        title = product.title;
      }
    }

    let jsonBody = JSON.stringify({
      parentId: parentProduct,
      title: title,
      variant: productVariant,
      price: productPrice,
      description: productDescription
    });

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/product', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        drawerCtx.closeDrawer();
        drawerCtx.setPageUpdate({ page: "products" });
      } else {
        setDisabled(false);
        setIsLoading(false);
      }
    });

  }

  // const onFileChangeCapture = (event) => {
  //   console.log(isZip);
  //   /*Selected files data can be collected here.*/
  //   console.log(event.target.files[0].type);
  //   if(isZip.isTrue && event.target.files[0].type != "application/x-zip-compressed") {
  //     alert("You did not upload a ZIP file... Please try again");
  //   } else if(isZip.isTrue && event.target.files[0].type == "application/x-zip-compressed") {
  //     console.log("Upload zip file");
  //   } else if(!isZip.isTrue && event.target.files[0].type != "application/xml") {
  //     console.log("You did not upload an XML file... Please try again");
  //   } else if(!isZip.isTrue && event.target.files[0].type == "application/xml") {
  //     console.log("You did not upload an XML file... Please try again");
  //   } else {
  //     alert("Something went wrong");
  //   }
  // };

  const renderForm = () => {
    switch (showForm) {
      case 'parent':
        return <>
          <div className="double-column-form">
            <TextField label="Title" value={productTitle} onChange={(e) => { setProductTitle(e.target.value) }} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br />
          <div className="double-column-form">
            <TextField label="Specification" value={productVariant} onChange={(e) => { setProductVariant(e.target.value) }} placeholder="Specification" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
            <TextField label="Price" value={productPrice} onChange={(e) => { setProductPrice(e.target.value) }} placeholder="Price" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br />
          <div className="double-column-form">
            <TextField multiline rows={4} label="Description" value={productDescription} onChange={(e) => { setProductDescription(e.target.value) }} placeholder="Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br />
        </>;
      case 'variant':
        return <>
          <div className="double-column-form">
            <TextField label="Specification" value={productVariant} onChange={(e) => { setProductVariant(e.target.value) }} placeholder="Specification" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
            <TextField label="Price" value={productPrice} onChange={(e) => { setProductPrice(e.target.value) }} placeholder="Price" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br />
          <div className="double-column-form">
            <TextField multiline rows={4} label="Description" value={productDescription} onChange={(e) => { setProductDescription(e.target.value) }} placeholder="Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
          <br />
        </>;
      default:
        return null;
    }
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Product {isLoading ? <CircularProgress size={25} /> : null}</h1>
        </header>

        {!isLoading ? <section>

          <div className="double-column-form">
            <FormControl fullWidth>
              {/* <InputLabel id='base-product'>Base Product</InputLabel> */}
              <TextField
                value={parentProduct}
                onChange={(e) => updateBaseProduct(e.target.value)}
                select // tell TextField to render select
                label="Base Product"
                variant="outlined"
              >
                {/* <Select labelId="base-product" label="Base Product" value={parentProduct} onChange={(e) => {updateFileType(e)}} placeholder="File Type" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required> */}
                <MenuItem value='-1'>Choose 'None' for base product</MenuItem>
                <MenuItem value='0'>None (New base product)</MenuItem>
                {parentProducts.map((data) => (
                  <MenuItem value={data.id} key={data.id}>{data.title} ({data.variant})</MenuItem>
                ))}
              </TextField>
              {/* </Select>  */}
            </FormControl>
          </div>
          <br />

          {renderForm()}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={checkBtn() || disabled}>
              Create Product
              <input type="file" onClick={(e) => createProduct(e)} hidden />
            </Button>
          </div>

        </section> : null}

      </div>
    </div>
  );

}
export default CreateNetworkDrawer;

