import react, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { Paper, Grid, TextField, CardContent, Button, CircularProgress } from '@mui/material';
import { Checkbox, Snackbar } from '@mui/material';

import AuthContext from '../../context/AuthContext';

import { Alert } from '@mui/material';
import TextInput from '../../common/TextInput';




const Login = (props) => {

  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Sorry, email or password incorrect");
  const [response, setResponse] = useState(null);

  const [loading, setLoading] = useState(false);



  async function submit(e) {

    e.preventDefault()

    setLoading(true);

    await authCtx.login(email, password, setResponse);

    setTimeout(() => {setLoading(false);}, 500);

    console.log(response);

  }

  function keyDown(e, textField) {

    if (textField == "password") {
      if (e.code == "Enter") {
        submit(e);
      }
    } else {
      if (e.code == "Enter") {
        document.getElementById("password").focus();
      }
    }
  }

  function changeEmail(event) {
    setEmail(event.target.value);
  }

  function changePassword(event) {
    setPassword(event.target.value);
  }

  const paperStyle = { padding: 20, width: 580, margin: "0 auto", backgroundColor: "transparent" }
  return (
    <div className="login-register-page">

      <div className="login">

        <Paper elevation={0} style={paperStyle}>
          <CardContent>
            {/* <div className='logo-div'><img src={Welcome} className='logo-image' alt='Logo'></img></div> */}
            <div className="form-heading">LOGIN</div>
            <div className="form-wrapper">
              <form>
                <TextInput label='Email' onChange={changeEmail} value={email} placeholder="Email Address" onKeyDown={(e) => keyDown(e, "email")} className='textbox' />
                <TextInput label='Password' onChange={changePassword} value={password} type="password" placeholder="Password" onKeyDown={(e) => keyDown(e, "password")} required className='textbox' />
                {/* <input onChange={changeEmail} value={email} placeholder="Email Address" onKeyDown={(e) => keyDown(e, "email")} className='textbox' ></input>
                <div style={{ height: "15px" }}></div>
                <input></input>
                <div style={{ height: "20px" }}></div> */}
                <button disabled={loading} className="btn-primary w-100 mtb-1rem" onClick={submit}>
                  {loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Login"}
                </button>
              </form>
            </div>
            <Snackbar open={!!response} autoHideDuration={3000} onClose={() => setResponse(null)}>
              <Alert severity="error">
                {response}
              </Alert>
            </Snackbar>


          </CardContent>
        </Paper>

      </div>
    </div>
  );
}
export default Login;









