import React, { useContext, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { CloseRounded } from '@mui/icons-material';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';



const OrderChangeAccountDetailsModal = (props) => {

  console.log(props);
  
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(props.modalDetails.customer.full_name);
  const [mobileNumber, setMobileNumber] = useState(props.modalDetails.customer.phone);
  const [email, setEmail] = useState(props.modalDetails.customer.email);

  const nameHandler = (e) => {
    setName(e.target.value);
  }

  const emailHandler = (e) => {
    setEmail(e.target.value);
  }

  const mobileNumberHandler = (e) => {
    setMobileNumber(e.target.value);
  }

  const saveUpdates = () => {

    setIsLoading(true);

    let { firstName, lastName } = splitFullName(name);

    let payload = {
      fullName: name,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: mobileNumber
    }

    fetch(process.env.REACT_APP_API_URI + '/v3/customer/' + props.modalDetails.customer.id + '/update', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        modalCtx.setPageUpdate({page: "ordersDrawer", updateType: "updateDetails"});
        modalCtx.closeModal();
        setTimeout(() => {modalCtx.setPageUpdate({page: "orders"});}, 500);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error("address request error");
      }
    }).catch(err => console.error("address request error", err));
  }

  const splitFullName = (fullName) => {
    // Split the full name by space
    const nameArray = fullName.split(' ');

    // Check if there are at least two parts (first name and last name)
    if (nameArray.length >= 2) {
      const firstName = nameArray[0];
      const lastName = nameArray.slice(1).join(' '); // Join the remaining parts as the last name
      return { firstName, lastName };
    } else {
      // If there's only one part, consider it as the first name
      return { firstName: fullName, lastName: '' };
    }
  }

  return (
    <div className="account-details">

      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 data-aos="fade-right" data-aos-delay="500">Edit Account Details</h1>
        <CloseRounded onClick={(e) => modalCtx.closeModal()} />
      </header>

      <div className='account-details-container'>

        <div className='detail'>
          <label for='name' >Name</label>
          <input onChange={nameHandler} value={name} type="text" placeholder='Name' name='name'></input>
        </div>

        <div className='detail'>
          <label for='mobile-number' >Mobile Number</label>
          <input onChange={mobileNumberHandler} value={mobileNumber} type='number' placeholder='Mobile Number' name='mobile-number'></input>
        </div>


        <div className='detail'>
          <label for='email'>Email</label>
          <input onChange={emailHandler} value={email} type='email' placeholder='Email' name='email'></input>
        </div>

        <div className="save-changes-btn">
          <Button variant="outlined" component="label" disabled={false} onClick={() => { }} fullWidth style={{ border: '1px Solid #AA7B59', color: '#AA7B59' }}>Cancel</Button>
          {
            isLoading ? <Button variant="contained" component="label" disabled={true} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}><CircularProgress size={10} style={{color: "#fff"}} /></Button> :
            <Button variant="contained" component="label" disabled={false} onClick={() => saveUpdates()} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}>Save</Button>
          }
        </div>

      </div>

    </div>
  )
}

export default OrderChangeAccountDetailsModal;