import React, { useContext, useState } from "react";
import IngredientTable from "./components/IngredientTable";
import {HiOutlinePlus} from 'react-icons/hi'
import ModalContext from '../../context/ModalContext'
import { set } from "lodash";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export default function RecipesIngredients() {
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const handleModal = (modal, details) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, details);
  };
  
  const { recipeId } = useParams();

  
  const [ingredientsData, setIngredientsData] = useState([]);

  const refreshIngredientsData = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/recipe/ingredients", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredIngredients = data.data.filter((ingredient) => ingredient.recipeId == recipeId);
        console.log(filteredIngredients)
        setIngredientsData(filteredIngredients);
      })
      .catch((error) => {
        console.error("Error fetching updated data:", error);
      });
  };

  return (
    <div className="recipe-ingredients-main-container">
      <div className="recipe-ingredient-heading-container">
        <h1 className="recipe-ingredient-heading">Recipes - Ingredients</h1>
        <button style={{ cursor :'pointer' }}
        onClick={()=>{
          handleModal('add-ingredient', {refreshIngredientsData: refreshIngredientsData})
        }}
        > <HiOutlinePlus/> ADD NEW</button>
      </div>
      <div className="recipe-ingredients-table">
        <IngredientTable refreshIngredientsData={refreshIngredientsData} ingredientsData={ingredientsData} setIngredientsData={setIngredientsData} recipeId={recipeId} />
      </div>
    </div>
  );
}
