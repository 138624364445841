import React, { useState, useContext } from 'react';

//context
import AuthContext from '../../context/AuthContext'
import DrawerContext from '../../context/DrawerContext';

import ImagePlaceHolder from '../../assets/img/Recipes/upload-img.png'

//component
import TextInput from '../../common/TextInput'

import { CircularProgress } from '@mui/material'
import ModalContext from '../../context/ModalContext';

function CreateNewAuthor({drawerDetails}) {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  const [loading, setLoading] = useState(false)
  const [authorObj, setAuthorObj] = useState({firstName: '', lastName: '', email: '', avatar: ''});

  const openImageModal = () => {
    modalCtx.setDetails("upload-fixed-image", {fillAvatarValue, origin:'author' })
    modalCtx.openModal();
  }

  const fillAvatarValue = (imageURL) => {
    setAuthorObj((prev) => ({...prev, 'avatar': imageURL}));
  } 

  const createAuthor = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/author', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify(authorObj)
    }).then((res) => res.json())
    .then(data => {
      console.log(data);
      if (data.status === "success") {
        drawerDetails.getAllAuthors();
        drawerCtx.closeDrawer();
      } else {
        console.error("Error while creating author...");
      }
    }).catch((err) => {
      console.error("Error while creating author...", err);
    });
  }

    return <div className='container'>
      <div className='drawer'>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Author</h1>
        </header>
        <section style={{ padding: '1rem' }}>
          <TextInput name="firstName" label="first Name" value={authorObj?.firstName} onChange={(e) => setAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="First Name" />
          <TextInput name="lastName" label="last Name" value={authorObj?.lastName} onChange={(e) => setAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Last Name" />
          <TextInput name="email" label="email" value={authorObj?.email} onChange={(e) => setAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Email" />
          <div className='upload-image-container'>
            <img src={ authorObj.avatar == '' ? ImagePlaceHolder : authorObj.avatar} onClick={() => openImageModal()} alt="" />
          </div>
          <div className="double-column-form aligned-right margin-top">
            <button disabled={!(authorObj?.email.length && authorObj?.firstName.length)} className='btn-primary' onClick={(e) => createAuthor()}>{loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : "Save Changes"}</button>
          </div>
        </section>
      </div>
    </div>
}

export default CreateNewAuthor;