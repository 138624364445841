import React, { useContext, useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';

import DrawerContext from '../../context/DrawerContext';
import AuthContext from '../../context/AuthContext';

import deleteButton from '../../assets/img/blog/delete.svg';

import TextInput from '../../common/TextInput';


const Customers = () => {

  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchText, setSearchText] = useState('');

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  useEffect(() => {

    getCustomers()

  }, []);

  const getCustomers = async () => {

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/customers', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setUsersData(data.data);
      });

  }


  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  const columns = [

    {
      field: 'id', headerName: 'ID', width: 100, headerAlign: "center", align: "center",
    },
    { field: 'fullName', headerName: 'Customer Name', flex: 1, },
    {
      field: 'phone', headerName: 'Mobile Number', flex: 1,
    },
    {
      field: 'email', headerName: 'Email', flex: 1,
    },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='btn-container'>
        <button className='btn-primary' onClick={() => toggleDrawerHandler('user-details', params.row)}>View More</button>
        <img className='delete-btn'
          src={deleteButton} alt='' />

      </div>
    },

  ];


  return (
    <div className="customer-page">
      <div className='template-header'>
        <h1>Customers</h1>
        <div className="header-actions">
          <button className='btn-primary' >Delete</button>
          <TextInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>

      <div className='template-section'>
        <DataGrid
          sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
          rows={usersData}
          columns={columns}
          checkboxSelection
          selectionModel={selectedRows}
          disableSelectionOnClick={true}
          onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        />
      </div>
    </div>
  );

}
export default Customers;