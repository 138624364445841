import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

//assets
import Logo from '../../assets/img/sidebar/logo.png';
import Overview from "../../assets/img/sidebar/overview.svg";
import Blogs from "../../assets/img/sidebar/blogs.svg";
import Customers from "../../assets/img/sidebar/customers.svg";
import Orders from "../../assets/img/sidebar/orders.svg";
import Retailers from "../../assets/img/sidebar/retailers.svg";
import Products from "../../assets/img/sidebar/products.svg";
import Featured from '../../assets/img/sidebar/featured.svg'
import Logout from "../../assets/img/sidebar/logout.svg";
import Recipes from "../../assets/img/sidebar/recipes.svg"
import Marketing from '../../assets/img/sidebar/marketing.svg'
import Automation from '../../assets/img/sidebar/automation.svg'
import Promotion from '../../assets/img/sidebar/promotion.svg'
import Content from '../../assets/img/sidebar/content.svg'
import Topics from '../../assets/img/sidebar/topics.svg'
import Tags from '../../assets/img/sidebar/Tags.svg'
import Authors from '../../assets/img/sidebar/authors.svg'
import Coupons from '../../assets/img/sidebar/coupons.svg'

//context
import AuthContext from '../../context/AuthContext';
import { LocalOfferOutlined } from '@mui/icons-material';

const Sidebar = ({ setSideBarOpen }) => {

  const noSideBarRoutes = ["/login", "/register", "/print-orders", "/print-invoices"];
  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const isLoggedIn = authCtx.isLoggedIn;

  const [marketingOpen, setMarketingOpen] = useState(false);
  const [contentOpen, setContentOpen] = useState(false)
  useEffect(() => {
    if (pathname.includes('automation') || pathname.includes('promotion')) { setMarketingOpen(true) && setContentOpen(false) }
    if (pathname.includes('blogs') || pathname.includes('recipes')) { setContentOpen(true) && setMarketingOpen(false) }
  }, []);

  useEffect(() => {
    if (noSideBarRoutes.some((item) => pathname.includes(item))) {
      setSideBarOpen(false)
    } else {
      setSideBarOpen(true)
    }
  }, [])


  if (noSideBarRoutes.some((item) => pathname.includes(item))) return;


    const handleMarketingMenuClick = (e) => {
    e.preventDefault()
    setMarketingOpen(!marketingOpen)
  }
  
  const handleContentMenuClick = (e) => {
    e.preventDefault(); 
    setContentOpen(!contentOpen)
  }


  return (
    <aside className='sidebar'>
      <div className="logo"><img alt='sidebar' src={Logo} /></div>
      <nav>
        {!isLoggedIn && (<Link to="/login"><img alt='sidebar' src={Logout} />Login</Link>)}
        {isLoggedIn && (<Link to="/overview"><img alt='sidebar' src={Overview} />Overview</Link>)}
        <div className={marketingOpen ? `marketing-active` : ''}>
          {isLoggedIn && (<a href='' onClick={(e) => {handleMarketingMenuClick(e)}}><img alt='sidebar' src={Marketing} />Marketing {marketingOpen ? <MdOutlineKeyboardArrowUp size={20} /> : <MdOutlineKeyboardArrowDown size={20} />}</a>)}
          {isLoggedIn && marketingOpen && (<Link to="/automation"><img alt='sidebar' src={Automation} />Automation</Link>)}
          { isLoggedIn && marketingOpen && (<Link to="/promotion"><img alt='sidebar' src={Promotion} />Promotion</Link>)}
          { isLoggedIn && marketingOpen && (<Link to="/coupons"><img alt='sidebar' src={Coupons} />Coupons</Link>)}
        </div>
        {isLoggedIn && (<Link to="/customers"><img alt='sidebar' src={Customers} />Customers</Link>)}
        {isLoggedIn && (<Link to="/orders"><img alt='sidebar' src={Orders} />Orders</Link>)}
        <div className={contentOpen ? `content-active` : ''}>
          {isLoggedIn && (<a href='' onClick={(e) => {handleContentMenuClick(e)}}><img alt='sidebar' src={Content} />Content {contentOpen ? <MdOutlineKeyboardArrowUp size={20} /> : <MdOutlineKeyboardArrowDown size={20} />}</a>)}
        {isLoggedIn && contentOpen && (<Link to="/blogs"><img alt='sidebar' src={Blogs} />Blogs</Link>)}
        {isLoggedIn && contentOpen && (<Link to="/recipes"><img alt='sidebar' src={Recipes} />Recipes</Link>)}
        {isLoggedIn && contentOpen && (<Link to="/topics"><img alt='sidebar' src={Topics} />Topics</Link>)}
        {isLoggedIn && contentOpen && (<Link to="/authors"><img alt='sidebar' src={Authors} />Authors</Link>)}
        {isLoggedIn && contentOpen && (<Link to="/featured"><img alt='sidebar' src={Featured} />Featured</Link>)}
        {isLoggedIn && contentOpen && (<Link to="/tags"><img alt='sidebar' src={Tags} />Tags</Link>)}
        </div>
        {isLoggedIn && (<Link to="/products"><img alt='sidebar' src={Products} />Products</Link>)}
        {isLoggedIn &&  (<Link to="/retailers"><img alt='sidebar' src={Retailers} />Retailers</Link>)}
        {isLoggedIn && (<a onClick={() => authCtx.logout()}><img alt='sidebar' src={Logout} />Logout</a>)}
      </nav>
    </aside>
  )
}

export default Sidebar
