import { useContext, useEffect, useRef, useState } from "react";
import PrintOrdersContext from '../../context/PrintOrdersContext'
import AuthContext from "../../context/AuthContext";
import _ from "lodash";
const PrintInvoice = () => {

  const sizeRef = useRef()

  const printCtx = useContext(PrintOrdersContext)
  const authCtx = useContext(AuthContext)

  const [printOrders, setPrintOrders] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])

  // useEffect(() => {
  //   setPrintOrders(printCtx.details)
  // }, [printCtx])

  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    const ids = query.get('ids')?.split(',')
    for (let id in ids) {
      setSelectedOrders(prev => [...prev, { id: +ids[id], type: 'customer' }])
    }
  }, [])
  // console.log(selectedOrders)

  useEffect(() => {
    postDataForPrint()
  }, [selectedOrders])

  const resizeOrderElement = () => {
    var allElements = document.querySelectorAll('.order');
    allElements.forEach((Ele) => {
      if (Ele.scrollHeight > 519) {
        Ele.style.minHeight = "1045px";
      }
    });
  }


  const postDataForPrint = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/admin/order/forPrint', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ orderIds: selectedOrders })
      });
      const data = await response.json();

      if (data.status == 'success') {
        setPrintOrders(data.data)
      }

    } catch (e) {
      console.error(e);
    }
  }

  // console.log(selectedOrders.split(','))

  const getTableForOrders = (data) => {
    if (!data.length) return;

    const grouped = _.groupBy(data, "productId");
    const groupedOrders = Object.keys(grouped).map((key) => grouped[key])

    return groupedOrders?.map((item, i) => {
      return <div className="table-container" key={i}>
        <div className="product-quantity">{item.length}</div>
        <table>
          {
            item?.map((val, key) => {
              return (
                <tr key={key}>
                  <td style={{ width: '45px', borderRight: '1px solid black' }} ></td>
                  <td style={{ textAlign: 'start' }} >{val.title} - {val.variant}</td>
                  <td style={{ textAlign: 'end' }}>{val.price}</td>
                </tr>
              )
            })
          }
        </table>
      </div>
    })
  }

  // console.log('test')
  return (
    <>
      <div className="orders-printing-page">
        {
          printOrders?.length > 0 &&
          printOrders?.map((order, index) => {
            resizeOrderElement()
            return <div className="order" key={index}>
              <div className="address-section">
                <div className="customer-info">
                  <div className="customer-name-section">
                    {order.address && <p className="customer-name">{order.customer?.fullName || order.address.contactName}&nbsp;</p>}
                  </div>
                  {order.address && <div className='customer-address'>
                    <p>{order.address.addressLine1 || ""}</p>
                    <p>{order.address.addressLine2 || ""}</p>
                    <p>{order.address.pinCode}, {order.address.state}</p>
                  </div>}
                  <div className='customer-contact-info'>
                    <p>Contact: {order.customer?.phone}</p>
                  </div>
                </div>

                <div className="order-no-date">
                  <h3>Order No: {order.id}</h3>
                  <span><strong>Date: </strong> {order.date}</span>
                </div>
              </div>

              <div className="order-items-table">
                {getTableForOrders(order.orderItems)}
              </div>

              <div className="total">Total: {order.total}</div>
            </div>
          })
        }
      </div>
    </>
  )
}

export default PrintInvoice;