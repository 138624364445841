import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import DrawerContext from "../../context/DrawerContext";
import TopicsTable from "./components/TopicsTable";

export default function Topics() {

  const drawerCtx = useContext(DrawerContext)
  const authCtx = useContext(AuthContext);

  function toggleDrawerHandler(drawer) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer);
  };


  return (
    <>
      <div className="recipes-page">
        <header className="template-header">
          <h1 data-aos="fade-right" data-aos-delay="500">Topics</h1>
          <div>
            <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-topics', null) }}>+ Create New</button>
          </div>
        </header>

        <section className='template-section' >
        <TopicsTable />
        </section>
      </div>
    </>
  );
}
