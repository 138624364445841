import React, { useContext, useState, useEffect } from 'react';
import { Alert, Checkbox, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import DrawerContext from '../../../context/DrawerContext';
import editButton from '../../../assets/img/blog/edit.svg';
import deleteButton from '../../../assets/img/blog/delete.svg';

const OrderChangeAddressModal = ({ modalDetails }) => {

  const customerDetails = modalDetails.customer;
  const orderDetails = modalDetails.order;

  console.log("modalDetails", orderDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [active, setActive] = useState('all');
  const [addressAction, setAddressAction] = useState('add');
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [address, setAddress] = useState([]);
  const [order, setOrder] = useState(modalDetails.order);
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState('');
  const [checkedAddresses, setCheckedAddresses] = useState(orderDetails.addressId);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [searchCustomer, setSearchTag] = useState('')
  const [addTagTitle, setAddTagTitle] = useState('')

  const [showCustomerResults, setShowCustomerResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)


  //effect
  useEffect(() => {

    getAllAddress()

  }, [])

  // get all address
  const getAllAddress = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/customer/${customerDetails.id}/addresses`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log("this is respone", res)
        setAddress(res?.addresses);
        setLoading(false);

      }
    } catch (err) {
      console.log(err);
    }
  }


  const openModalHander = (data) => {
    setActive('add');
  }

  console.log(selectedTags)


  //add address codes
  const [payload, setPayload] = useState({
    customerId: customerDetails.id,
    address: {
      contactName: customerDetails.full_name,
      company: "",
      addressLine1: "",
      addressLine2: "",
      zipCode: "",
      city: "",
      state: ""
    }
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPayload(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value
      }
    }));
  };

  // get all customers
  const addAddress = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v2/customer/address', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          customerId: payload.customerId,
          address: payload.address
        })



      })
    } catch (err) {
      console.log(err);
    }
    setActive('all');
    getAllAddress();
    setPayload({
      customerId: customerDetails.id,
      address: {
        contactName: customerDetails.full_name,
        company: "",
        addressLine1: "",
        addressLine2: "",
        zipCode: "",
        city: "",
        state: ""
      }
    });
  }

  const editAddress = async (id) => {

    fetch(process.env.REACT_APP_API_URI + `/v2/customer/address/${id}`, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        address: payload.address
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setActive('all');
        getAllAddress();
        setPayload({
          customerId: customerDetails.id,
          address: {
            contactName: customerDetails.full_name,
            company: "",
            addressLine1: "",
            addressLine2: "",
            zipCode: "",
            city: "",
            state: ""
          }
        });
      } else {
        // setOpenSnackbar(true);
        console.error("address request error");
      }
    }).catch(err => console.error("address request error", err));

  }



  const openDeleteAddressModal = async (id) => {

  }

  const saveAddress = async () => {

    console.log(checkedAddresses);

    let payload = {
      addressId: checkedAddresses
    }

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v2/orders/' + order.id + '/updateAddress', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        modalCtx.setPageUpdate({page: "ordersDrawer", updateType: "updateDetails"});
        modalCtx.closeModal();
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
        console.error("address request error");
      }
    }).catch(err => console.error("address request error", err));

  }




  const handleCheckboxChange = (id) => {
    if (checkedAddresses == id) {
      setCheckedAddresses(0);
    } else {
      setCheckedAddresses(id);
    }
  };

  if (active == "all") {
    return (
      <div className="order-address">
        <div className="edit-tags-container">
          <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 data-aos="fade-right" data-aos-delay="500">Change Address</h1>
            <CloseRounded onClick={(e) => modalCtx.closeModal()} />
          </header>

          <section>

            <div className='address-card-container'>
              {loading ? <CircularProgress /> : <>
                {console.log("this is address", address)}

                {
                  address?.map((item) => (
                    console.log(item),
                    <div className='card'>
                      <div className='address-card'>
                        <Checkbox onClick={() => handleCheckboxChange(item.id)} checked={checkedAddresses == item.id} />
                        <div>
                          {item.address_line1}<br />
                          {item.address_line2}<br />
                          {item.city}, {item.pin_code}<br />
                          {item.state}
                        </div>
                      </div>
                      <div className='edit-icon'>
                        <img className='back-button action-icon' onClick={() => {
                          setActive('add');
                          setAddressAction('edit');
                          setPayload(
                            {
                              address: {
                                contactName: item.full_name,
                                company: item.company,
                                addressLine1: item.address_line1,
                                addressLine2: item.address_line2,
                                zipCode: item.pin_code,
                                city: item.city,
                                state: item.state
                              }
                            }
                          );
                          setSelectedAddress(item.id);
                        }} src={editButton} alt='' />
                        <img className='back-button action-icon' onClick={() => openDeleteAddressModal(item.id)} src={deleteButton} alt='' />
                      </div>
                    </div>
                  ))
                }
              </>}


              <div className='add-address' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => openModalHander(customerDetails)}>+ Add Address</div>
            </div>

            <div className="save-changes-btn">
              {
                isLoading ? <Button variant="contained" component="label" disabled={true} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}><CircularProgress size={10} style={{color: "#fff"}} /></Button> :
                <Button variant="contained" component="label" disabled={false} onClick={(e) => saveAddress()} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}>Save Changes</Button>
              }
            </div>
          </section>
        </div>
      </div>
    )

  } else {
    return (
      <>
        <div className="edit-tags-container">
          <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 data-aos="fade-right" data-aos-delay="500">Add Address</h1>
            <CloseRounded onClick={() => {
              setActive('all')
              getAllAddress()
            }} />
          </header>

          <section>


            <div className='address-textfield-container'>

              <div className='single-textfield'>
                <span>Address</span>
                <input className='address' placeholder='Address' fullWidth name="addressLine1" value={payload.address.addressLine1} onChange={handleInputChange} />
                <input className='address' placeholder='Address' fullWidth name="addressLine2" value={payload.address.addressLine2} onChange={handleInputChange} />
              </div>

              <div className='double-textfield'>
                <div className='single-textfield'>
                  <span>City</span>
                  <input placeholder='ex: Bangalore' name="city" value={payload.address.city} onChange={handleInputChange}></input>
                </div>
                <div className='single-textfield'>
                  <span>Pincode</span>
                  <input placeholder='ex: 560004' name="zipCode" value={payload.address.zipCode} onChange={handleInputChange}></input>
                </div>
              </div>

              <div className='double-textfield'>
                <div className='single-textfield'>
                  <span>State</span>
                  <input placeholder='ex: Karnataka' name="state" value={payload.address.state} onChange={handleInputChange}></input>
                </div>
                <div className='single-textfield'>
                  <span>Country</span>
                  <input placeholder='ex: India' name="country" value={payload.address.country} onChange={handleInputChange}></input>
                </div>
              </div>
              

            </div>

            <div className="save-changes-btn">
              <Button variant="outlined" component="label" disabled={false}
                onClick={() => setActive('all')} fullWidth style={{ border: '1px Solid #AA7B59', color: '#AA7B59' }}>Cancel
              </Button>
              <Button variant="contained" component="label" disabled={false}
                onClick={() => {
                  if (addressAction == "add") {
                    addAddress()
                  } else {
                    editAddress(selectedAddress);
                  }
                }} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}>Save
              </Button>
            </div>
          </section>
        </div>
      </>
    )
  }


}

export default OrderChangeAddressModal;
