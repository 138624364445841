import React, { useState, useContext, useEffect } from 'react'
import DrawerContext from '../../context/DrawerContext';
import AuthContext from '../../context/AuthContext';
import { CircularProgress } from '@mui/material'

import Profile from '../../assets/img/profile.svg';
import editButton from '../../assets/img/blog/edit.svg';
import deleteButton from '../../assets/img/blog/delete.svg';
import ModalContext from '../../context/ModalContext';


function Authors() {

    const drawerCtx = useContext(DrawerContext);
		const authCtx = useContext(AuthContext);
		const modalCtx = useContext(ModalContext);

		const [loadingStatus, setLoadingStatus] = useState(false);
		const [authors, setAuthors] = useState([]);

    function openCreateAuthorDrawer() {
      drawerCtx.openDrawer();
      drawerCtx.setDetails('create-author', {getAllAuthors});
    };

		const openDeleteAuthorModal = (id) => {
			modalCtx.setDetails('confirm-delete-author', {id, getAllAuthors});
			modalCtx.openModal();
		}

		const openEditAuthorDrawer = (author) => {
			drawerCtx.setDetails('edit-author', {author, getAllAuthors});
			drawerCtx.openDrawer();
		}

		useEffect(() => {
			getAllAuthors();
		}, [])

		const getAllAuthors = () => {
			setLoadingStatus(true);
			fetch(process.env.REACT_APP_API_URI + '/v3/authors', {
				method: 'GET',
				headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
			})
			.then(res => res.json())
			.then(data => {
				setLoadingStatus(false);
				setAuthors([...data.data]);
				console.log(data.data)})
			.catch((e) => console.log(e.message));
		}

  return (
		<>
			<div className='authors-page'>
				<header className='template-header'>
					<h1 data-aos="fade-right" data-aos-delay="500">Authors</h1>
					<div>
						<button className='btn-primary' data-aos='fade-right' data-aos-delay="400" onClick={() => { openCreateAuthorDrawer() }}>Create New</button>
					</div>
				</header>
				{loadingStatus ?
					<CircularProgress />
					: <div className='template-body'>
						<div className='authors'>
							{authors.map((author, index) => {
								return (
									<div className='author' key={index}>
										<div className='profile-img'>
											<img src={author.avatar == "" ?  Profile : author.avatar } alt="" />
										</div>
										<div className='details'>
											<h3>{author.firstName + " " + author.lastName}</h3>
											<p>{author.email}</p>
										</div>
										<div className='button'>
											<img className='btn'
												onClick={() => openEditAuthorDrawer(author)}
												src={editButton} alt='' />
											<img className='btn'
												onClick={() => openDeleteAuthorModal(author.id)}
												src={deleteButton} alt='' />
										</div>
									</div>
								)
							})}
						</div>
					</div>}
				
			</div>
		</>
  )
}

export default Authors