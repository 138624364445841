import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { RxDragHandleDots2 } from 'react-icons/rx'
import { MdDelete } from 'react-icons/md'
import { BiEdit } from "react-icons/bi";

import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import ModalContext from "../../../context/ModalContext";

import uploadImage from "../../../assets/img/Recipes/upload-img.png";

const IngredientTable = ({refreshIngredientsData, ingredientsData, setIngredientsData, recipeId}) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [isvisible, setisvisible] = useState(false);
  const [isEdit, setIsEdit] = useState();
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [newIngredientText, setNewIngredientText] = useState("");
  const [updatedIngredientText, setUpdatedIngredientText] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [ingredientOrder, setIngredientOrder] = useState([]);
  
  useEffect(() => {
    // Fetch ingredients data from the API endpoint
    setLoadingStatus(true)
    fetch(process.env.REACT_APP_API_URI + "/v3/recipe/ingredients", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      // Filter ingredients with a non-null recipeId
      const filteredIngredients = data.data.filter((ingredient) => ingredient.recipeId == recipeId);
      // console.log(filteredIngredients)
      // Filter ingredients for the specific recipeId
      filteredIngredients.sort((a, b) => a.orderNo - b.orderNo);  
      setIngredientsData(filteredIngredients);
      setLoadingStatus(false)
    })
    .catch((error) => {
      setLoadingStatus(false)
      console.error("Error fetching data:", error);
    });

    drawerCtx.setRecipeId(recipeId);
  }, [recipeId]);
    
  const handleModal = (modal, details) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, details);
  };

  const toggleEdit = (data) => {
    const id = data?.id;
    isvisible ? setisvisible(false) : setisvisible(true);
    setIsEdit(id);
    
    setUpdatedIngredientText(data?.ingredient)
  };
  
  // useEffect(() => {
    //   console.log("isVisible:", isvisible);
    //   console.log("ingredientsData:", ingredientsData);
    // }, [isvisible, isEdit]);

  const handleCheckboxChange = (id) => {
    setSelectedIngredients((prevState) => {
      const updatedSelectedIngredients = prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];
      return updatedSelectedIngredients;
    });
  };

  const handleAddIngredient = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/recipe/ingredient", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        recipeId: recipeId,
        ingredient: newIngredientText,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setNewIngredientText("");
        refreshIngredientsData();
      })
      .catch((error) => {
        console.error("Error adding new ingredient:", error);
      });
  };



  const handleUpdateIngredient = (data) => {
    setSaveLoading(true);
    fetch(
      process.env.REACT_APP_API_URI + `/v3/admin/recipe/ingredient/${data?.id}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify({
          recipeId: recipeId,
          ingredient: updatedIngredientText,
        }),
      }
    )
      .then(() => {
        setUpdatedIngredientText("");
        refreshIngredientsData();
        setSaveLoading(false);
        setisvisible(false);
      })
      .catch((error) => {
        console.error("Error updating ingredient:", error);
      });

  };

  const deleteIngredient = (ingredientId) => {
    // Send a DELETE request to the API to delete the ingredient
    setLoadingStatus(true)
    fetch(
      process.env.REACT_APP_API_URI + `/v3/admin/recipe/ingredient/${ingredientId}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      }
    )
      .then(() => {
        // After successful deletion, you can update the ingredients data if needed
        // Example: refreshIngredientsData();
        setLoadingStatus(false)
      })
      .catch((error) => {
        setLoadingStatus(false)
        console.error("Error deleting ingredient:", error);
      });

    refreshIngredientsData();
  };

  const handleIngredientsOrderUpdate = (reorderedIngredientsList) => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/recipe/ingredient/order/update', {
      method:'PUT',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({'ingredientOrder': reorderedIngredientsList})
    }).then((res) => {
      if (!res.ok) {
        throw new Error(
          `Network response was not ok (Status: ${res.status})`
        );
      }
      return res.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error("Error:", error.message);
    });
  }
 
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedIngredients = Array.from(ingredientsData);
    const [reorderedIngredient] = reorderedIngredients.splice(result.source.index, 1);
    reorderedIngredients.splice(result.destination.index, 0, reorderedIngredient);

    setIngredientsData(reorderedIngredients);
    var newOrder = [];
    for (let index = 0; index < reorderedIngredients.length; index++) {
      const element = reorderedIngredients[index];
      newOrder.push({'ingredientId':element['id'], 'orderIndex': index})
    }
    handleIngredientsOrderUpdate(newOrder);
    // Update the recipeOrder state with the new order
    const newIngredientOrder = reorderedIngredients.map((ingredient) => ingredient.id);
    setIngredientOrder(newIngredientOrder);
    console.log(newIngredientOrder)
  };
  

  return (
    <>
      {/* <input
        type="text"
        placeholder="Add a new ingredient..."
        value={newIngredientText}
        onChange={handleNewIngredientTextChange}
      />
      <button onClick={handleAddIngredient}>Add</button> */}
      {
        ingredientsData.length != 0 ? <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <table className="recipe-ingredients-table">
              <thead>
                <tr>
                  <th className="drag-icon" ></th>
                  <th>Order</th>
                  <th>Ingredients</th>
                </tr>
              </thead>
              <Droppable droppableId="ingredient-list">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {ingredientsData.map((data,index) => (
                    <Draggable key={index} draggableId={`ingredient-${index}`} index={index}>
                    {(provided) => (
                      <tr className="ingredient-single-row" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <td className="drag-icon" colSpan={'1'}>
                          <RxDragHandleDots2/>
                        </td>
                        <td>{index+1}</td>
                        <td className="ingredient-data">
                          <h4>
                            {isEdit === data.id && isvisible ? (
                              <div className="recipe-edit-ip-btn">
                                <input type="text" value={updatedIngredientText} onChange={(event) => setUpdatedIngredientText(event.target.value)} />
                                <button onClick={() => handleUpdateIngredient(data)}>{ saveLoading ? <CircularProgress style={{ 'color': '#ffffff' }} size={15} /> : 'Save' }</button>
                               </div>
                            ) : (
                              data.ingredient
                            )}
                          </h4>
                          <div className="recipe-ingredient-edit-icon">
                            <BiEdit onClick={() => handleModal('edit-ingredient', {refreshIngredientsData: refreshIngredientsData, ingredient: data})} />
                          </div>
                        </td>
                        <td className="delete-ingredient">
                          <div className="del-ingredient-icon" onClick={()=>{deleteIngredient(data.id)}}>
                            <MdDelete/>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                </tbody>
              )}
              </Droppable>
            </table>
          </DragDropContext>
        </> : <> 
          {
            loadingStatus ? <><CircularProgress size={22}/></> : <>
              <h1>No data in ingredients table</h1>
            </>
          }
        </>
      }
    </>
  );
};

export default IngredientTable;