import React, { useState } from 'react'

import deleteButton from '../../assets/img/blog/delete.svg';

import TextInput from '../../common/TextInput'
import { DataGrid } from '@mui/x-data-grid';

function Automation() {

  const [searchText, setSearchText] = useState('');

  const columns = [

    {
      field: 'title', headerName: 'Automation Title', flex: 1
    },
    { field: 'description', headerName: 'Description', flex: 1, },
    {
      field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => <div className={`status bg-${params.row.status.toLowerCase()}`}>{params.row.status}</div>
    },
    {
      field: 'type', headerName: 'Type', flex: 1,
    },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='btn-container'>
        <img className='delete-btn'
          src={deleteButton} alt='' />
      </div>
    },

  ];

    const data = [
    {
      id: 101,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
    },
    {
      id: 102,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
    },
    {
      id: 103,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
    },
    {
      id: 104,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
    },
    {
      id: 105,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
    },
    {
      id: 106,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
    },
    {
      id: 107,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
    },
    {
      id: 108,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
    },
    {
      id: 109,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
    },
    {
      id: 110,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
    },
    {
      id: 111,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
    },
    {
      id: 112,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
    },
    {
      id: 113,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
    },
    {
      id: 114,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
    },
    {
      id: 115,
      title: 'Automation Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
    },
  ]
  
  return (
    <div className="automation-page">

      <header className="template-header" >
        <h1 data-aos="fade-right" data-aos-delay="500">Automation</h1>
        <div>
          {/* <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-overview', null) }}>Create New</button> */}
        </div>
        <div className="header-actions">
          <button className='btn-primary' >Delete</button>
          <TextInput value={searchText} placeholder='Seacrh' onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </header>
      <div className='template-section'>
        <DataGrid
          sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
          rows={data}
          columns={columns}
        />
      </div>
    </div>
  )
}

export default Automation