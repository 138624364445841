import React from 'react'
import { useState, useEffect, useContext } from "react";
import '../../../Style/Modal.scss'

import AuthContext from "../../../context/AuthContext";
import ModalContext from "../../../context/ModalContext";
import DrawerContext from '../../../context/DrawerContext';

//assets
import { Button, CircularProgress, drawerClasses } from "@mui/material";
import { GridCloseIcon } from '@mui/x-data-grid';


const AddFeaturedBlog = () => {
  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)
  const drawerCtx = useContext(DrawerContext)

  const [blogs, setBlogs] = useState([])
  const [blogPage, setBlogPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [currentBlogId, setCurrentBlogId] = useState(null)

  useEffect(() => {
    getAllBlogs()
  }, []);

  useEffect(() => {
    getAllBlogs()
  }, [drawerCtx, modalCtx]);


  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getAllBlogs = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URI}/v3/blogs/get/list`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          console.log("blogs ", data)
          setBlogs(data?.data.reverse())

        }
      }).catch(err => {
        console.error(err)
      });

  }


  const postFeaturedBlog = (id) => {
    setLoading(true)
    setCurrentBlogId(id)
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/featured`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "entityId": id,
        "entityType": "blog"
      })
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          console.log("blogs ", data.data)
          setLoading(false)
          modalCtx.closeModal()
        }
      }).catch(err => {
        console.error(err)
      });

  }



  return (
    <div className='add-featured-blog-container'>
      {
        !blogs?.length
          ? <div className="no-blogs">No Blogs</div>
          : <>
            <div className="blog-header">
              <h3>Select Blog</h3>
              <div className="close-btn" onClick={() => modalCtx.closeModal()}><GridCloseIcon style={{
                cursor: 'pointer',
              }} /></div>
            </div>
            {
              blogs.map((blog, index) => (
                <div className="featured-blogs" key={index}>
                  <div className="featured-blog-title">
                    {blog.title}
                  </div>
                  <button className='add-btn' onClick={() => postFeaturedBlog(blog.id)}>
                    {
                      loading && currentBlogId == blog.id
                        ? <CircularProgress style={{ color: "#AA7B59" }} size={17} />
                        : "ADD"
                    }
                  </button>
                </div>
              ))
            }
          </>
      }
    </div>
  )
}

export default AddFeaturedBlog