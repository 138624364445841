import React, { createContext, useState } from 'react';

const ModalContext = createContext({
  open: false,
  modal: 'none',
  details: {},
  pageUpdate: {},
  fixedSize: false,
  maxHeight: null,
  setMaxHeight: () => {},
  maxWidth: null,
  setMaxWidth: () => {},
  minWidth: null,
  setMinWidth: () => {},
  minHeight: null,
  setMinHeight: () => {},
  newIngredientText: '',
  setNewIngredientText: () => {},
  recipeId: null, // New variable for recipeId
  setRecipeId: () => {}, // New method to set recipeId
  setDetails: () => {},
  setPageUpdate: () => {},
  openModal: () => {},
  closeModal: () => {},
  setFixedSize: () => {}
});

export function ModalContextProvider(props) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('none');
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});
  const [fixedSize, setFixedSize] = useState(false);
  const [maxHeight, setMaxHeight] = useState(null);
  const [maxWidth, setMaxWidth] = useState(null);
  const [minWidth, setMinWidth] = useState(null);
  const [minHeight, setMinHeight] = useState(null);
  const [newIngredientText, setNewIngredientText] = useState('');
  const [recipeId, setRecipeId] = useState(null);
  

  function openModalHandler() {
    setOpen(true);
  }

  function closeModalHandler() {
    setFixedSize(false);
    setOpen(false);
  }

  function setDetailsHandler(modal, data) {
    setModal(modal);
    setDetails(data);
  }

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  function setFixedSizeHandler(data) {
    setFixedSize(data);
  }

  const context = {
    open,
    modal,
    details,
    pageUpdate,
    fixedSize,
    maxHeight,
    setMaxHeight,
    maxWidth,
    setMaxWidth,
    minWidth,
    setMinWidth,
    minHeight,
    setMinHeight,
    newIngredientText,
    setNewIngredientText,
    recipeId,
    setRecipeId,
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    openModal: openModalHandler,
    closeModal: closeModalHandler,
    setFixedSize: setFixedSizeHandler
  };

  return (
    <ModalContext.Provider value={context}>
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
