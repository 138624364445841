import { SearchOutlined } from '@mui/icons-material';
import { Button, Input, InputAdornment, Menu, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'

import deleteButton from '../../assets/img/blog/delete.svg';
import emailPreview from '../../assets/img/promotions/email-preview.png'
import textPreview from '../../assets/img/promotions/text-preview.png'
import whatsappPreview from '../../assets/img/promotions/whatsapp-preview.png'

import TextInput from '../../common/TextInput'
import { DataGrid } from '@mui/x-data-grid';
import ModalContext from '../../context/ModalContext';
import AuthContext from '../../context/AuthContext';

function Promotion() {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');


  //Fliter Menu Functions
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const open = Boolean(anchorEl);

  const open2 = Boolean(anchorElFilter);
  const [filterValue, setFilterValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const [mainTab, setMainTab] = useState(1);
  const [secondaryTab, setSecondaryTab] = useState(0);
  const [promotionsTab, setPromotionsTab] = useState(1);
  const [promotionData, setPromotionData] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [customerFilter, setCustomerFilters] = useState("");
  const [typeFilter, setTypeFilter] = useState("Email");
  const [previewImage, setPreviewImage] = useState(emailPreview);

  const data = [
    {
      id: 101,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
      customers: 101,
    },
    {
      id: 102,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
      customers: 102,
    },
    {
      id: 103,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
      customers: 103,
    },
    {
      id: 104,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
      customers: 104,
    },
    {
      id: 105,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
      customers: 105,
    },
    {
      id: 106,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
      customers: 106,
    },
    {
      id: 107,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
      customers: 107,
    },
    {
      id: 108,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
      customers: 108,
    },
    {
      id: 109,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
      customers: 109,
    },
    {
      id: 110,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
      customers: 110,
    },
    {
      id: 111,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "Email",
      customers: 111,
    },
    {
      id: 112,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Text Message",
      customers: 112,
    },
    {
      id: 113,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Draft",
      type: "Text Message",
      customers: 113,
    },
    {
      id: 114,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Inactive",
      type: "WhatsApp",
      customers: 114,
    },
    {
      id: 115,
      title: 'Title',
      description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      status: "Active",
      type: "Email",
      customers: 115,
    },
  ]

  const columns = [

    {
      field: 'title', headerName: 'Title', flex: 1
    },
    { field: 'description', headerName: 'Description', flex: 1, },
    {
      field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => <div className={`status bg-${params.row.status.toLowerCase()}`}>{params.row.status}</div>
    },
    {
      field: 'type', headerName: 'Type', flex: 1,
    },
    {
      field: 'customers', headerName: 'No. of Customers'
    },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='btn-container'>
        <button className='btn-primary' onClick={() => promote(params.row)} >Promote</button>
        <img className='delete-btn'
          src={deleteButton} alt='' />
      </div>
    },
  ];

  const customer_columns = [

    {
      field: 'id', headerName: 'ID', width: 100, headerAlign: "center", align: "center",
    },
    { field: 'fullName', headerName: 'Customer Name', flex: 1, },
    {
      field: 'phone', headerName: 'Mobile Number', flex: 1,
    },
    {
      field: 'email', headerName: 'Email', flex: 1,
    },
    {
      field: "buttons", headerName: '', flex: 1, renderCell: (params) => <div className='btn-container'>
        <img className='delete-btn'
          src={deleteButton} alt='' />
      </div>
    },

  ];

  useEffect(() => {

    getCustomers()

  }, []);

  useEffect(() => {
    if(typeFilter === 'Email'){
      setPreviewImage(emailPreview);
    }
    else if(typeFilter === 'Text Message'){
      setPreviewImage(textPreview);
    }
    else if(typeFilter === 'Whatsapp'){
      setPreviewImage(whatsappPreview);
    }
  }, [typeFilter])

  const getCustomers = async () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/customers', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        setUsersData(data.data);
      });

  }

  const promote = (data) => {
    setPromotionData(data);
    setMainTab(1);
  }

  const handleModal = (name) => {
    modalCtx.setDetails(name);
    modalCtx.openModal();
  }

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);
    
    setFilterValue(value)
    
  };
  const handlePrevButton = () => {
    setPromotionsTab(promotionsTab => promotionsTab - 1);
  }
  
  const handleNextButton = () => {
    setPromotionsTab(promotionsTab => promotionsTab + 1);
  }

  return (
    <div className="promotion-page">

      <header className="template-header" >
        <div className='heading-flex'>
          <h1 data-aos="fade-right" data-aos-delay="500" onClick={() => setMainTab(0)}>Promotion</h1>
          {(mainTab === 1) && <h1 data-aos="fade-right" data-aos-delay="500">{"> Title of Promotion"} </h1>}
        </div>
        <div>
          {/* <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-overview', null) }}>Create New</button> */}
        </div>
        <div className="header-actions">
          <button className='btn-primary btn-create' onClick={() => handleModal('create-new-promotions')} >Create Promotion</button>
          <button className='btn-primary' >Delete</button>
          <TextInput value={searchText} placeholder='Seacrh' onChange={(e) => setSearchText(e.target.value)} />
          <button className='btn-primary' onClick={() => setShowFilters(showFilters => !showFilters)} >Filter</button>
        </div>
        {showFilters && <div className="filters-box">
          <h4>Filters</h4>
          <div className="single-filter">
            <div>Customer who has ordered more than one product in 6 months</div>
            <input type="checkbox" name="filter" id="filter" />
          </div>
          <div className="single-filter">
            <div>Customer who has ordered one product in 6 months</div>
            <input type="checkbox" name="filter" id="filter" />
          </div>
          <div className="single-filter">
            <div>Customer who has ordered more than one product in 3 months</div>
            <input type="checkbox" name="filter" id="filter" />
          </div>
          <button className="btn-primary" onClick={() => setShowFilters(false)}>Apply</button>
        </div>}
      

      
      {/* <div className="header-items-div">

            <Button data-aos="fade-right" data-aos-delay="400" className='btn-primary'>Delete</Button>
            <Input
              className="search-input"
              onChange={(e)=>setSearchInput(e.target.value)}
              id="input-with-icon-adornment"
              placeholder="Search a emergency service"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
            />
           
            

            <div>
              <Button
                id="basic-button"
                className="btn-primary"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickFilter}
              >
                Filter
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElFilter}
                open={open2}
                onClose={()=>handleCloseFilter('')}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={()=>handleCloseFilter('MoreThanOneIn6Month')}>Customer who has ordered more than one product in 6 months</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('OnlyOneIn6Month')}>Customer who has ordered one product in 6 months</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('MoreThanOneIn3Month')}>Customer who has ordered more than one product in 3 months</MenuItem>
              </Menu>
            </div>
            
        </div> */}

        </header>
        
        {
          mainTab === 0 ? 
          <div className='template-section'>
            <DataGrid
              sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
              rows={data}
              columns={columns}
            />
          </div>
          :
          <div className="template-section single-promotion">
            <div className="tab-flex">
              <h3 className={`${secondaryTab === 0 ? 'active-tab' : ''}`} onClick={() => setSecondaryTab(0)}>Setup</h3>
              <h3 className={`${secondaryTab === 1 ? 'active-tab' : ''}`} onClick={() => setSecondaryTab(1)}>Customers</h3>
            </div>
            {
              secondaryTab === 0 ? 
              <div className='promotion-setup'>
                <h2>Setup</h2>
                <div className='promotion-tabs'>
                  <div className={`tab ${promotionsTab === 0 && 'active-tab'} ${promotionsTab > 0 && 'completed-tab'}`}>Select Customers
                    {promotionsTab > 0 && <>
                      <div className="arrow-lower"></div>
                      <div className="arrow-upper"></div>
                    </>}
                    {
                      promotionsTab === 0 && <div className='triangle'></div>
                    }
                  </div>
                  <div className={`tab ${promotionsTab === 1 && 'active-tab'} ${promotionsTab > 1 && 'completed-tab'}`}>Choose Type
                    {promotionsTab > 1 && <>
                      <div className="arrow-lower"></div>
                      <div className="arrow-upper"></div>
                    </>}
                    {
                      promotionsTab === 1 && <div className='triangle'></div>
                    }
                  </div>
                  <div className={`tab ${promotionsTab === 2 && 'completed-tab'}`}>Test Preview & Send</div>
                </div>
                {
                  (promotionsTab === 0) && 
                  <>
                    <div className='select-customers'>
                      <div className="left">
                        <div className="input-box">
                          <h4 className="label">Extract Your Customers</h4>
                          <Select value={customerFilter} onChange={(e) => setCustomerFilters(e.target.value)} displayEmpty inputProps={{ "aria-label": "Without label" }}>
                            <MenuItem value="">Extract Your Customers</MenuItem>
                            <MenuItem value='Customer who has ordered more than one product in 6 months'>Customer who has ordered more than one product in 6 months</MenuItem>
                            <MenuItem value='Customer who has ordered one product in 6 months'>Customer who has ordered one product in 6 months</MenuItem>
                            <MenuItem value='Customer who has ordered more than one product in 3 months'>Customer who has ordered more than one product in 3 months</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className="right">
                        <h4>{customerFilter === '' ? 'All Customers' : customerFilter}</h4>
                        <DataGrid
                          sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
                          rows={usersData}
                          columns={customer_columns}
                          checkboxSelection
                          selectionModel={selectedRows}
                          disableSelectionOnClick={true}
                          onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
                        />
                      </div>
                    </div>
                  </>
                }
                {
                  (promotionsTab === 1) && 
                  <div className='choose-type'>
                    <div className="left">
                      <div className="input-box">
                        <h4 className="label">Type</h4>
                        <Select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)} displayEmpty inputProps={{ "aria-label": "Without label" }}>
                          <MenuItem value='Email'>Email</MenuItem>
                          <MenuItem value='Text Message'>Text Message</MenuItem>
                          <MenuItem value='Whatsapp'>Whatsapp</MenuItem>
                        </Select>
                      </div>
                      <div className="content">
                        <h4 className="label">Edit Content</h4>
                        <textarea name="content" id="content" cols="30" rows="5" placeholder='Enter your Content Here...'></textarea>
                      </div>
                    </div>
                    <div className="right">
                      <h4>{typeFilter} Preview</h4>
                      <div className="image">
                        <img src={previewImage} />
                      </div>
                    </div>
                  </div>
                }
                {
                  (promotionsTab === 2) && 
                  <div className='test-preview'>
                    <img src={previewImage} />
                  </div>
                }
              </div> :
              <div className='customers-data'>
                <div className="customers-table">
                  <DataGrid
                    sx={{ '& .MuiDataGrid-cell:hover': { color: 'primary.main', }, }}
                    rows={usersData}
                    columns={customer_columns}
                    checkboxSelection
                    selectionModel={selectedRows}
                    disableSelectionOnClick={true}
                    onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
                  />
                </div>
                <div className="start-btn">Start Promotion</div>
              </div>
            }
            {
              secondaryTab === 0 &&
              <div className='button-flex'>
                {
                  promotionsTab === 0 ? <div></div> : <button className='btn-primary-border' onClick={() => handlePrevButton()}>Prev</button>
                }
                {
                  promotionsTab === 2 ? <button className='btn-primary'>Complete Setup</button> : <button className='btn-primary' onClick={() => handleNextButton()}>Next</button>
                }
              </div>
            }
          </div>
        }
    </div>
  )
}

export default Promotion