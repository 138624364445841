import React, { useContext, useState } from "react";
import { HiOutlinePlus } from 'react-icons/hi';
import ModalContext from '../../context/ModalContext';
import MethodTable from "./components/methodTable";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export default function RecipesMethods() {
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  };
  
  const { recipeId } = useParams();
  
  const [methodsData, setMethodsData] = useState([]);

  const refreshMethodsData = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/recipe/methods", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMethodsData(data.data);
      })
      .catch((error) => {
        console.error("Error fetching updated data:", error);
      });
  };


  return (
    <div className="recipe-methods-main-container">
      <div className="recipe-method-heading-container">
        <h1 className="recipe-method-heading">Recipes - Methods</h1>
        <button style={{ cursor: 'pointer' }}
          onClick={() => {
            handleModal('add-method', {refreshMethodsData: refreshMethodsData});
          }}
        > <HiOutlinePlus /> ADD NEW</button>
      </div>
      <div className="recipe-methods-table">
        <MethodTable refreshMethodsData={refreshMethodsData} methodsData={methodsData} setMethodsData={setMethodsData} recipeId={recipeId}/>
      </div>
    </div>
  );
}
