import React, { useContext, useState, useEffect } from "react";
import { GrClose } from 'react-icons/gr';
import { BiCircle } from 'react-icons/bi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useParams } from "react-router-dom";
import ModalContext from "../../../context/ModalContext.js";
import AuthContext from "../../../context/AuthContext.js";
import DrawerContext from "../../../context/DrawerContext.js";
import { CircularProgress } from "@mui/material";

const AddMethod = () => {
//   const optionsData = [
//     "Lemon Ginger Cooler",
//     "Peach Cooler",
//     "Strawberry Cooler",
//     "Malta Cooler",
//     "Apricot Cooler",
//     "Plum Cooler",
//     "Spiced Buransh Cooler",
//     "Wild Blackberry Preserve",
//     "Chunky Strawberry Preserve",
//     "Malta Orange Marmalade",
//     "Spiced Peach Preserve",
//     "Pear & Cinnamon Preserve",
//     "Fiery Sweet Chilli Sauce",
//     "Tomato Basil Sauce With Garlic & Chia",
//     "Plum Spiced Chutney",
//     "Ginger Pear Spiced Chutney",
//     "Peppermint Ginger & Citrus Infusion",
//     "Full - Bloom Chamomile Flowers",
//     "Lemongrass Ginger Turmeric Infusion",
//     "Nettle Rosemary & Peppermint Infusion",
//     "Lemongrass & Chamomile Infusion",
//     "Peppermint & Lemongrass Infusion",
//     "Citrus, Chamomile & Tejpatta Infusion",
//     "Italian Herbed Seasoning Salt",
//     "Rosemary Garlic Seasoning Salt",
//     "Lemon Chilli Seasoning Salt",
//     "Roasted Garlic Chilli Seasoning Salt",
//     "Raw Apple Cider Vinegar (with mother)",
//     "Whole Leaf Himalayan Tejpatta",
//     "Himalayan Rosemary",
//     "Whole Leaf Himalayan Peppermint"
//   ];

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { recipeId } = useParams();
  const [newMethodText, setnewMethodText] = useState("");
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSaveChanges = () => {
    setLoading(true);
    handleAddMethod();
  };

  const handleClose = () => {
    modalCtx.closeModal();
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setnewMethodText(selectedOption?.value);
  };

  const handleIpChange = (e) => {
    setnewMethodText(e.target.value);
  }

  const handleAddMethod = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/recipe/method", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        recipeId: drawerCtx.recipeId,
        method: newMethodText,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setnewMethodText("");
        modalCtx.details.refreshMethodsData();
        modalCtx.closeModal();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding new ingredient:", error);
      });
  };

  useEffect(()=>{
    console.log('recipeId',drawerCtx.recipeId)
    console.log('newMethodText',newMethodText)
  },[newMethodText,drawerCtx.recipeId])

  return (
    <div className="enter-ingredient-modal">
      <div className="enter-ingredient-heading">
        <h3>Add New Method</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-ingredient-input-container">
        <h5>Method</h5>
        <input type="text" placeholder="Method" onChange={handleIpChange} />
      </div>
      {/* <div className="link-hh-product">
        <h5>Link Himalayan Haat Product</h5>
        <Select
          className="select-tag"
          options={optionsData.map((optionText, index) => ({
            value: optionText,
            label: (
              <div className="option-container">
                {optionText}
                <div className="select-icon">
                  {selectedOption === optionText ? (
                    <AiFillCheckCircle />
                  ) : (
                    <BiCircle />
                  )}
                </div>
              </div>
            ),
          }))}
          value={selectedOption}
          onChange={handleOptionChange}
        />
      </div> */}
      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-primary" onClick={() => !loading && handleSaveChanges()}>
          {
            loading ? 
            <CircularProgress style={{'color': '#ffffff'}} size={22}/> :
          'Add'
          }
        </button>
      </div>
    </div>
  );
};

export default AddMethod;
