import React, { useState } from 'react'
import { useContext } from 'react';
import DrawerContext from '../../../context/DrawerContext';
import { GridCloseIcon } from '@mui/x-data-grid';
import '../Topics/TopicsDrawer.scss'
import AuthContext from '../../../context/AuthContext';

const TagDrawer = () => {

  const authCtx = useContext(AuthContext);


  const drawerCtx = useContext(DrawerContext);
  const [tagName, setTagName] = useState()



  const handleTagNameChange = (e) => {
    setTagName(e.target.value);
  };

  const handleSaveChanges = () => {
    //Handelling save changes button here
    sendTopicRequest();
    drawerCtx.closeDrawer();
  }
  //post request to send the topics in database

  const sendTopicRequest = () => {
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/tag`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + authCtx.token
      },
      body: JSON.stringify(
        {
          title: tagName,
        }
      ),

    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data)
      })
  }



  return (
    <div className="edit-topics-container">
      <div className="drawer topics-drawer-container">
        <div className="topics-drawer">
          <div className="topics-drawer-header">
            <header>
              <h1>Create Tag Drawer</h1>
            </header>
          </div>
          <div className="topics-input-box-container">
            <div className="sub-topics-input-box-container">
              <div className="form-label">Tag</div>
              <input
                type="text"
                placeholder="Tag"
                className="input-box"
                value={tagName}
                onChange={handleTagNameChange}
              />
            </div>
            <div className="btn-container">
              <button className="save-btn" onClick={handleSaveChanges} >Create Tag</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TagDrawer