import React, { useContext, useState, useEffect } from 'react'

//mui
import { GrClose } from 'react-icons/gr';

//Context
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';

const EditTopicsModal = () => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [topicData, setTopicData] = useState(null)
  const [topicTitle, setTopicTitle] = useState()

  useEffect(() => {
    setTopicTitle(modalCtx.details.title);
  }, [modalCtx]);

  
  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();
  }

  console.log(modalCtx)
  

  const handleClose = () => {
    modalCtx.closeModal();
  };

  const handleSaveChanges = () => {
    const payload = {
      title: topicTitle,
    }
    
    console.log(topicTitle)

  fetch(
    `https://apiv2.himalayanhaat.org/v3/admin/topic/${modalCtx.details.id}`,
    {
      method: "PUT" ,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify(payload),
    }
  )
    .then((response) => {
      if (response.ok) {
        setOpenSnackbar(true);
        console.log(response)
      } else {
        throw new Error("Failed to update recipe data");
      }
    })
    .catch((error) => {
      console.error("Error updating recipe data:", error);
      setOpenSnackbar(true);
    });

  fetchTopics(modalCtx.topicId)      
  modalCtx.closeModal();
};

  const fetchTopics = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/topics', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data)
        setTopicData(data.data)
        setTopicTitle(data.title)

      })
      .catch((error) => {
        console.error("Error fetching topic data:", error);
      });
  };
  return (
    <div className="enter-time-modal">
      <div className="enter-time-heading">
        <h3>Edit Topic</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-time-input-container">
        {
        <input
          type="text"
          placeholder="edit topic"
          value={topicTitle}
          onChange={(e) => setTopicTitle(e.target.value)}
        />
        }
      </div>
      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose} >
          Cancel
        </button>
        <button className="btn-primary" onClick={handleSaveChanges} >
          Save
        </button>
      </div>
    </div>
  )
}

export default EditTopicsModal