import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, CircularProgress } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import DrawerContext from '../../../context/DrawerContext';

import customer from '../../../assets/img/orders/customer.svg'
import customerSelected from '../../../assets/img/orders/customer-selected.svg'
import retailer from '../../../assets/img/orders/retailer.svg'
import retailerSelected from '../../../assets/img/orders/retailer-selected.svg'
import amazon from '../../../assets/img/orders/amazon.svg'
import amazonSelected from '../../../assets/img/orders/amazon-selected.svg'
import whatsapp from '../../../assets/img/orders/whatsapp.svg'
import whatsappSelected from '../../../assets/img/orders/whatsapp-selected.svg'
import instagram from '../../../assets/img/orders/instagram.svg'
import instagramSelected from '../../../assets/img/orders/instagram-selected.svg'
import website from '../../../assets/img/orders/website.svg'
import websiteSelected from '../../../assets/img/orders/website-selected.svg'
import email from '../../../assets/img/orders/email.svg'
import emailSelected from '../../../assets/img/orders/email-selected.svg'
import call from '../../../assets/img/orders/call.svg'
import callSelected from '../../../assets/img/orders/call-selected.svg'

const OrderAddModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [customers, setCustomers] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [retailersAddresses, setRetailersAddresses] = useState([]);
  const [retailersContact, setRetailersContact] = useState([]);
  const [selectedTags, setSelectedTags] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [searchCustomer, setSearchTag] = useState('')
  const [addTagTitle, setAddTagTitle] = useState('');
  const [selectedRetailer, setSelectedRetailer] = useState('');
  const [searchRetailer, setSearchRetailer] = useState('');

  const [showCustomerResults, setShowCustomerResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const [orderType, setOrderType] = useState("Customer");
  const [orderSource, setOrderSource] = useState("amazon");


  //effect
  useEffect(() => {
    getAllCustomers();
    getAllRetailers();
  }, [])

  //I had to do this effect because the state doesn't mutate immediately
  //   useEffect(() => {
  //     addNewlyCreatedTag(addTagTitle)
  //   }, [customers, addTagTitle])

  //change visibility and get all customers when searchCustomer is changed
  useEffect(() => {
    if (searchCustomer.length > 0) {
      setShowCustomerResults(true)
    } else {
      setShowCustomerResults(false)
    }
    // getAllCustomers()
  }, [searchCustomer])


  // submit tag to db
  const submitEditTag = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${modalDetails.id}/customers`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          tagId: id,
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(data)
          getAllCustomers()
        } else {
          console.error("Error while editing devotional...");
        }
      }).catch((err) => console.error("Error while editing devotional...", err));
    }

  }

  // get all customers
  const getAllCustomers = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/customers`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setShowSpinner(false)
        setCustomers(res?.data)
        setAddTagTitle(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  // get all retailers
  const getAllRetailers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/retailers`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setShowSpinner(false)
        setRetailers(res?.data)
        // try {
        //   const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/retailers-contacts`,
        //     {
        //       method: 'GET',
        //       headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        //     })
    
        //   const res = await response.json();
        //   if (res.status == 'success') {
        //     console.log(res)
        //     setRetailersAddresses(res?.details)
        //     try {
        //       const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/retailers-contacts`,
        //         {
        //           method: 'GET',
        //           headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        //         })
        
        //       const res = await response.json();
        //       if (res.status == 'success') {
        //         console.log(res)
        //         setRetailersContact(res?.details)
        //       }
        //     } catch (err) {
        //       console.log(err);
        //     }
        //   }
        // } catch (err) {
        //   console.log(err);
        // }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const submitCreateCustomer = async (customerName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/customer`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            customerName
          })
        })

      const res = await response.json();
      if (res.status == 'success') {
        getAllCustomers(customerName)
      }
    } catch (err) {
      console.log(err);
    }
  }


  //check if tag exist
  const handleCreateNewCustomer = (title) => {
    setShowSpinner(true)
    if (customers.some(tag => tag.fullName == title)) {
      setOpenSnackbar(true)
      return;
    } else {
      submitCreateCustomer(title)
    }
  }

  //add selected tag to db function and array
  const handleSelectTag = () => {
    if(orderType == "Retailer") {

      let retailer = selectedRetailer;
      console.log(retailer);
      if(retailer !== '') {
        fetch(process.env.REACT_APP_API_URI + `/v3/admin/retailer/order`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            retailerId: retailer.id,
            source: orderSource
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          if (data.status == "success") {
            let order = {
              id: data?.order.id,
              date: data?.order.orderedAt || "",
              customer: retailer.retailer,
              total: 0,
              order: data?.order,
              cartTotal: 0,
              shippingTotal: 0,
              total: 0
            }
            console.log(order);
            toggleDrawerHandler('order-details', { orderId: data.order.id, retailerId: retailer.id, orderType: "retailer" });
            modalCtx.setPageUpdate({page: "orders"});
            modalCtx.closeModal();
    
          } else {
            console.error("Error while creating an order");
          }
        }).catch((err) => console.error("Error while creating an order", err));
      }

    } else {

      let customer = selectedCustomer;
      console.log(customer);
      if(customer !== ''){
        fetch(process.env.REACT_APP_API_URI + `/v3/admin/customer/order`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            customerId: customer.id,
            source: orderSource
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          if (data.status == "success") {
            let order = {
              id: data?.order.id,
              date: data?.order.orderedAt || "",
              customer: customer?.fullName || customer?.firstName,
              total: 0,
              order: data?.order,
              cartTotal: 0,
              shippingTotal: 0,
              total: 0
            }
            console.log(order);
            toggleDrawerHandler('order-details', { orderId: data.order.id, customerId: customer.id, orderType: "customer" });
            modalCtx.setPageUpdate({page: "orders"});
            modalCtx.closeModal();
    
          } else {
            console.error("Error while creating an order");
          }
        }).catch((err) => console.error("Error while creating an order", err));
      }

    }
    
  }

  //filter and delete tag from db and state
  // const handleFilterArray = (id) => {

  //   setSelectedCustomer('')
  // }

  //set search tag string
  const handleSearchTag = (e) => {
    setSelectedCustomer('');
    setSearchTag(e.target.value)
  }

  const handleSearchRetailer = (e) => {
    setSelectedRetailer('');
    setSearchRetailer(e.target.value);
  }

  //handling tag visibility
  const handleShowTagResult = () => {
    if (showCustomerResults) {
      setShowCustomerResults(false)
    } else {
      setShowCustomerResults(true)
    }
  }

  function toggleDrawerHandler(drawer, details) {
    drawerCtx.setDetails(drawer, details);
    drawerCtx.openDrawer();
  };

  console.log(selectedTags)

  return (
    <>
      <div className="add-orders-container">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Already exist.
          </Alert>
        </Snackbar>
        <header>
          <h2 data-aos="fade-right" data-aos-delay="500">Add Orders</h2>
        </header>

        <section>

          <div className="order-type">
            <div className="image-box" onClick={() => setOrderType("Customer")}>
              <img src={orderType==="Customer" ? customerSelected : customer} className={`${orderType==="Customer" ? 'active-img' : ''}`} alt="" />
              <h3 className={`${orderType==="Customer" ? 'active-h3' : ''}`}>Customer</h3>
            </div>
            <h3>OR</h3>
            <div className="image-box" onClick={() => setOrderType("Retailer")}>
              <img src={orderType==="Retailer" ? retailerSelected : retailer} className={`${orderType==="Retailer" ? 'active-img' : ''}`} alt="" />
              <h3 className={`${orderType==="Retailer" ? 'active-h3' : ''}`}>Retailer</h3>
            </div>
          </div>

          { 
            orderType == "Retailer" ? <>
              <div className="search-tag-container">
                <input type="text" placeholder={`Search for Retailers...`} value={selectedRetailer !== '' ? selectedRetailer.retailer : searchRetailer} onChange={handleSearchRetailer} />
                <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowTagResult()} style={showCustomerResults ? { transform: 'rotateZ(180deg)' } : null} />
              </div>
              <div className="tags-menu" style={showCustomerResults ? null : { display: 'none' }} >
                <ul>
                  {
                    retailers?.filter((retailer) => retailer.retailer.toLowerCase().includes(searchRetailer.toLowerCase())).map((retailer, i) => {
                      return <li key={i} onClick={() => {
                        setSelectedRetailer(retailer);
                        setShowCustomerResults(false);
                      }}>
                        {retailer.retailer}
                        <p className=''>{retailer.phone === null ? "" : retailer.phone} | { retailer.contactName }</p>
                      </li>
                    })
                  }
                  {searchRetailer.length == 0 ? null : ( showSpinner == true ? <CircularProgress style={{justifyContent:"center"}} /> : <li className='add-new-tag' onClick={() => handleCreateNewCustomer(searchRetailer)}><Add /> &nbsp; Add new &nbsp; <span className='tag-text'>"{searchRetailer}"</span></li> )}
                </ul>
              </div>
            </> : <>
              <div className="search-tag-container">
                <input type="text" placeholder={`Search for Customers...`} value={selectedCustomer !== '' ? selectedCustomer.fullName : searchCustomer} onChange={handleSearchTag} />
                <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowTagResult()} style={showCustomerResults ? { transform: 'rotateZ(180deg)' } : null} />
              </div>
              <div className="tags-menu" style={showCustomerResults ? null : { display: 'none' }} >
                <ul>
                  {
                    customers?.filter((customer) => customer.fullName.toLowerCase().includes(searchCustomer.toLowerCase())).map((customer, i) => {
                      return <li key={i} onClick={() => {
                        setSelectedCustomer(customer);
                        setShowCustomerResults(false);
                      }}>
                        {customer.fullName}
                        <p className=''>{customer.email === null ? "" : customer.email} | { customer.phone }</p>
                      </li>
                    })
                  }
                  {searchCustomer.length == 0 ? null : ( showSpinner == true ? <CircularProgress style={{justifyContent:"center"}} /> : <li className='add-new-tag' onClick={() => handleCreateNewCustomer(searchCustomer)}><Add /> &nbsp; Add new &nbsp; <span className='tag-text'>"{searchCustomer}"</span></li> )}
                </ul>
              </div>
            </>
          }

          <div className="order-source">
            <h2>Sources</h2>
            <div className="soures-group">
              <div className="image-box" onClick={() => setOrderSource("amazon")}>
                <img src={orderSource==="amazon" ? amazonSelected : amazon} className={`${orderSource==="amazon" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="amazon" ? 'active-h4' : ''}`}>Amazon</h4>
              </div>
              <div className="image-box" onClick={() => setOrderSource("instagram")}>
                <img src={orderSource==="instagram" ? instagramSelected : instagram} className={`${orderSource==="instagram" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="instagram" ? 'active-h4' : ''}`}>Instagram</h4>
              </div>
              <div className="image-box" onClick={() => setOrderSource("whatsapp")}>
                <img src={orderSource==="whatsapp" ? whatsappSelected : whatsapp} className={`${orderSource==="whatsapp" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="whatsapp" ? 'active-h4' : ''}`}>WhatsApp</h4>
              </div>
              <div className="image-box" onClick={() => setOrderSource("website")}>
                <img src={orderSource==="website" ? websiteSelected : website} className={`${orderSource==="website" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="website" ? 'active-h4' : ''}`}>Website</h4>
              </div>
              <div className="image-box" onClick={() => setOrderSource("email")}>
                <img src={orderSource==="email" ? emailSelected : email} className={`${orderSource==="email" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="email" ? 'active-h4' : ''}`}>Email</h4>
              </div>
              <div className="image-box" onClick={() => setOrderSource("call")}>
                <img src={orderSource==="call" ? callSelected : call} className={`${orderSource==="call" ? 'active-img' : ''}`} alt="" />
                <h4 className={`${orderSource==="call" ? 'active-h4' : ''}`}>Direct Call</h4>
              </div>
            </div>
          </div>
          <div className="buttons-group">
            <button className="btn-primary-border" onClick={(e) => modalCtx.closeModal()} >Close</button>
            <button className="btn-primary" onClick={(e) => handleSelectTag()}>Create Order</button>
          </div>
          {/* <div className="save-changes-btn">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Save Changes
            </Button>
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div> */}
        </section>
      </div>
    </>
  )
}

export default OrderAddModal;
