import React, { useState, useEffect, useContext } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import ModalContext from "../../../context/ModalContext";
import { useParams } from "react-router-dom";
import { RxDragHandleDots2 } from "react-icons/rx";
import { CircularProgress } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const MethodTable = ({refreshMethodsData, methodsData, setMethodsData, recipeId}) => {
  const [isvisible, setisvisible] = useState(false);
  const [isEdit, setIsEdit] = useState();
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [newMethodText, setNewMethodText] = useState("");
  const [updatedMethodText, setUpdatedMethodText] = useState("");
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const [loadingStatus, setloadingStatus] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false);
  const [methodOrder, setMethodOrder] = useState([]);


  useEffect(() => {
    setloadingStatus(true);
    fetch(process.env.REACT_APP_API_URI + "/v3/recipe/methods", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloadingStatus(false);

        // Filter methods with a non-null recipeId
        const filteredMethods = data.data.filter(
          (method) => method.recipeId == recipeId
        );

        // Filter methods for the specific recipeId
        setMethodsData(filteredMethods);
      })
      .catch((error) => {
        setloadingStatus(false);
        console.error("Error fetching data:", error);
      });

    drawerCtx.setRecipeId(recipeId);
  }, [recipeId, authCtx.token,modalCtx]);

  useEffect(()=>{
    console.log('loadingStatus',loadingStatus)
  },[loadingStatus])

  const toggleEdit = (data) => {
    const id = data?.id;
    isvisible ? setisvisible(false) : setisvisible(true);
    setIsEdit(id);

    setUpdatedMethodText(data?.method);
  };

  useEffect(() => {
    console.log("isVisible:", isvisible);
    console.log("methodsData:", methodsData);
  }, [isvisible, isEdit]);

  const handleCheckboxChange = (id) => {
    setSelectedMethods((prevState) => {
      const updatedSelectedMethods = prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];
      return updatedSelectedMethods;
    });
  };
  const handleModal = (modal, details) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, details);
  };

  const handleAddMethod = () => {
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/recipe/method", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        recipeId: recipeId,
        method: newMethodText,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setNewMethodText("");
        refreshMethodsData();
      })
      .catch((error) => {
        console.error("Error adding new method:", error);
      });
  };
  


  const handleUpdateMethod = (methodId) => {
    setSaveLoading(true);
    fetch(
      process.env.REACT_APP_API_URI + `/v3/admin/recipe/method/${methodId?.id}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify({
          recipeId: recipeId,
          method: updatedMethodText,
        }),
      }
    )
      .then(() => {
        setUpdatedMethodText("");
        refreshMethodsData();
        setSaveLoading(false);
        setisvisible(false);
      })
      .catch((error) => {
        setloadingStatus(false);
        console.error("Error updating method:", error);
      });
  };

  const deleteMethod = (methodId) => {
    setloadingStatus(true);
    fetch(
      process.env.REACT_APP_API_URI + `/v3/admin/recipe/method/${methodId}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      }
    )
      .then(() => {
        // After successful deletion, update the methods data
        setloadingStatus(false);
        refreshMethodsData();
      })
      .catch((error) => {
        setloadingStatus(false);
        console.error("Error deleting method:", error);
      });

      refreshMethodsData();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedMethods = Array.from(methodsData);
    const [reorderedMethod] = reorderedMethods.splice(result.source.index, 1);
    reorderedMethods.splice(result.destination.index, 0, reorderedMethod);

    setMethodsData(reorderedMethods);

    // Update the recipeOrder state with the new order
    const newMethodOrder = reorderedMethods.map((method) => method.id);
    setMethodOrder(newMethodOrder);
  };

  return (
    <div>
      {
        methodsData.length!==0 ? <>
        
        <DragDropContext onDragEnd={handleDragEnd}>
              <table className="recipe-methods-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Methods</th>
                  </tr>
                </thead>
               <Droppable droppableId="method-list">
                {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {methodsData.map((data, index) => (
                    <Draggable key={index} draggableId={`method-${index}`} index={index}>
                    {(provided) => (
                     <tr 
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}>
                      <td colSpan="1" className="drag-icon">
                          <RxDragHandleDots2/>
                      </td>
                      <td>{index + 1}</td>
                      <td className="method-data">
                        <h4>
                          {isEdit === data.id && isvisible ? (
                            <div className="recipe-edit-ip-btn">
                              <input
                                type="text"
                                value={updatedMethodText}
                                onChange={(event) =>
                                  setUpdatedMethodText(event.target.value)
                                }
                              />
                              <button onClick={() => handleUpdateMethod(data)}>
                              {saveLoading ?
                              <CircularProgress style={{'color': '#ffffff'}} size={15}/> :
                              'Save'}
                              </button>
                            </div>
                          ) : (
                            data.method
                          )}
                        </h4>
                        <div className="recipe-method-edit-icon">
                          <BiEdit onClick={() => handleModal('edit-method', {refreshMethodsData: refreshMethodsData, method: data})} />
                        </div>
                      </td>
                      <td className="delete-method">
                        <div
                          className="del-method-icon"
                          onClick={() => deleteMethod(data.id)}
                        >
                          <MdDelete />
                        </div>
                      </td>
                    </tr>
                 )}
                 </Draggable>
                  ))}
                </tbody>
                )}
              </Droppable>
              </table>
        </DragDropContext>
        </> : <>
              {
                loadingStatus? <>
                  <CircularProgress size={22}/>
                </> :
                <>
                  <h1>No data in Methodtable</h1>
                </>
              }
        </>
      }
    </div>
  );
};

export default MethodTable;