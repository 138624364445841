import React, { useContext, useState, useEffect } from "react";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import ModalContext from "../../../context/ModalContext.js";
import { GrClose } from "react-icons/gr";
import { Add, CloseRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import AuthContext from "../../../context/AuthContext.js";

const EditPrepTime = () => {
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [prepTime, setprepTime] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [recipeData, setRecipeData] = useState(null);

  useEffect(() => {
    fetchRecipeData(modalCtx.recipeId);
  }, [modalCtx.recipeId]);

  useEffect(() => {
    setprepTime(recipeData?.prepTime);
    console.log('recipeData',recipeData)
  }, [recipeData]);

  const handleSaveChanges = () => {
    const requestData = {
      title: recipeData.title,
      prepTime: prepTime,
      totalTime: recipeData.totalTime,
      serves: recipeData.serves,
      image: recipeData.image,
    };

    fetch(
      `https://apiv2.himalayanhaat.org/v3/admin/recipe/${modalCtx.recipeId}`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        if (response.ok) {
          setOpenSnackbar(true);
        } else {
          throw new Error("Failed to update recipe data");
        }
      })
      .catch((error) => {
        console.error("Error updating recipe data:", error);
        setOpenSnackbar(true);
      });

    fetchRecipeData(modalCtx.recipeId)      
    modalCtx.closeModal();
  };

  const handleClose = () => {
    modalCtx.closeModal();
  };

  const fetchRecipeData = (recipeId) => {
    fetch(process.env.REACT_APP_API_URI + '/v3/recipes/'+recipeId,{
      method: 'GET',
      mode:'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        setRecipeData(data.data);
        setprepTime(data.prepTime);
        console.log(data.prepTime)
      })
      .catch((error) => {
        console.error("Error fetching recipe data:", error);
        setOpenSnackbar(true);
      });
  };

  return (
    <div className="enter-time-modal">
      <div className="enter-time-heading">
        <h3>Edit Prep Time</h3>
        <GrClose onClick={handleClose} />
      </div>
      <div className="enter-time-input-container">
        <h5>Total Time</h5>
        {
         <input
          type="text"
          placeholder="Prep Time"
          value={prepTime}
          onChange={(e) => setprepTime(e.target.value)}
        />
        }
      </div>
      <div className="save-changes-btn">
        <button className="btn-primary-border" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-primary" onClick={handleSaveChanges}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditPrepTime;