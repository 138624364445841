/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { AiOutlinePlus } from "react-icons/ai";
import uploadImage from "../../../assets/img/Recipes/upload-img.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { BiEdit } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import ModalContext from '../../../context/ModalContext';
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator, EditRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import AddTrailingDots from '../../../helper/addTrailingDots';

const RecipeTable = ({ recipe, index }) => {
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext)
  const [isDeleted, setIsDeleted] = useState(false)
  const [allRecipes, setAllRecipes] = useState();
  const [recipeOrder, setRecipeOrder] = useState([]);


  useEffect(() => {
    getData();
  }, [drawerCtx, isDeleted, modalCtx])

  const handleModal = (modal, id) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal);
    modalCtx.setRecipeId(id)
  };

  const handleDrawer = (drawer, id) => {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer);
  };

  const deleteRecipe = (id) => {
    const apiUrl = process.env.REACT_APP_API_URI + "/v3/admin/recipe/" + id;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authCtx.token,
    };

    fetch(apiUrl, {
      method: "DELETE",
      mode: "cors",
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `Network response was not ok (Status: ${res.status})`
          );
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const getData = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/recipes ', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllRecipes(data.data.sort((a, b) => a.orderNo - b.orderNo));
      })
  }

  const handleRecipesOrderUpdate = (reorderedRecipesList) => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/recipe/order/update', {
      method: 'PUT',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({ 'recipeOrder': reorderedRecipesList })
    }).then((res) => {
      if (!res.ok) {
        throw new Error(
          `Network response was not ok (Status: ${res.status})`
        );
      }
      return res.json();
    })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }

  // Function to handle drag and drop
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedRecipes = Array.from(allRecipes);
    const [reorderedRecipe] = reorderedRecipes.splice(result.source.index, 1);
    reorderedRecipes.splice(result.destination.index, 0, reorderedRecipe);

    setAllRecipes(reorderedRecipes);

    var newOrder = [];
    for (let index = 0; index < reorderedRecipes.length; index++) {
      const element = reorderedRecipes[index];
      newOrder.push({ 'recipeId': element['id'], 'orderIndex': index })
    }
    handleRecipesOrderUpdate(newOrder);

    // Update the recipeOrder state with the new order
    const newRecipeOrder = reorderedRecipes.map((recipe) => recipe.id);
    setRecipeOrder(newRecipeOrder);
  };


  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 0.65, origin: 'recipe', id: id });
    modalCtx.openModal();
  }

  function convertMinutesToTime(minutes) {
    if (isNaN(minutes) || minutes < 0) {
      return "Invalid input";
    }

    const days = Math.floor(minutes / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const remainingMinutes = minutes % 60;

    let result = "";

    if (days > 0) {
      result += `${days} d `;
    }

    if (hours > 0) {
      result += `${hours} h `;
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes} min`;
    }

    return result.trim();
  }

  return (
    <>
      {
        allRecipes ? <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <table className="recipe-table">
              <thead>
                <tr>
                  <th>Recipe Image</th>
                  <th>Recipe Name</th>
                  <th>Ingredients</th>
                  <th>Method</th>
                  <th>Total Time</th>
                  {/* <th>Prep Time</th> */}
                  <th>Serves</th>
                  <th></th>
                </tr>
              </thead>
              <Droppable droppableId="recipe-list">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {allRecipes?.map((el, index) => (
                      <Draggable key={index} draggableId={`recipe-${index}`} index={index}>
                        {(provided) => (
                          <>
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="ingredient-row"
                            >
                              <td className='recipe-image-container' style={{ cursor: "pointer" }}>
                                {
                                  el?.image ? <>
                                    <img
                                      className="recipe-img"
                                      src={el.image}
                                      alt={`Recipe ${index + 1}`}
                                      onClick={() => { openUploadImageModal(el.id) }}
                                    />
                                  </>
                                    :
                                    <>
                                      <img
                                        className="recipe-img"
                                        src={uploadImage}
                                        alt={`Recipe ${index + 1}`}
                                        onClick={() => { openUploadImageModal(el.id) }}
                                      />
                                    </>
                                }
                              </td>
                              <td className="recipe-name">
                                <h5>{el?.title}</h5>
                                <p> { AddTrailingDots(el.description, 150)} </p>
                              </td>
                              <td>
                                <NavLink to={`recipesIngredients/${el.id}`}>
                                  <button className="ingredient-btn">
                                    <AiOutlinePlus /> Ingredients
                                    <div className='ingredients-count'>{el['ingredients'].length}</div>
                                  </button>
                                </NavLink>
                              </td>
                              <td>
                                <NavLink to={`recipesMethod/${el.id}`}>
                                  <button className="method-btn" onClick={() => {
                                    drawerCtx.setRecipeId(el.id)
                                  }}>
                                    <AiOutlinePlus /> Method
                                    <div className='ingredients-count'>{el['methods'].length}</div>
                                  </button>
                                </NavLink>
                              </td>
                              <td>
                                <button>
                                  {convertMinutesToTime(el?.totalTime)}
                                  <BiEdit
                                    onClick={() => {
                                      handleModal("edit-total-time", el.id);
                                    }}
                                  />
                                </button>
                              </td>
                              {/* <td>
                                <button>
                                  {el?.prepTime} h
                                  <BiEdit
                                    onClick={() => {
                                      handleModal("edit-prep-time", el.id);
                                    }}
                                  />
                                </button>
                              </td> */}
                              <td>
                                <button>
                                  {el?.serves}
                                  <BiEdit
                                    onClick={() => {
                                      handleModal("edit-serves", el.id);
                                    }}
                                  />
                                </button>
                              </td>
                              <td className='action-btns'>
                                <EditRounded onClick={() => handleDrawer('edit-recipe', el)} style={{marginRight: '10px'}} />
                                <DeleteIcon onClick={() => {
                                  deleteRecipe(el?.id)
                                  isDeleted ? setIsDeleted(false) : setIsDeleted(true)
                                }} />
                              </td>
                            </tr>
                            <tr className="space-row"></tr>
                          </>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </>
          :
          <>
            <CircularProgress size={30} />
          </>
      }
    </>
  );
};

export default RecipeTable;
