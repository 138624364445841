import React, { useContext } from 'react';

import DrawerContext from '../../context/DrawerContext';

import ProductsGrid from "./components/ProductsGrid";


const Products = () => {

  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  return (
    <>
      <div className="products-page">

        <header className="template-header" >
          <h1 data-aos="fade-right" data-aos-delay="500">Products</h1>
          <div>
            <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-product', null) }}>Create</button>
          </div>
        </header>

        <section className='template-section' >
          <ProductsGrid />
        </section>

      </div>
    </>
  );

}
export default Products;