import React, { useContext, useState, useEffect } from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from "@mui/material/Chip";
import AuthContext from '../../../context/AuthContext.js';
import DrawerContext from '../../../context/DrawerContext.js';

import NoImage from '../../../assets/img/no-image.gif';
import { Button } from '@mui/material';

import DeleteBtn from "../../../assets/img/delete.svg";
import EditBtn from "../../../assets/img/edit.svg";

const ProductsGrid = () => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [products, setProducts] = useState([]);

  const breakpoints = {
    xs: 0,
    sm: 800,
    md: 1200,
    lg: 1400,
    xl: 1620
  }

  const getColumns = (width) => {
    if (width < breakpoints.sm) {
      return 1
    } else if (width < breakpoints.md) {
      return 3
    } else if (width < breakpoints.lg) {
      return 4
    } else if (width < breakpoints.xl) {
      return 5
    } else {
      return 6
    }
  }

  const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
  }

  const getProducts = () => {

    fetch(process.env.REACT_APP_API_URI + '/v3/products', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        setProducts(data.data);
        setIsLoading(false);
      } else {
      }
    });

  }

  if (drawerCtx.pageUpdate.page == "products") {
    drawerCtx.setPageUpdate({});
    getProducts();
  };

  const uploadImage = (id) => {
    const currentProduct = products?.find((prod) => prod.id === id);
    console.log("currentProduct", currentProduct);
    drawerCtx.openDrawer();
    drawerCtx.setDetails("product-image-upload", currentProduct);
  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }


  return (
    <>
        <div className="products-list">
          {
            products?.length > 0 ? products?.map((item, index) => (
              <div className="product" key={index} style={{ borderTop: `8px solid ${item.productionStatus == 'production' && "#CD4A4A" || item.productionStatus == 'onhold' && '#636951' || item.productionStatus == 'discontinued' && ''}` }}>

                <div className="product-img">
                  {
                    item.image
                      ? <img className="hover-img" onClick={() => uploadImage(item.id)} src={item.image} srcSet={item.image} alt={item.title} loading="lazy" />
                      : <img className="hover-img" onClick={() => uploadImage(item.id)} src={NoImage} srcSet={NoImage} alt={item.title} loading="lazy" />
                  }
                  <div className="hover-div">
                    <button className="btn-white" onClick={() => uploadImage(item.id)}>Upload Image</button>
                  </div>
                </div>

                <div className="product-title-container">
                  <span className='product-title' >{item.title}</span>
                  <span className='product-quantity' style={{ border: `2px solid ${item.id == 0 && "#CD4A4A" || item.id > 0 && item.productionStatus == 'production' && '#FF8800' || item.productionStatus == 'discontinued' && '#636951'}` }} >{item.id.toString() + " left"}</span>
                </div>
                <div className='product-variant' >{item.variant}</div>

                <div className='product-btns'>
                  <button className='btn-primary' style={{ borderRadius: '30px', backgroundColor: `${item.productionStatus == 'production' && "#CD4A4A" || item.productionStatus == 'onhold' && '#636951' || item.productionStatus == 'discontinued' && ''}`, opacity: `${item.productionStatus == 'discontinued' && '0.6' || '1'}` }} onClick={(e) => { }}>{item.productionStatus}</button>
                  <div className='edit-btns df-center'>
                    <div className='df-center edit-btn' onClick={() => { }}><img src={DeleteBtn}></img></div>
                    <div className='df-center edit-btn' onClick={() => { toggleDrawerHandler('edit-product', item) }}><img src={EditBtn}></img></div>
                  </div>
                </div>

              </div>
            )) : null
          }
        </div>

    </>
  );


}
export default ProductsGrid;