import React, { useContext, useState } from 'react';
import { Checkbox, Button, CircularProgress, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext';
import { CloseRounded } from '@mui/icons-material';


const OrderStatusChangeModal = ({ modalDetails }) => {
  
  const status = modalDetails.data.status;
  const orderId = modalDetails.data.orderId;
  
  // context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [isConfirming, setIsConfirming] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(status);
  const [sendNotifications, setSendNotifications] = useState(false);
  const [sendCustomerUpdate, setSendCustomerUpdate] = useState(false);
  const [sendCustomerNotification, setSendCustomerNotification] = useState(false);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const changeOrderStatus = async () => {
    setIsConfirming(true)
    try {
      
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/order/${orderId}/updateStatus`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ status: statusUpdate })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          modalCtx.setPageUpdate({page: "orders"});
          modalCtx.closeModal();
          setIsConfirming(false);
        } else {
          console.error("Error while creating an order");
        }
      }).catch((err) => console.error("Error while creating an order", err));
      
    } catch (error) {
      setIsConfirming(false)
      console.log(error);
    }
  }

  const showAdditionalOptions = () => {
    switch (statusUpdate) {

      case "packing": return <div className='aditional-options'>
        <div><Checkbox checked={sendNotifications} onChange={(e) => setSendNotifications(e.target.checked)} /> Send notification to operations team</div>
      </div>;

      case "dispatched": return <div className='aditional-options'>
        <div><Checkbox checked={sendCustomerUpdate} onChange={(e) => setSendCustomerUpdate(e.target.checked)} /> Send update to customer</div>
      </div>;
      
      case "delivered": return <div className='aditional-options'>
        <div><Checkbox checked={sendCustomerNotification} onChange={(e) => setSendCustomerNotification(e.target.checked)} /> Send notification to customer</div>
      </div>;

      default: return null;
    }
  }


  return (
    <>
      
      <div className="confirm-order-status-container">

        <header>
          <h2>Update status</h2>
          <CloseRounded onClick={() => handleCloseModal()} style={{cursor: "pointer"}} />
        </header>

        <section>
          <div className='confirmation-option'>
            <RadioGroup defaultValue={statusUpdate} onChange={(e) => setStatusUpdate(e.target.value)} name="radio-buttons-group" >
              <FormControlLabel value="pending" control={<Radio />} label="Set status as DRAFT" />
              <FormControlLabel value="packing" control={<Radio />} label="Set status as PACKING" />
              <FormControlLabel value="dispatched" control={<Radio />} label="Set status as DISPATCHED" />
              <FormControlLabel value="delivered" control={<Radio />} label="Set status as DELIVERED" />
            </RadioGroup>
          </div>
          
          {showAdditionalOptions()}

          <div className="save-changes-btn">
            <Button variant="outlined" component="label" disabled={false} onClick={() => handleCloseModal()} fullWidth style={{ border: '1px Solid #AA7B59', color: '#AA7B59' }}>Cancel</Button>
            {
              isConfirming ? <Button variant="contained" component="label" disabled={true} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}><CircularProgress size={10} style={{color: "#fff"}} /></Button> :
              <Button variant="contained" component="label" disabled={false} onClick={() => changeOrderStatus()} fullWidth style={{ backgroundColor: '#AA7B59', color: 'white' }}>Save</Button>
            }
          </div>

        </section>
      </div>
    </>
  )
}

export default OrderStatusChangeModal