import React, { useContext, useState, useEffect } from 'react'

//context
import AuthContext from '../../../context/AuthContext'
import ModalContext from '../../../context/ModalContext'

//mui
import DeleteIcon from '@mui/icons-material/Delete'
import { BiEdit } from 'react-icons/bi'
import { CircularProgress } from '@mui/material'
import DrawerContext from '../../../context/DrawerContext'

const TagsTable = () => {
  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)
  const drawerCtx = useContext(DrawerContext)

  const [allTags, setAllTags] = useState()

  const handleModal = (modal, data) => {
    // console.log(modal, data)
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();
  }

  useEffect(() => {
    getData();
  }, [drawerCtx, modalCtx])


  const getData = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/tags ', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllTags(data.data)
      })
  }


  return (
    <>
      {
        allTags ? <>
          <table className="topic-table">
            <thead>
              <tr>
                <th>Tag Name</th>
              </tr>
            </thead>

            <tbody>
              {allTags?.map((el, index) => (
                <>
                  <tr className="ingredient-row">
                    <td className="topics-name" key={index}>
                      <h5>{el?.title}</h5>
                    </td>
                    <div className="edit-delete-icons">
                      <td className=''>
                        <button
                          onClick={() => {
                            handleModal("delete-tag", el)
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                      <td>
                        <button>
                          <BiEdit
                            onClick={() => {
                              handleModal("edit-tag", el);
                            }}
                          />
                        </button>
                      </td>
                    </div>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </>
          :
          <>
            <CircularProgress size={30} />
          </>
      }
    </>
  )
}

export default TagsTable