import { useState, useEffect, useContext, useCallback } from 'react';


// import { TextField, MenuItem, FormControl, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, FormControl, Button, Card, InputAdornment, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Paper, CardContent, Menu, ImageList, ImageListItem } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CloseRounded, Search, Title } from '@mui/icons-material';
// import editButton from '../../../assets/img/blog/edit.svg';
// import deleteButton from '../../../assets/img/blog/delete.svg';
// import emailButton from '../../../assets/img/email.svg';
// import callButton from '../../../assets/img/call.svg';
// import profileButton from '../../../assets/img/profile.svg';
import DrawerContext from '../../../context/DrawerContext.js';
// import GetImage from '../../../common/GetImage';
// import { InsertEmoticonSharp } from '@mui/icons-material';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import AuthContext from '../../../context/AuthContext.js';
import { Avatar, Chip, Rating } from '@mui/material';
// import InventoryMenu from './InventoryMenu.jsx';




function EditProductDrawer(props) {

  console.log("editProductDrawer", props?.productDetails);
  const editProduct = props?.productDetails;
  // console.log("EDITPORDUCT", editProduct);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  // // const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [parentProduct, setParentProduct] = useState(-1);
  const [parentProducts, setParentProducts] = useState([]);
  const [showForm, setShowForm] = useState("");

  const [productTitle, setProductTitle] = useState("");
  const [productVariant, setProductVariant] = useState("");
  const [productPrice, setProductPrice] = useState(0.00);
  const [productDescription, setProductDescription] = useState("");

  // const [selectedRows, setSelectedRows] = useState([]);
  // const [searchTag, setSearchTag] = useState('');
  // const [activeButton, setActiveButton] = useState(1);



  // // useEffect(() => {
  // //   fetch(process.env.REACT_APP_API_URI + '/v1/products/byParent', {
  // //     method: 'GET',
  // //     mode: 'cors',
  // //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
  // //   }).then(response => {
  // //     return response.json();
  // //   }).then(data => {
  // //     console.log(data);
  // //     if (data.status == "success") {
  // //       setParentProducts(data.products);
  // //       setIsLoading(false);
  // //     } else {
  // //       // setOpenSnackbar(true);
  // //     }
  // //   });
  // // }, []);

  useEffect(() => {
    setProductDescription(editProduct.description);
    setProductTitle(editProduct.title);
    setProductVariant(editProduct.variant);
    setProductPrice(editProduct.price);
    setParentProduct(editProduct.parent_id);

    setIsLoading(false)

  }, [editProduct])

  // console.log(parentProduct);

  const updateBaseProduct = (parent) => {
    setParentProduct(parent);
    if (parent == 0) {
      setShowForm("parent");
    } else {
      setShowForm("variant");
    }
  }

  const checkBtn = () => {
    if (productVariant != "" && productPrice != "" && productDescription != "") {
      return false;
    } else {
      return true;
    }
  }

  const editProductFunc = (event) => {
    event.preventDefault();

    setDisabled(true);
    setIsLoading(true);

    let title;
    if (parentProduct == 0) {
      title = productTitle;
    } else {
      let product = parentProducts.find(function (obj) { return obj.id == parentProduct });
      if (product) {
        title = product.title;
      }
    }

    let jsonBody = JSON.stringify({
      parentId: parentProduct,
      title: title,
      variant: productVariant,
      price: productPrice,
      description: productDescription,
      id: editProduct?.id,
    });

    console.log("jsonBody", jsonBody);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/product/' + editProduct.id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        drawerCtx.closeDrawer();
        drawerCtx.setPageUpdate({ page: "products" });
      } else {
        setDisabled(false);
        setIsLoading(false);
      }
    });

  }


  // const data = [
  //   { argument: 'Jan', value: 30 },
  //   { argument: 'Feb', value: 20 },
  //   { argument: 'Mar', value: 10 },
  //   { argument: 'Apr', value: 50 },
  //   { argument: 'May', value: 60 },
  //   { argument: 'June', value: 30 },
  //   { argument: 'July', value: 20 },
  //   { argument: 'Aug', value: 10 },
  //   { argument: 'Sep', value: 50 },
  //   { argument: 'Oct', value: 60 },
  //   { argument: 'Nov', value: 30 },
  //   { argument: 'Dec', value: 30 }
  // ];


  // const color = '#AA7B59';
  // const chartHeight = 319;

  // const [barHeights, setBarHeights] = useState([]);
  // // const barHeights = [];

  // // useEffect(() => {
  // //   if (drawerCtx.open) {
  // //     setTimeout(() => {

  // //       const bars = document?.querySelectorAll("rect.bars");
  // //       console.log("BARS", bars);
  // //       bars?.forEach((bar) => barHeights.push(+bar.getAttribute('height')));
  // //       const maxHeight = barHeights.sort((a, b) => b - a);
  // //       console.log("MAX", maxHeight[0], barHeights);
  // //       bars.forEach((bar) => {
  // //         if (+bar.getAttribute('height') === maxHeight[0]) {
  // //           bar.style.fill = '#636951';
  // //         }
  // //       });

  // //     }, 1000);

  // //   }

  // // }, []);


  const renderForm = () => {

    return <>
      { showForm == "parent" ? <div className="double-column-form">
        <TextField label="Title" value={productTitle} onChange={(e) => {setProductTitle(e.target.value)}} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div> : null }
      <br/>
      <div className="double-column-form">
        <TextField label="Specification" value={productVariant} onChange={(e) => {setProductVariant(e.target.value)}} placeholder="Specification" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
        <TextField label="Price" value={productPrice} onChange={(e) => {setProductPrice(e.target.value)}} placeholder="Price" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div>
      <br/>
      <div className="double-column-form">
        <TextField multiline rows={20} label="Description" value={productDescription} onChange={(e) => {setProductDescription(e.target.value)}} placeholder="Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div>
      <br/>
    </>;
  }

  // // // console.log(editProduct.parent_id, editProduct.id, parentProduct, parentProducts);

  return (
    <div className="container">
      <div className="drawer product-drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Product { isLoading ? <CircularProgress size={25} /> : null }</h1>
        </header>

        <section className="product-drawer-details">

          <div className="double-column-form">
            <FormControl fullWidth>
              {/* <InputLabel id='base-product'>Base Product</InputLabel> */}
              <TextField
                value={parentProduct}
                onChange={(e) => updateBaseProduct(e.target.value)}
                select // tell TextField to render select
                label="Base Product"
                variant="outlined"
              >
              {/* <Select labelId="base-product" label="Base Product" value={parentProduct} onChange={(e) => {updateFileType(e)}} placeholder="File Type" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required> */}
                <MenuItem value='-1'>Please Choose 'None' for base product</MenuItem>
                <MenuItem value='0'>None (New base product)</MenuItem>
                { parentProducts.map((data) => (
                  <MenuItem value={data.id} key={data.id}>{data.title} ({data.variant})</MenuItem>
                ))}
              </TextField>
              {/* </Select>  */}
            </FormControl>
          </div>
          <br/>

          { renderForm() }

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={checkBtn() || disabled}>
              Save Changes
              <input type="file" onClick={(e) => editProductFunc(e)} hidden />
            </Button>
          </div>

        </section>

      </div>
    </div>
  );

  // const handleButtonClick = (buttonId) => {
  //   setActiveButton(buttonId);
  //   setSearchTag('');
  // }

  // const handleFilterArray = (value) => {
  //   console.log(value);
  //   setSearchTag(value);
  // }


  // const handleSelectAll = (event, selecting) => {
  //   if (selecting = "order") {
  //     if (event.target.checked) {
  //       setSelectedRows(dummyOrderData.map((item) => item.id));
  //     } else {
  //       setSelectedRows([]);
  //     }
  //   } else {
  //     if (event.target.checked) {
  //       setSelectedRows(marginItems.map((item) => item.id));
  //     } else {
  //       setSelectedRows([]);
  //     }
  //   }

  // };

  // const handleSelectRow = (event, id) => {
  //   if (event.target.checked) {
  //     setSelectedRows([...selectedRows, id]);
  //   } else {
  //     setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
  //   }
  // };

  // const closeDrawerHandler = () => {
  //   drawerCtx.closeDrawer();
  //   setBarHeights([]);
  // }
  // // const format = scale => scale.tickFormat(null, null);

  return (

    // <div className="product-drawer">
    //   <div className='head'>
    //     <CloseRounded onClick={() => { closeDrawerHandler() }}></CloseRounded>
    //     <h1>{editProduct.title}</h1>
    //   </div>



    //   <div className='details-section'>
    //     <div className='buttons-div'>
    //       <button style={{ backgroundColor: activeButton === 1 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(1)}>Statistics</button>
    //       <button style={{ backgroundColor: activeButton === 2 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(2)}>Inventory</button>
    //       <button style={{ backgroundColor: activeButton === 3 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(3)}>Details</button>
    //       <button style={{ backgroundColor: activeButton === 4 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(4)}>Images</button>
    //       <button style={{ backgroundColor: activeButton === 5 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(5)}>Recipes</button>
    //       <button style={{ backgroundColor: activeButton === 6 ? '#DCD3CB' : 'transparent' }} onClick={() => handleButtonClick(6)}>Reviews</button>

    //     </div>

    //     {activeButton === 1 &&
    //       <div className='statistics'>
    //         <Paper >
    //           <Chart height={chartHeight}
    //             data={data}
    //           >
    //             {<h1>Statistics</h1>}
    //             <ArgumentAxis />
    //             <ValueAxis />

    //             <BarSeries className='bars' valueField="value" argumentField="argument" color={color} />

    //           </Chart>
    //         </Paper>

    //         <div className='graph-details'>
    //           <div className='card'><p className='title'>5600</p> <p>Sales</p></div>
    //           <div className='card'><p className='title'>May</p> <p>Top Month</p></div>
    //           <div className='card'><p className='title'>5600</p> <p>Prediction</p></div></div>
    //       </div>

    //     }

    //     {activeButton === 2 && <div className='margin'>

    //       <div className='inventory'>
    //         <Card className='inventory-card'>
    //           <CardContent>
    //             <h1>20 left</h1>
    //             <Chip className='inventory-status' label="In Production" variant='outlined'></Chip>
    //           </CardContent>
    //         </Card>
    //       </div>

    //     </div>}


    //     {activeButton === 3 &&
    //       <div className='details'>
    //         <InventoryMenu />

    //         <div className='product-details'>
    //           <Card className='details-card'>
    //             <h1 className='product-title'>{editProduct.title}</h1>
    //             <p className='product-description'>A delicious syrup made with forest-farmed Himalayan strawberries.</p>
    //             <p className='product-ingredients'>{editProduct.description}</p>
    //           </Card>
    //         </div>
    //       </div>}


    //     {activeButton === 4 && <div className='images-container'>
    //       <Card className='product-image-card' >
    //         <img className='product-image' src="http://api.himalayanhaat.org/assets/img/strawberry-cooler.jpg" ></img>
    //       </Card>

    //       <div className='product-image-gallery' style={{ boxShadow: '0px' }}>
    //         <ImageList className='gallery' cols={4} gap={20} style={{ margin: "0px auto" }} >
    //           <ImageListItem style={{ height: "304px", width: "227px", borderRadius: "4px" }}>
    //             <img className='other-product' src="http://api.himalayanhaat.org/assets/img/strawberry-cooler.jpg" ></img>
    //           </ImageListItem>

    //           <ImageListItem style={{ height: "304px", width: "227px", borderRadius: "4px" }}>
    //             <img className='other-product' src="http://api.himalayanhaat.org/assets/img/strawberry-cooler.jpg" ></img>
    //           </ImageListItem>

    //           <ImageListItem style={{ height: "304px", width: "227px", borderRadius: "4px" }}>
    //             <img className='other-product' src="http://api.himalayanhaat.org/assets/img/strawberry-cooler.jpg" ></img>
    //           </ImageListItem>

    //           <ImageListItem style={{ height: "304px", width: "227px", borderRadius: "4px" }}>
    //             <img className='other-product' src="http://api.himalayanhaat.org/assets/img/strawberry-cooler.jpg" ></img>
    //           </ImageListItem>
    //         </ImageList>
    //       </div>
    //     </div>

    //     }
    //     {activeButton === 5 && <div className='details' style={{}}>

    //       <h5 className='recipe-heading'>Add Recipe</h5>
    //       <TextField
    //         className='recipe-text'
    //         id="filled-multiline-static"
    //         label="Recipe"
    //         multiline
    //       />
    //       <Button className='recipe-button'>Add Recipe</Button>
    //     </div>}
    //     {activeButton === 6 && <div className='reviews'>

    //       <h1 className='reviews-heading'>5 Reviews for Strawberry Cooler</h1>

    //       <div className='review-container'>
    //         <div className='review'>
    //           <div className='reviewer-data'>
    //             <div className='reviewer-column'>
    //               <Avatar alt="name" src='https://mui.com/static/images/avatar/1.jpg'></Avatar>
    //               <div className='review-name-ratings'>
    //                 <text className='name'>Stuart Res</text>
    //                 <div className='rating'>
    //                   <Rating name="read-only" value={4} readOnly />
    //                   <text className='rating-score'>4.0</text>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='review-date'>10 June 22</div>
    //           </div>
    //           <div className='review-paragraph'>
    //             <p className='review-text'>A sensational burst of flavour in a tall glass of iced strawberry cooler… A summer must…</p>
    //           </div>
    //         </div>

    //         {/* //2 */}

    //         <div className='review'>
    //           <div className='reviewer-data'>
    //             <div className='reviewer-column'>
    //               <Avatar alt="name" src='https://mui.com/static/images/avatar/1.jpg'></Avatar>
    //               <div className='review-name-ratings'>
    //                 <text className='name'>Stuart Res</text>
    //                 <div className='rating'>
    //                   <Rating name="read-only" value={4} readOnly />
    //                   <text className='rating-score'>4.0</text>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='review-date'>10 June 22</div>
    //           </div>
    //           <div className='review-paragraph'>
    //             <p className='review-text'>A sensational burst of flavour in a tall glass of iced strawberry cooler… A summer must…</p>
    //           </div>
    //         </div>

    //         {/* //3 */}

    //         <div className='review'>
    //           <div className='reviewer-data'>
    //             <div className='reviewer-column'>
    //               <Avatar alt="name" src='https://mui.com/static/images/avatar/1.jpg'></Avatar>
    //               <div className='review-name-ratings'>
    //                 <text className='name'>Stuart Res</text>
    //                 <div className='rating'>
    //                   <Rating name="read-only" value={4} readOnly />
    //                   <text className='rating-score'>4.0</text>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='review-date'>10 June 22</div>
    //           </div>
    //           <div className='review-paragraph'>
    //             <p className='review-text'>A sensational burst of flavour in a tall glass of iced strawberry cooler… A summer must…</p>
    //           </div>
    //         </div>

    //         {/* //4 */}
    //         <div className='review'>
    //           <div className='reviewer-data'>
    //             <div className='reviewer-column'>
    //               <Avatar alt="name" src='https://mui.com/static/images/avatar/1.jpg'></Avatar>
    //               <div className='review-name-ratings'>
    //                 <text className='name'>Stuart Res</text>
    //                 <div className='rating'>
    //                   <Rating name="read-only" value={4} readOnly />
    //                   <text className='rating-score'>4.0</text>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='review-date'>10 June 22</div>
    //           </div>
    //           <div className='review-paragraph'>
    //             <p className='review-text'>A sensational burst of flavour in a tall glass of iced strawberry cooler… A summer must…</p>
    //           </div>
    //         </div>
    //       </div>

    //     </div>}
    //   </div>
    // </div>
    <>
    </>
  );


  // }

}
export default EditProductDrawer;

const dummyData = [
  { id: 1, address: "123 Main St." },
  { id: 2, address: "456 Oak Ave." },
  { id: 3, address: "789 Pine Blvd." },
  { id: 4, address: "321 Elm St." },
  { id: 5, address: "654 Maple Ave." }
];

const dummyOrderData = [
  {
    id: 1,
    date: '2022-05-03',
    customer: 'John Doe',
    status: 'Pending',
    type: 'Online',
    total: 100.0,
  },
  {
    id: 2,
    date: '2022-05-02',
    customer: 'Jane Smith',
    status: 'Completed',
    type: 'In-Store',
    total: 50.0,
  },
  {
    id: 3,
    date: '2022-05-01',
    customer: 'Bob Johnson',
    status: 'Cancelled',
    type: 'Online',
    total: 75.0,
  },
  {
    id: 4,
    date: '2022-04-30',
    customer: 'Sarah Lee',
    status: 'Completed',
    type: 'In-Store',
    total: 25.0,
  },
];


const marginItems = [
  { id: 1, product: 'Product A', marginPercentage: 10 },
  { id: 2, product: 'Product B', marginPercentage: 12 },
  { id: 3, product: 'Product C', marginPercentage: 8 },
  { id: 4, product: 'Product D', marginPercentage: 15 },
  { id: 5, product: 'Product E', marginPercentage: 20 },
  { id: 6, product: 'Product F', marginPercentage: 18 },
  { id: 7, product: 'Product G', marginPercentage: 7 },
  { id: 8, product: 'Product H', marginPercentage: 14 },
  { id: 9, product: 'Product I', marginPercentage: 9 },
  { id: 10, product: 'Product J', marginPercentage: 11 },
];