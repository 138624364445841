import { useContext } from 'react';
import { Modal, Box } from '@mui/material';

import ModalContext from '../context/ModalContext';

import CreateBlog from '../utils/Modals/Blogs/CreateBlog';
import EditBlogAuthorModal from '../utils/Modals/Blogs/EditBlogAuthorModal.jsx';
import EditBlogTopicsModal from '../utils/Modals/Blogs/EditBlogTopicsModal.jsx';
import EditBlogTagsModal from '../utils/Modals/Blogs/EditBlogTagsModal.jsx';
import DeleteBlogModal from '../utils/Modals/Blogs/DeleteBlogModal.jsx';
import PublishBlogModal from '../utils/Modals/Blogs/PublishBlogModal.jsx';
import OrderAddModal from '../utils/Modals/Orders/OrderAddModal.jsx';
import OrderDeleteModal from '../utils/Modals/Orders/OrderDeleteModal.jsx';
import DeleteRetailModel from '../utils/Modals/Retailers/DetailRetailModel.jsx';
import OrderChangeAddressModal from '../utils/Modals/Orders/OrderChangeAddressModal.jsx';
import OrderChangeAccountDetailsModal from '../utils/Modals/Orders/OrderChangeAccountDetails.jsx';
import OrderStatusChangeModal from '../utils/Modals/Orders/OrderStatusChangeModal.jsx'
import OrderConfirmModal from '../utils/Modals/Orders/OrderConfirmModal.jsx';
import UploadFixedImageModal from '../utils/Modals/ImageCropper/UploadFixedImageModal'
import EditTotalTime from '../utils/Modals/Recipes/EditTotalTime';
import EditPrepTime from '../utils/Modals/Recipes/EditPrepTime';
import EditServes from '../utils/Modals/Recipes/EditServes';
import AddIngredient from '../utils/Modals/Recipes/AddInngredient';
import AddMethod from '../utils/Modals/Recipes/AddMethod';
import CreatePromotions from '../utils/Modals/Promotions/CreatePromotions.jsx';
import AddFeaturedBlog from '../utils/Modals/Blogs/AddFeaturedBlog.jsx';
import EditIngredient from '../utils/Modals/Recipes/EditIngredient.jsx';
import EditMethod from '../utils/Modals/Recipes/EditMethod.jsx';
import DeleteRecipeModal from '../utils/Modals/Recipes/DeleteRecipeModal.jsx'
import DeleteAuthorModal from '../utils/Modals/Authors/DeleteAuthorModal.jsx'
import EditTopicsModal from '../utils/Modals/Topics/EditTopicsModal.jsx';
import EditTagsModal from '../utils/Modals/Tags/EditTagsModal.jsx';
import EditTopics from '../utils/Modals/Topics/EditTopics.jsx';
import DeleteTopicsModal from '../utils/Modals/Topics/DeleteTopicsModal.jsx';
import EditTag from '../utils/Modals/Tags/EditTag.jsx'
import DeleteTagModal from '../utils/Modals/Tags/DeleteTagModal.jsx';

//coupons
import CreateCoupon from '../utils/Modals/Coupons/CreateCoupon.jsx';
import DeleteCoupon from '../utils/Modals/Coupons/DeleteCoupon.jsx';
import EditCoupon from '../utils/Modals/Coupons/EditCoupon.jsx';

// import OrderAddAddressModal from '../utils/Modals/Orders/OrderAddAddressModal.jsx';



const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);
  console.log(modalCtx)

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
  };

  const currentModal = () => {

    switch (modalCtx.modal) {

      // Retailers
      case "confirm-delete-retailer": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteRetailModel modalDetails={modalCtx.details} /></Box></Modal>;

      // file Upload
      case "upload-fixed-image": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadFixedImageModal modalDetails={modalCtx.details} /></Box></Modal>;

      // Orders
      case "add-orders": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><OrderAddModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "change-order-address": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><OrderChangeAddressModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "change-account-details": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><OrderChangeAccountDetailsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-order": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><OrderConfirmModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "delete-orders": return <Modal open={modalCtx.open} className=''><Box sx={boxStyles}><OrderDeleteModal modalDetails={modalCtx.details} /></Box></Modal>
      case "change-order-status": return <Modal open={modalCtx.open} className=''><Box sx={boxStyles}><OrderStatusChangeModal modalDetails={modalCtx.details} /></Box></Modal>

      // blogs
      case "confirm-publish-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTagsModal modalDetails={modalCtx.details} /></Box></Modal>;


      // recipe
      case "edit-total-time": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTotalTime /></Box></Modal>;
      case "edit-prep-time": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditPrepTime /></Box></Modal>;
      case "edit-serves": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditServes /></Box></Modal>;
      case "add-ingredient": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddIngredient /></Box></Modal>;
      case "add-method": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddMethod /></Box></Modal>;
      case "edit-ingredient": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditIngredient /></Box></Modal>;
      case "edit-method": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditMethod /></Box></Modal>;
      case "delete-recipe-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteRecipeModal /></Box></Modal>;

      // Promotions
      case 'create-new-promotions': return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreatePromotions /></Box></Modal>

      //featured
      case "add-featured-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddFeaturedBlog modalDetails={modalCtx.details} /></Box></Modal>;

      //Authors
      case 'confirm-delete-author': return <Modal open={modalCtx.open} className='add-goal-lightbox'><Box sx={boxStyles}><DeleteAuthorModal modalDetails={modalCtx.details} /></Box></Modal>

      //Topics
      case "edit-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTopics /></Box></Modal>;
      case "delete-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTopicsModal /></Box></Modal>;

      //Tags
      case "edit-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTag /></Box></Modal>;
      case "delete-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTagModal /></Box></Modal>;


      //Topics
      case "edit-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTopicsModal /></Box></Modal>;

      //Tags
      case "edit-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTagsModal /></Box></Modal>;

      //coupons
      case "create-coupon": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreateCoupon modalDetails={modalCtx.details} /></Box></Modal>;
      case "delete-coupon": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCoupon modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-coupon": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCoupon modalDetails={modalCtx.details} /></Box></Modal>;


      default: return null;

    }

  }

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;

