import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref, uploadString } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp({

  //   measurementId: "G-XCJWJXX408",
  apiKey: "AIzaSyAbdRc9PjbzmTQ-Dp8m05UNfPwiMoKVMZ8",
  authDomain: "himalayan-haat.firebaseapp.com",
  databaseURL: "https://himalayan-haat-default-rtdb.firebaseio.com",
  projectId: "himalayan-haat",
  storageBucket: "himalayan-haat.appspot.com",
  messagingSenderId: "400768696994",
  appId: "1:400768696994:web:73b9c3705fd09341524dfb"
});

// Firebase storage reference
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

export { database, firestore, uploadBytes, ref, uploadString };

export default storage;
